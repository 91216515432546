import useActions from 'HOOKS/useActions';
import { testCallActions as actions } from './testCallSlice';

const useTestCallDeviceManager = () => {
  const [setSelectedMicrophone, setSelectedSpeaker] = useActions([
    actions.setSelectedMicrophone,
    actions.setSelectedSpeaker,
  ]);

  return {
    setSelectedMicrophone: (microphone) => setSelectedMicrophone(microphone),
    setSelectedSpeaker: (speaker) => setSelectedSpeaker(speaker),
  };
};

export default useTestCallDeviceManager;
