// libraries
import useActions from 'HOOKS/useActions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'SERVICES/i18n';
import { Flex, FlexItem } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getInterpreterDetails, updateCallDetails } from 'FEATURES/interpreter/api/api';

// utilities
import logger from 'SERVICES/logger';

// clients
import socketClient from 'SRC/socket/client';

// components imported from alias
import ACSChatClient from 'SERVICES/acsChatClient/index';
import FullScreenLoader from 'COMPONENTS/common/FullScreenLoader';
import { useFloorMeeting, VideoGallery } from 'FEATURES/interpreter/features/FloorMeeting';
import earphones from 'RESOURCES/images/earphones-grey.svg';
import {
  logout,
  getInterpreterIdToken,
} from 'FEATURES/interpreter/features/Authentication/authentication';
import { ERROR } from 'CONSTANTS/apiConstants';

// store
import {
  selectInterpreter,
  interpreterActions,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import { selectService, serviceActions } from 'SRC/store/serviceSlice';
import {
  CustomToastContainer as ToastContainer,
  showToast,
  TOAST_TYPE,
} from 'FEATURES/interpreter/components/CustomToastContainer';
import { chatActions } from 'FEATURES/interpreter/slices/chatSlice';
import { SOCKET_RESPONSE, SOCKET_REQUEST } from 'SRC/socket/socketConstant';
import { LOGOUT_TRIGGER } from 'CONSTANTS/enum';
import { CALL_END_REASON } from 'FEATURES/participant/constants';
import {
  primaryLangMeetingSelector as selector,
  primaryLangMeetingActions,
} from '../LanguageMeeting/primaryLangMeetingSlice';
import { secondaryLangMeetingSelector as secondaryMeetingSelector } from '../LanguageMeeting/secondaryLangMeetingSlice';
import useChatClient from '../ChatWindow/useChatClient';

// resources
import AkouoLogoGrey from './components/AkouoLogo';

// hooks
import usePrimaryLanguageMeeting from '../LanguageMeeting/usePrimaryLanguageMeeting';
import useRelayMeeting from '../RelayMeeting/hooks/useRelayMeeting';
import useSecondaryLanguageMeeting from '../LanguageMeeting/useSecondaryLanguageMeeting';

// commponents
import ChatWindow from '../ChatWindow/ChatWindow';
import HangUpButton from './components/HangUpButton';
import ControlHeader from './components/ControlHeader';
import IncomingMediaController from './components/IncomingMediaController';
import OutgoingLanguageMeeting from '../LanguageMeeting/OutgoingLanguageMeeting';
import ServiceErrorOverlay from './components/ServiceErrorOverlay';
import { FLOOR_INCOMING_AUDIO, OUTGOING_CALL_TYPE, SERVICE_STATUS } from '../../constants/index';
// styles
import './Dashboard.scss';

const Dashboard = ({ handleCallEnd }) => {
  // #region state variables
  const [isLoading, setIsLoading] = useState(true);
  const [socketConnected, setSocketConnected] = useState(true);

  const [showFullscreenError, setFullscreenError] = useState(false);

  // FIXME: Workaround to set interpreter details
  const [joinInitiate, setJoinInitiate] = useState(false);

  // FIXME: workaround for stale state in hooks
  const [threadCreatedSubId, setThreadCreatedSubId] = useState(null);
  const [threadDeletedSubId, setThreadDeletedSubId] = useState(null);
  const [chatMsgReceiveSubId, setChatMsgReceiveSubId] = useState(null);
  const [localChatClient, setLocalChatClient] = useState(null);
  const [userRejoined, setUserRejoined] = useState(undefined);

  const [messageReceievd, setMessageReceived] = useState(null);
  const [threadCreated, setThreadCreated] = useState(null);
  const [updateUser, setUpdateUser] = useState(null);
  const [localPrimary, setLocalPrimary] = useState(null);
  const [localSecondary, setLocalSecondary] = useState(null);
  const [restUserData, setRestUserData] = useState(undefined);
  const [addNewUser, setAddNewUser] = useState(undefined);
  const [removeUserFromData, setRemoveUserFromData] = useState(undefined);
  const [createGroupChat, setCreateGroupChat] = useState(undefined);
  const [fallBackCheck, setFallBackCheck] = useState(undefined);
  // #endregion

  // #region selectors
  const chatInfo = useSelector(selectInterpreter.chatInfo);
  const acsToken = useSelector(selector.acsToken);
  const interpreterName = useSelector(selectInterpreter.interpreterName);
  const blockScreen = useSelector(selectService.blockScreen);
  const interpreterDetails = useSelector(selectInterpreter.interpreterDetails);
  const languageMeetings = useSelector(selectInterpreter.languageMeetings);
  const selectedPrimaryLanguageInfo = useSelector(selectInterpreter.selectedPrimaryLanguageInfo);
  const selectedSecondaryLanguageInfo = useSelector(
    selectInterpreter.selectedSecondaryLanguageInfo
  );
  const isRecordingEnabled = useSelector(selectInterpreter.isRecordingEnabled);
  const primaryLanguageCallData = useSelector(selector.primaryLanguageCallData);
  const primaryRecordingLanguageCallData = useSelector(selector.primaryRecordingLanguageCallData);
  const secondaryLanguageCallData = useSelector(secondaryMeetingSelector.secondaryLanguageCallData);
  const secondaryRecordingLanguageCallData = useSelector(
    secondaryMeetingSelector.secondaryRecordingLanguageCallData
  );
  // #endregion

  const FALLBACK_DELAY_MS = 5000;
  // #region hooks
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });
  const { meetingId } = useParams();
  const { joinFloorMeeting, hangUpFloorMeeting, disposeFloorMeeting, callEnded } =
    useFloorMeeting();
  const {
    handleNewMessageReceived,
    initiateChatClientCleanup,
    handleStatusUpdate,
    syncThreadsAndParticipants,
    repopulateParticipantsWithThreads,
    addThreadToParticipant,
    onNewParticipantListReceived,
    addParticipantToList,
    removeParticipantFromList,
    saveGroupChatParticipant,
  } = useChatClient();
  const { removeUnreadThread, updateParticipantList, setGroupChat } = useActions(chatActions);
  const { setConnectionStatus } = useActions(serviceActions);
  const {
    setupPrimaryLanguageMeeting,
    joinPrimaryLanguageMeeting,
    hangUpPrimaryLanguageMeeting,
    disposePrimaryLanguageMeeting,
    setupPrimaryRecordingLanguageMeeting,
    joinPrimaryRecordingLanguageMeeting,
    hangUpPrimaryRecordingLanguageMeeting,
    disposePrimaryRecordingLanguageMeeting,
  } = usePrimaryLanguageMeeting();
  const {
    setupSecondaryLanguageMeeting,
    joinSecondaryLanguageMeeting,
    hangUpSecondaryLanguageMeeting,
    disposeSecondaryLanguageMeeting,
    setupSecondaryRecordingLanguageMeeting,
    joinSecondaryRecordingLanguageMeeting,
    hangUpSecondaryRecordingLanguageMeeting,
    disposeSecondaryRecordingLanguageMeeting,
  } = useSecondaryLanguageMeeting();
  const { setupRelayMeeting, hangUpRelayMeeting, disposeRelayMeeting } = useRelayMeeting();
  const { setAcsToken } = useActions(primaryLangMeetingActions);

  const {
    setActiveLanguageCode,
    setActiveLanguageStatus,
    setActiveIncomingAudio,
    setErrorStatus,
    setInterpreterDetails,
    setSelectedPrimaryLanguageInfo,
    setSelectedSecondaryLanguageInfo,
  } = useActions(interpreterActions);
  // #endregion

  const meetingCleanUp = async () => {
    logger.debug('Dashboard Cleanup');
    await disposeRelayMeeting();
    await disposePrimaryLanguageMeeting();
    await disposeSecondaryLanguageMeeting();
    await disposeFloorMeeting();
    if (isRecordingEnabled) {
      await disposePrimaryRecordingLanguageMeeting();
      await disposeSecondaryRecordingLanguageMeeting();
    }
    socketClient.disconnect();
  };

  const handleHangUp = async (logoutMode = LOGOUT_TRIGGER.auto) => {
    // logoutMode is "manual" only if user logs out by clicking logout button
    // AKMSTEAMS-483 observation of button not appearing hence moving it into new thread
    setTimeout(async () => {
      let result;
      if (logoutMode === LOGOUT_TRIGGER.manual) {
        result = window.confirm(t('LOGOUT_MESSAGE'));
      }
      if (logoutMode === LOGOUT_TRIGGER.auto || result) {
        logger.debug('handleHangup');
        try {
          await Promise.all([
            hangUpRelayMeeting(),
            hangUpPrimaryLanguageMeeting(),
            hangUpSecondaryLanguageMeeting(),
            hangUpFloorMeeting(),
          ]);
          if (isRecordingEnabled) {
            await Promise.all([
              hangUpPrimaryRecordingLanguageMeeting(),
              hangUpSecondaryRecordingLanguageMeeting(),
            ]);
          }
          setActiveLanguageStatus(OUTGOING_CALL_TYPE.none);
          setActiveLanguageCode(null);
          await initiateChatClientCleanup();
          await logout(
            meetingId,
            interpreterDetails.id,
            interpreterDetails.name,
            interpreterDetails.email,
            logoutMode
          );
          handleCallEnd();
        } catch (error) {
          setErrorStatus(true);
          logger.error('HANGUP ERROR', error);
        }
      }
    });
  };

  const joinMeetings = async () => {
    // FIXME: Workaround to ensure joinMeetings should run exactly once, and only after the interpreter details are correctly updated in redux store.
    if (joinInitiate) {
      try {
        setIsLoading(true);
        setErrorStatus(false);
        await Promise.all([
          joinFloorMeeting(),
          setupPrimaryLanguageMeeting(),
          setupSecondaryLanguageMeeting(),
          setupRelayMeeting(),
        ]);
        // Setting up recording meeting
        if (isRecordingEnabled) {
          await Promise.all([
            setupPrimaryRecordingLanguageMeeting(),
            setupSecondaryRecordingLanguageMeeting(),
          ]);
        }
        setSocketConnected(true);
        setActiveIncomingAudio(FLOOR_INCOMING_AUDIO);
        setIsLoading(false);
      } catch (error) {
        logger.debug('Error while joining meeting', error);
        setErrorStatus(true);
        await handleHangUp();
      }
    }
  };

  // #region for updating call id & recording call id
  useEffect(async () => {
    if (primaryLanguageCallData && !isRecordingEnabled) {
      logger.debug('Updating primary call data', primaryLanguageCallData);
      await updateCallDetails(primaryLanguageCallData);
    }
  }, [primaryLanguageCallData]);

  useEffect(async () => {
    if (isRecordingEnabled && primaryRecordingLanguageCallData) {
      logger.debug('Updating primary recording call data', primaryRecordingLanguageCallData);
      const combinedData = { ...primaryLanguageCallData, ...primaryRecordingLanguageCallData };
      await updateCallDetails(combinedData);
    }
  }, [primaryRecordingLanguageCallData]);

  useEffect(async () => {
    if (secondaryLanguageCallData && !isRecordingEnabled) {
      logger.debug('Updating secondary call data', secondaryLanguageCallData);
      await updateCallDetails(secondaryLanguageCallData);
    }
  }, [secondaryLanguageCallData]);

  useEffect(async () => {
    if (isRecordingEnabled && secondaryRecordingLanguageCallData) {
      logger.debug('Updating secondary recording call data', secondaryRecordingLanguageCallData);
      const combinedData = { ...secondaryLanguageCallData, ...secondaryRecordingLanguageCallData };
      await updateCallDetails(combinedData);
    }
  }, [secondaryRecordingLanguageCallData]);
  // # end region callId

  // #region stale state workarounds
  useEffect(() => {
    if (createGroupChat) {
      saveGroupChatParticipant();
    }
  }, [createGroupChat]);

  // Added for meeting end notification
  useEffect(async () => {
    if (callEnded === CALL_END_REASON[4097]) {
      await handleHangUp();
    }
  }, [callEnded]);

  useEffect(() => {
    if (addNewUser) {
      addParticipantToList(addNewUser);
    }
  }, [addNewUser]);

  useEffect(() => {
    if (removeUserFromData) {
      removeParticipantFromList(removeUserFromData);
    }
  }, [removeUserFromData]);

  useEffect(() => {
    setFullscreenError(blockScreen);
  }, [blockScreen]);

  useEffect(async () => {
    if (messageReceievd) {
      await handleNewMessageReceived(messageReceievd);
    }
  }, [messageReceievd]);

  useEffect(async () => {
    if (restUserData) {
      logger.debug('[THREAD-FLOW] (Dashboard) saving all interpreter data', restUserData);
      await onNewParticipantListReceived(restUserData.data);
    }
  }, [restUserData?.time]);
  useEffect(async () => {
    if (threadCreated !== null) {
      await addThreadToParticipant(threadCreated);
    }
  }, [threadCreated]);

  useEffect(async () => {
    await handleStatusUpdate(updateUser);
  }, [updateUser]);

  useEffect(async () => {
    if (selectedPrimaryLanguageInfo && selectedPrimaryLanguageInfo !== localPrimary) {
      await setLocalPrimary(selectedPrimaryLanguageInfo);
    }
  }, [selectedPrimaryLanguageInfo]);

  useEffect(async () => {
    if (selectedSecondaryLanguageInfo && selectedSecondaryLanguageInfo !== localSecondary) {
      await setLocalSecondary(selectedSecondaryLanguageInfo);
    }
  }, [selectedSecondaryLanguageInfo]);

  useEffect(async () => {
    if (fallBackCheck) {
      const latestThreadList = await localChatClient.getAllChatThreads();
      await syncThreadsAndParticipants(latestThreadList);
    }
  }, [fallBackCheck]);
  // #endregion

  useEffect(async () => {
    if (userRejoined) {
      logger.debug('[THREAD-FLOW] (Dashboard) userRejoied?:', userRejoined);
      if (userRejoined?.isRejoin) {
        const latestThreadList = await localChatClient.getAllChatThreads();
        await repopulateParticipantsWithThreads(latestThreadList);
      } else if (!userRejoined?.isRejoin) {
        setActiveLanguageStatus(OUTGOING_CALL_TYPE.none);
        setActiveLanguageCode(null);

        if (localPrimary && localSecondary) {
          await joinPrimaryLanguageMeeting(localPrimary);
          await joinSecondaryLanguageMeeting(localSecondary);
          if (isRecordingEnabled) {
            await joinPrimaryRecordingLanguageMeeting(localPrimary);
            await joinSecondaryRecordingLanguageMeeting(localSecondary);
          }
          // Here it is required to set again so that when we update booth it works normally.
          setSelectedPrimaryLanguageInfo(localPrimary);
          setSelectedSecondaryLanguageInfo(localSecondary);
          socketClient.emit(SOCKET_REQUEST.updateBooth, {
            boothLanguages: [localPrimary.language, localSecondary.language],
          });
        }
      }
    }
  }, [userRejoined?.time]);

  useEffect(async () => {
    let chatClient;
    const createChatClient = async (config) => {
      chatClient = new ACSChatClient();
      logger.debug('[THREAD-FLOW] (Dashboard) Chat client created');

      await setLocalChatClient(chatClient);

      await setThreadCreatedSubId(
        chatClient.subscribe(ACSChatClient.THREAD_CREATED, async (e) => {
          logger.debug('chatThreadCreatedListener', e);
          logger.debug('[THREAD-FLOW] (Dashboard) THREAD_CREATED event', e);
          setThreadCreated(e);
        })
      );
      await setThreadDeletedSubId(
        chatClient.subscribe(ACSChatClient.THREAD_DELETED, async (e) => {
          logger.debug('chatThreadDeletedListener', e);
          logger.debug('[THREAD-FLOW] (Dashboard) THREAD_DELETED event', e);
          removeUnreadThread(e.deletedThreadId);
        })
      );
      await setChatMsgReceiveSubId(
        chatClient.subscribe(ACSChatClient.CHAT_MESSAGE_RECEIVED, (e) => {
          logger.debug('[THREAD-FLOW] (Dashboard) CHAT_MESSAGE_RECEIVED event', e);
          setMessageReceived(e);
          logger.debug('chatMessageReceivedListener', e);
        })
      );

      // Initializing after subscribing to event, so that no events are missed.
      await chatClient.init(config);
      socketClient.subscribe(SOCKET_RESPONSE.userLeftMeeting, async (data) => {
        chatClient?.dashboardHandleGetAllChatThreads(data);
      });
      setTimeout(async () => {
        // Calling getAllThreads again after specific TIMEOUT_VALUE
        // So that in case if we loose event from SDK, here we can catch that
        setFallBackCheck(Date.now);
      }, FALLBACK_DELAY_MS);
      logger.debug('[THREAD-FLOW] (Dashboard) All events subscribed');
    };

    if (chatInfo.userId && acsToken && meetingId) {
      const authToken = await getInterpreterIdToken();
      logger.debug('[THREAD-FLOW] (Dashboard) Logged In, connecting to socket');
      socketClient.connect({
        meetingId,
        userId: chatInfo.userId,
        name: interpreterName,
        authToken,
      });
      const config = {
        endpointUrl: process.env.REACT_APP_CHAT_SERVICE_ENDPOINT,
        userAccessToken: chatInfo.token,
        acsId: chatInfo.userId,
        socketClient,
        userName: interpreterName,
      };
      try {
        socketClient.subscribe(SOCKET_RESPONSE.connect, async () => {
          // ================ Chat client section =============
          // Creating chat-client to get start with chat in application
          // Subscribing to all the listeners available
          logger.debug('[THREAD-FLOW] (Dashboard) Socket connected, creating chat client');
          await createChatClient(config);
          setSocketConnected(true);

          // Create group participant
          setCreateGroupChat(true);
          setConnectionStatus(SERVICE_STATUS.connected);
        });

        socketClient.subscribe(SOCKET_RESPONSE.disconnect, async () => {
          await chatClient?.cleanup();
          setSocketConnected(false);
          setConnectionStatus(SERVICE_STATUS.disconnected);
          setAcsToken(null);
        });

        socketClient.subscribe(SOCKET_RESPONSE.userJoinedMeeting, (eventData) => {
          setAddNewUser(eventData);
        });

        socketClient.subscribe(SOCKET_RESPONSE.userLeftMeeting, (eventData) => {
          setRemoveUserFromData(eventData);
        });

        socketClient.subscribe(SOCKET_RESPONSE.userLeftBooth, (eventData) => {
          setUpdateUser(eventData);
        });
        socketClient.subscribe(SOCKET_RESPONSE.userJoinedBooth, (eventData) => {
          setUpdateUser(eventData);
        });
        socketClient.subscribe(SOCKET_RESPONSE.activeLanguageUpdated, (eventData) => {
          setUpdateUser(eventData);
        });
        socketClient.subscribe(SOCKET_RESPONSE.userMuted, (eventData) => {
          setUpdateUser(eventData);
        });
        socketClient.subscribe(SOCKET_RESPONSE.userUnmuted, (eventData) => {
          setUpdateUser(eventData);
        });
        socketClient.subscribe(SOCKET_RESPONSE.userHandedOver, (eventData) => {
          setUpdateUser(eventData);
        });
        socketClient.subscribe(SOCKET_RESPONSE.userTookOver, (eventData) => {
          setUpdateUser(eventData);
        });
        socketClient.subscribe(SOCKET_RESPONSE.connectError, async (error) => {
          // clear all locally saved chat threads, including group
          await updateParticipantList({});
          // This will set group chat to null
          await setGroupChat(null);
          setCreateGroupChat(false);

          if (error?.data?.code === ERROR.TOKEN_MISSING_OR_INVALID) {
            // If token is wrong, we are getting error from middleware
            // Hence trying to reconnecting with proper token
            const newAuthToken = await getInterpreterIdToken();
            socketClient.connect({
              meetingId,
              userId: chatInfo.userId,
              name: interpreterName,
              authToken: newAuthToken,
            });
          }
        });
        socketClient.subscribe(SOCKET_RESPONSE.joinAck, (data) => {
          logger.debug('[THREAD-FLOW] (Dashboard) JoinACK event', data);
          setRestUserData({
            data: data.allUsers,
            time: Date.now(),
          });
          setUserRejoined({
            isRejoin: data.isRejoin,
            time: Date.now(),
          });
        });
      } catch (err) {
        setConnectionStatus(SERVICE_STATUS.disconnected);
        logger.error('Error: While creating chat-client', err);
        // Retrying to create chat-client
        await createChatClient(config);
      }
    }
  }, [chatInfo.userId, acsToken, meetingId]);

  useEffect(async () => {
    if (!interpreterDetails) {
      try {
        const details = await getInterpreterDetails();
        // save to redux
        await setInterpreterDetails(details);
        setJoinInitiate(details);
      } catch (err) {
        logger.error('Error: while setting Interpreter details locally', err);
      }
    }
  }, [interpreterDetails]);

  useEffect(() => {
    return meetingCleanUp;
  }, []);

  useEffect(() => {
    if (!socketConnected) {
      showToast({
        data: {
          content: t('NO_LONGER_CONNECTED_TO_SOCKET'),
          autoClose: false,
          type: TOAST_TYPE.error,
        },
      });
      if (threadCreatedSubId) {
        logger.debug('[THREAD-FLOW] (Dashboard) Unsubscribing threadCreatedSubId');

        localChatClient.unsubscribe(threadCreatedSubId);
      }
      if (threadDeletedSubId) {
        localChatClient.unsubscribe(threadDeletedSubId);
        logger.debug('[THREAD-FLOW] (Dashboard) Unsubscribing threadDeletedSubId');
      }
      if (chatMsgReceiveSubId) {
        localChatClient.unsubscribe(chatMsgReceiveSubId);
        logger.debug('[THREAD-FLOW] (Dashboard) Unsubscribing chatMsgReceiveSubId');
      }
    }
  }, [socketConnected]);

  // FIXME: Workaround to ensure joinMeetings should run only after the interpreter details are correctly updated in redux store.
  useEffect(() => {
    joinMeetings();
  }, [joinInitiate]);

  const headerHeight = document.getElementById('headerControlsContainer')?.clientHeight;

  if (isLoading) return <FullScreenLoader label={t('LOADING_MESSAGE')} />;
  return (
    <Flex fill column hAlign="center" id="interpreterContainer" className="background-color-2">
      <ServiceErrorOverlay open={showFullscreenError} />
      {/* Header controls */}
      <Flex
        id="headerControlsContainer"
        className="p-4 headerControlsContainer"
        gap="gap.medium"
        fill
      >
        <FlexItem className="px-3">
          <div className="sectionBorder verticalCenter">
            <AkouoLogoGrey />
          </div>
        </FlexItem>
        <FlexItem>
          <div className="sectionBorder flexGrow1">
            <ControlHeader content={t('INCOMING')} icon={earphones} />
            <IncomingMediaController />
          </div>
        </FlexItem>
        <OutgoingLanguageMeeting languages={languageMeetings} interpreterName={interpreterName} />
        <FlexItem className="verticalCenter px-3">
          <div className="sectionBorder">
            <HangUpButton onClick={() => handleHangUp(LOGOUT_TRIGGER.manual)} />
          </div>
        </FlexItem>
      </Flex>

      {/* Video gallery and chat window */}
      <Flex
        fill
        className="p-4 background-color-2 chatWindowFlex flexColumnGap flexJustifyContentSpaceBetween"
        style={{ height: `100% - ${headerHeight}` }}
      >
        <VideoGallery id="videoGallery" />
        <ChatWindow socketConnected={socketConnected} />
      </Flex>
      <ToastContainer />
    </Flex>
  );
};

export default Dashboard;
