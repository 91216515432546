import { useEffect, useState } from 'react';
import { Text, Flex, Dialog } from '@fluentui/react-northstar';
import AkouoSupport from 'COMPONENTS/common/AkouoSupport';
import { getTeamsContext } from 'FEATURES/teams/utils/teamsUtils';
import OverlayLoader from 'COMPONENTS/common/OverlayLoader/OverlayLoader';
import { logAuditEvent } from 'SERVICES/auditLogger';
import { AUDIT_EVENT_TYPE } from 'CONSTANTS/apiConstants';
import { useSelector } from 'react-redux';
import logger from 'SERVICES/logger';
import { selectLanguageForCaptions } from 'UTILS/languagesUtils';
import { teamsMeetingSelector } from 'FEATURES/teams/slices/teamsMeetingSlice';

import { SNACKBAR_DURATION } from 'CONSTANTS/enum';
import useInMeeting from './useInMeeting';
import LanguageSelectionDropdown from './LanguageSelectionDropdown/LanguageSelectionDropdown';

const InMeeting = () => {
  const {
    t,
    loading,
    selectedLanguage,
    showCountDownTimer,
    interpreterMeetings,
    setUnsupportedPopup,
    showUnsupportedPopup,
    handleLanguageSelection,
  } = useInMeeting();

  const [ccOn, setCcOn] = useState(false);
  const [captionData, setCaptionData] = useState(false);

  // Max 21 seconds it take to load
  const [timer, setTimer] = useState(21);
  const teamsMeetingDetail = useSelector(teamsMeetingSelector.teamsMeetingDetail);

  useEffect(async () => {
    // TODO: HOOKS - context should be immediately available via useTeams hook
    // but its value is available after a delay, so sgetTeamsContext is used directly
    const context = await getTeamsContext();
    logAuditEvent({
      Timestamp: new Date(),
      Meeting_ID: context.meetingId,
      Event_type: AUDIT_EVENT_TYPE.msTeamsLogin,
      Field_1: context.userObjectId,
      Field_3: context.userPrincipalName,
    });
  }, []);

  useEffect(() => {
    if (ccOn) {
      const captionLanguage = selectLanguageForCaptions(
        teamsMeetingDetail?.captionEvents,
        selectedLanguage?.value
      );
      setCaptionData(captionLanguage);
      logger.info(`Captioning event ${captionLanguage.eventname}`);
    }

    if (!selectedLanguage) {
      setCcOn(false);
    }
  }, [selectedLanguage, ccOn]);
  // Storing in a variable so that we can clear
  let timeoutForCounter;
  if (showCountDownTimer) {
    // This timeout is to show timer on spinner
    timeoutForCounter = setTimeout(() => {
      if (timer < 31 && timer > 0) {
        setTimer(timer - 1);
      }
    }, SNACKBAR_DURATION.SHORT);
  } else if (timeoutForCounter) {
    clearTimeout(timeoutForCounter);
  }

  return (
    <>
      <Dialog
        open={showUnsupportedPopup}
        onConfirm={() => setUnsupportedPopup(false)}
        confirmButton={t('OK')}
        content={t('NOT_SUPPORTED_ON_DEVICE_MSG')}
      />
      {interpreterMeetings?.length === 0 ? (
        <Text>
          <Flex column>
            <span>{t('NO_LANGUAGES_CONFIGURED_PARTICIPANT')}</span>
            <AkouoSupport className="pt-1" />
          </Flex>
        </Text>
      ) : (
        <>
          <LanguageSelectionDropdown
            interpreterMeetings={interpreterMeetings}
            handleLanguageSelection={handleLanguageSelection}
            selectedLanguage={selectedLanguage}
            teamsMeetingDetail={teamsMeetingDetail}
            ccOn={ccOn}
            setCcOn={setCcOn}
          />
          {ccOn && (
            <iframe
              title="captions"
              id="stFrame"
              src={`//www.streamtext.net/player/?event=${captionData.eventname}&language=${captionData.sourcelanguageid}&chat=false&title=off&controls-transcript=off&bgc=222&fgc=fff&ff=Helvetica&fs=14`}
              style={{ width: '100%', minHeight: '65%', display: `${ccOn ? 'block' : 'none'}` }}
            />
          )}
        </>
      )}
      {showCountDownTimer && (
        <OverlayLoader label={`${t('LOADING_MEETING_PLACEHOLDER')} ${timer} ${t('SECONDS')}`} />
      )}

      {loading && <OverlayLoader label={loading} />}
    </>
  );
};

export default InMeeting;
