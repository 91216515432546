import {
  Provider,
  teamsTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
} from '@fluentui/react-northstar';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

import logger from 'SERVICES/logger';
import useActions from 'HOOKS/useActions';
import { appActions } from 'STORE/appSlice';
import { getTeamsContext } from 'FEATURES/teams/utils/teamsUtils';

const style = { height: '100vh', background: 'transparent' };

const ThemeProvider = ({ children }) => {
  const dispatchAppThemeMode = useActions(appActions.setAppThemeMode);
  const [theme, setTheme] = useState(teamsTheme);

  const themeChangeHandler = (themeMode) => {
    logger.debug(`Theme is change to ${themeMode}`);
    dispatchAppThemeMode(themeMode || 'default');
    switch (themeMode) {
      case 'dark':
        setTheme(teamsDarkTheme);
        break;
      case 'contrast':
        setTheme(teamsHighContrastTheme);
        break;
      case 'default':
      default:
        setTheme(teamsTheme);
    }
  };

  const setThemeAndRegisterThemeHandler = async () => {
    const context = await getTeamsContext();
    themeChangeHandler(context.theme);
    microsoftTeams.registerOnThemeChangeHandler(themeChangeHandler);
  };

  useEffect(() => {
    setThemeAndRegisterThemeHandler();
  }, []);

  return (
    <Provider theme={theme} style={style}>
      {children}
    </Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
