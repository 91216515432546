export const RECORDER_LANGUAGE_MEETING = 'RECORDER_LANGUAGE_MEETING';

export const CALL_RECORDER_EVENT = {
  PARTICIPANT_MUTED_CHANGED: 'isMutedChanged',
  REMOTE_PARTICIPANT_UPDATED: 'remoteParticipantsUpdated',
};

export const API = {
  meetings: '/recording/meeting',
  fetchAcsToken: '/recording/identity/access-token',
  startRecording: '/recording/operations/start',
  stopRecording: '/recording/operations/stop',
};

export const RECORDING_TIMEOUT = 3000;
