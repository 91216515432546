import logger from 'SERVICES/logger';
import { useEffect, useState } from 'react';
import { appInitialization } from '@microsoft/teams-js';
import {
  useTeams,
  getIdToken as getTeamIdToken,
  languages as teamsTranslations,
} from 'FEATURES/teams';
import { injectCallbackToGetAccessToken } from 'SERVICES/axiosInit';
import { addI18nResources, useTranslation } from 'SERVICES/i18n'; // also initializing i18n
import { languages as interpreterTranslations, getInterpreterIdToken } from 'FEATURES/interpreter';
import { languages as participantTranslations } from 'FEATURES/participant';
import { getRecorderIdToken, languages as recorderTranslations } from 'FEATURES/recorder';
import ROUTES from 'CONSTANTS/routes';

const useAppInitializer = () => {
  const { inTeams, context } = useTeams();
  const [isInitialized, setIsInitialized] = useState(false);
  const { i18n } = useTranslation();

  const path = window.location.pathname;

  useEffect(() => {
    logger.info(`Package Version: ${process.env.REACT_APP_VERSION}`);
    logger.info(`Env : ${process.env.REACT_APP_ENV}`);
  }, []);

  const initializeTeams = () => {
    injectCallbackToGetAccessToken(getTeamIdToken);
    addI18nResources('teams', teamsTranslations);
  };

  const initializeInterpreter = () => {
    injectCallbackToGetAccessToken(getInterpreterIdToken);
    addI18nResources('interpreter', interpreterTranslations);
  };

  const initializeParticipant = () => {
    injectCallbackToGetAccessToken(() => {
      // there is no authentication for participant APIs
      return null;
    });
    addI18nResources('participant', participantTranslations);
  };

  const initializeRecorder = () => {
    injectCallbackToGetAccessToken(getRecorderIdToken);
    addI18nResources('recorder', recorderTranslations);
  };

  useEffect(() => {
    const teamsLocalLang = context?.locale.substring(0, context?.locale.indexOf('-')); // storing language part (not country code) from teams contex
    i18n.changeLanguage(teamsLocalLang);
  }, [context]);

  useEffect(() => {
    if (inTeams !== undefined) {
      setIsInitialized(true);
      if (inTeams === true) {
        logger.info('In Microsoft Teams!');
        // To stop teams default spinner
        appInitialization.notifySuccess();
        initializeTeams();
      } else {
        logger.info('Not in Microsoft Teams');
        if (path.startsWith(ROUTES.INTERPRETER)) {
          initializeInterpreter();
        } else if (path.startsWith(ROUTES.PARTICIPANT)) {
          initializeParticipant();
        } else if (path.startsWith(ROUTES.RECORDER)) {
          initializeRecorder();
        }
      }
    }
  }, [inTeams]);

  return { isInitialized };
};
export default useAppInitializer;
