import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './CustomToastContainer.scss';

const TOAST_POSITION = Object.freeze({
  center: 'top-center',
  bottom: 'bottom-left',
});

export const TOAST_TYPE = Object.freeze({
  info: 'info',
  success: 'success',
  error: 'error',
  warning: 'warning',
});

export const TOAST_DURATION = Object.freeze({
  long: 5000,
  short: 2000,
});

export const TOAST_THEME = Object.freeze({
  light: 'light',
  dark: 'dark',
  colored: 'colored',
});

export const showToast = ({ data }) => {
  return toast(data?.content, {
    autoClose: data.autoClose,
    closeOnClick: data.closeOnClick,
    pauseOnHover: data.pauseOnHover,
    type: data.type,
    draggable: data.draggable,
    theme: data.theme,
    newestOnTop: data.newestOnTop,
    position: !data.autoClose ? TOAST_POSITION.bottom : TOAST_POSITION.center,
  });
};

/**
 * 
 * @param {*} data - default options to render the toast 
 * 
 * example - 
 * data: {
      content: 'Text you'd like to show',
      position: TOAST_POSITION,
      hideProgressBar: true | false,
      closeOnClick: true | false,
      type: TOAST_TYPE,
      pauseOnHover: true | false,
      autoClose: TOAST_DURATION | false,
      newestOnTop: true | false,
      rtl : true | false,
      draggable: true | false,
      pauseOnHover: true | false
     },
 * 
 * @returns 
 */
export const CustomToastContainer = ({ data }) => {
  return (
    <ToastContainer
      position={data?.position}
      type={data?.type || TOAST_TYPE.info}
      autoClose={data?.autoClose || false}
      hideProgressBar={data?.hideProgressBar || false}
      newestOnTop={data?.newestOnTop || true}
      closeOnClick={data?.closeOnClick || false}
      rtl={data?.rtl || false}
      pauseOnFocusLoss
      draggable={data?.draggable || false}
      pauseOnHover={data?.pauseOnHover || true}
    />
  );
};
