import { PrimaryButton, Stack, initializeIcons } from '@fluentui/react';
import { Flex, FlexItem, Text, Button } from '@fluentui/react-northstar';
import { IconButton } from '@fluentui/react/lib/Button';
import { useState, useEffect, useRef } from 'react';
import ACSCallClient from 'SERVICES/callClient/index';
import { LocalAudioStream } from '@azure/communication-calling';
import { ERROR } from 'CONSTANTS/apiConstants';
import { useTranslation } from 'SERVICES/i18n';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import Home from 'FEATURES/home/Home';
import './RecorderHome.scss';
import AkouoLogoGrey from 'FEATURES/interpreter/features/Dashboard/components/AkouoLogo';
import logger from 'SERVICES/logger';
import {
  fetchACSAccessToken,
  getRecorderMeetingDetails,
  startRecordingCall,
  stopRecordingCall,
} from 'FEATURES/recorder/api';
import { useParams } from 'react-router-dom';
import { appSelector } from 'STORE/appSlice';
import { useSelector } from 'react-redux';
import OverlayLoader from 'COMPONENTS/common/OverlayLoader/OverlayLoader';
import { RECORDING_TIMEOUT } from 'FEATURES/recorder/constants';
import { loginRequest } from '../Authentication/authConfig';
import RecorderLanguageCall from './RecorderLanguageCall';

const { CALL_STATE, STATE_CHANGED, CALL_UPDATED } = ACSCallClient;

const RecorderHome = () => {
  const [teamsStream, setTeamsStream] = useState(undefined);
  const [teamsCall, setTeamsCall] = useState(undefined);
  const [recordButtonVisibility, setRecordButtonVisibility] = useState(true);
  const [languagesCalls, setLanguagesCalls] = useState(undefined);
  const [meetingDetail, setMeetingDetail] = useState(undefined);
  const [apiError, setApiError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isRecordingStop, setIsRecordingStop] = useState(false);

  const errorTimestamp = useSelector(appSelector.errorTimestamp);
  // To maintain mapping
  const serverCallMapping = useRef();
  const { meetingId } = useParams();
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { t } = useTranslation('recorder', { keyPrefix: 'RECORDING' });

  initializeIcons();
  const setUpTeamsCall = async (teamMeetingUrl) => {
    setLoadingMessage(t('JOINING_MEETING_MESSAGE'));

    // Joining as a teams identity
    const token = await fetchACSAccessToken();

    const config = {
      acsToken: token,
    };

    const callClientObj = new ACSCallClient();
    await callClientObj.setupCallTeamsCall(config);
    setTeamsCall(callClientObj);
    callClientObj.subscribe(CALL_UPDATED, (addedCall) => {
      try {
        addedCall?.on(STATE_CHANGED, async () => {
          if (addedCall.state === CALL_STATE.connected) {
            setLoadingMessage(t('SETTING_UP_MESSAGE'));
            setTimeout(async () => {
              const remoteAudioStream = addedCall.remoteAudioStreams[0];
              const mediaStreamTrack = await remoteAudioStream?.getMediaStreamTrack();
              setTeamsStream(new LocalAudioStream(mediaStreamTrack));
              logger.debug('Able to get teams call stream');
            }, RECORDING_TIMEOUT);
          }
        });
      } catch (error) {
        logger.error('Error In Connecting to teams call', error);
        throw error;
      }
    });
    callClientObj.joinMeeting({ meetingLink: teamMeetingUrl });
  };

  const homeComponent = () => {
    return <Home />;
  };

  const startRecording = async () => {
    logger.debug('Start recording cliecked');
    setRecordButtonVisibility(false);
    await startRecordingCall({ meetingId, recordingData: serverCallMapping.current });
  };

  const cleanupRecorderHome = async () => {
    // Disconnect teams calls
    logger.debug('Hanging up teams call');
    await teamsCall?.hangupCall();
    logger.debug('Cleaning up teams call');
    await teamsCall?.disposeCall();

    setTeamsStream(undefined);
    // Clear local mapping
    serverCallMapping.current = undefined;
    // Disconnecting language call
    setLanguagesCalls([]);
    setTeamsCall(undefined);
    setTeamsStream(undefined);
  };

  const stopRecording = async () => {
    logger.debug('Stop recording cliecked');
    setIsRecordingStop(true);
    setRecordButtonVisibility(true);
    await stopRecordingCall(serverCallMapping.current);
    // Cleanup teams call
    await cleanupRecorderHome();
  };

  const emojiIcon = { iconName: 'Location' };

  const updateServerCallId = (data) => {
    if (serverCallMapping.current) {
      serverCallMapping.current.push(data);
    } else {
      serverCallMapping.current = [data];
    }
    // Ensuring all the serverCallId's are with us before we start recording
    if (serverCallMapping?.current?.length === languagesCalls.length) {
      setRecordButtonVisibility(true);
      setIsLoading(false);
    }
  };
  // init
  useEffect(async () => {
    const [account] = accounts;

    try {
      if (isAuthenticated && account) {
        logger.debug('Name:', account.idTokenClaims.name);
        logger.debug('AdB2cId::', account.idTokenClaims.sub);
        if (account.idTokenClaims.sub) {
          setIsLoading(true);
          // fetchMeeting data
          setLoadingMessage(t('FETCH_MEETING_MESSAGE'));
          const responseFromMeetingAPI = await getRecorderMeetingDetails(meetingId);
          setMeetingDetail(responseFromMeetingAPI);

          // join teams call
          await setUpTeamsCall(responseFromMeetingAPI.joinUrl);

          // create language calls array
          const allData = responseFromMeetingAPI.interpretation;
          const languagesElement = [];
          allData.forEach(async (languageElement) => {
            const accessToken = await fetchACSAccessToken(languageElement.recorderAcsId);
            languagesElement.push({
              token: accessToken,
              languageCode: languageElement.language,
              recordingMeetingId: languageElement.recordingMeetingId,
            });
          });
          setLanguagesCalls(languagesElement);
        }
      }
    } catch (err) {
      logger.error(err);
      setApiError(err.code === ERROR.FEATURE_NOT_ENABLED ? err?.message : t('API_ERROR'));
    }
  }, [accounts, isAuthenticated]);

  useEffect(() => {
    if (errorTimestamp) {
      setApiError(t('API_ERROR'));
    }
  }, [errorTimestamp]);

  useEffect(() => {
    return cleanupRecorderHome;
  }, []);

  return apiError ? (
    <div className="message">
      <Text weight="bold" align="center">
        {apiError}
      </Text>
    </div>
  ) : (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={homeComponent}
    >
      {isLoading && <OverlayLoader label={loadingMessage} />}
      {!isRecordingStop ? (
        <div className="recording-body">
          <Text weight="bold">{meetingDetail?.subject}</Text>
          <FlexItem>
            <Flex>
              <div style={{ margin: '15px' }}>
                <AkouoLogoGrey />
              </div>
            </Flex>
          </FlexItem>

          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <PrimaryButton
              text={t('START_BUTTON_TEXT')}
              onClick={startRecording}
              disabled={!teamsStream || !recordButtonVisibility}
            />
            <PrimaryButton
              text={t('STOP_BUTTON_TEXT')}
              onClick={stopRecording}
              disabled={recordButtonVisibility}
            />
            <IconButton
              className={recordButtonVisibility ? 'greyButton' : 'redButton'}
              iconProps={emojiIcon}
              title="Emoji"
              ariaLabel="Emoji"
              disabled="true"
            />
          </Stack>

          <Flex gap="gap.small" style={{ margin: '15px' }}>
            {meetingDetail?.interpretation?.map((languageEle) => {
              return <Button content={languageEle.language} disabled="true" size="small" />;
            })}
          </Flex>
          {teamsStream &&
            languagesCalls?.map((languageCallData) => {
              return (
                <RecorderLanguageCall
                  languageCallData={languageCallData}
                  teamsStream={teamsStream}
                  updateServerCallId={updateServerCallId}
                />
              );
            })}
        </div>
      ) : (
        <div className="message">
          <Text weight="semibold" align="center">
            {t('AFTER_RECORDING_MESSAGE')}
          </Text>
        </div>
      )}
    </MsalAuthenticationTemplate>
  );
};

export default RecorderHome;
