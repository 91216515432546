/* eslint-disable no-restricted-syntax */
import logger from 'SERVICES/logger';

class ChatThread {
  static TOPIC = {
    group: 'groupTopic',
    oneOnOne: 'oneOnOneTopic',
  };

  /**
   * This will used to get details of chat-thread
   * @param {chatClient} chatClient
   * @param {chatRequest} chatRequest
   * @param {boolean} isGroupChat
   */
  async init(chatClient, chatRequest, isGroupChat) {
    const threadObj = await chatClient.createChatThread(
      { topic: chatRequest.topic },
      { participants: chatRequest.participants }
    );
    const threadClient = chatClient.getChatThreadClient(threadObj.chatThread.id);
    const properties = await threadClient.getProperties();

    this._setPropertiesForThread({
      threadId: threadObj.chatThread.id,
      threadClient,
      properties,
      displayName: chatRequest.participants[0].displayName,
      isGroupChat,
      userId: chatRequest.participants[0].id.communicationUserId,
    });
  }

  /**
   * This function will be used to get details of thread
   * @param {chatClient} chatClient
   * @param {string} threadId
   * @param {string} groupTopic
   */
  async updateThreadDetails(chatClient, threadId, selfAcsId) {
    const threadClient = chatClient.getChatThreadClient(threadId);
    const properties = await threadClient.getProperties();
    const participants = await threadClient.listParticipants();
    let displayName;
    let isGroupChat;
    let userId;

    if (properties.topic !== ChatThread.TOPIC.group) {
      for await (const participantInfo of participants) {
        if (participantInfo.id.communicationUserId !== selfAcsId) {
          displayName = participantInfo.displayName;
          isGroupChat = false;
          userId = participantInfo.id.communicationUserId;
        }
      }
    } else {
      // Group case
      displayName = '';
      userId = '';
      isGroupChat = true;
    }
    this._setPropertiesForThread({
      threadId,
      threadClient,
      properties,
      displayName,
      isGroupChat,
      userId,
    });
  }

  _setPropertiesForThread(threadInformation) {
    this._threadClient = threadInformation.threadClient;
    this._properties = threadInformation.properties;
    this.threadId = threadInformation.threadId;
    this.hasUnreadMessages = false;
    this.lastMessageTimeStamp = new Date();
    this.isGroupChat = threadInformation.isGroupChat;
    this.user = {
      id: threadInformation.userId,
      name: threadInformation.displayName,
    };
  }

  /**
   * This method will be used to send message in chat-thread
   * @param {string} content
   * @param {string} displayName
   * @returns string {messageId}
   */
  async sendMessageRequest(content, displayName) {
    logger.debug('sendMessageRequest', content, displayName);
    try {
      const messageId = await this._threadClient.sendMessage(
        { content },
        { senderDisplayName: displayName }
      );
      return messageId;
    } catch (err) {
      // DISCONNECTION CASE ERROR
      logger.error('Error: While sending message', err);
      throw err;
    }
  }

  /**
   * This method will be used to fetch chat history
   * @returns list of messages
   */
  async getAllMessages() {
    logger.debug('getAllMessages');
    const chatMessageList = [];
    try {
      const allMessages = await this._threadClient.listMessages();

      // As we are dealing with async data, we used for with await
      for await (const msgElement of allMessages) {
        // Currently we are just displaying text messages
        // Participant add/remove messages are also coming in this
        if (msgElement.type === 'text') {
          chatMessageList.push({
            id: msgElement.id,
            content: msgElement.content.message,
            senderDisplayName: msgElement.senderDisplayName,
            createdOn: msgElement.createdOn,
            senderId: msgElement.sender.communicationUserId,
            recipientId: msgElement.content.initiator,
          });
        }
      }
    } catch (err) {
      logger.error('Error: While fetching chatHistory', err);
      throw err;
    }

    return chatMessageList;
  }

  /**
   * This method will be used to get all user in chat-thread
   * @returns list of participants in calling thread
   */
  async getAllParticipants() {
    logger.debug('getAllParticipants');
    const participantList = [];

    try {
      const allParticipants = await this._threadClient.listParticipants();

      // As we are dealing with async data, we used for with await
      for await (const participant of allParticipants) {
        participantList.push({
          id: participant.id.communicationUserId,
          name: participant.displayName,
        });
      }
    } catch (err) {
      // DISCONNECTION CASE ERROR
      logger.error('Error: While fetching all participants', err);
      throw err;
    }

    return participantList;
  }

  /**
   * This method will be used to add participant in group chat
   * @param {information} participants
   */
  async addMemberInGroup(participants) {
    logger.debug('addMemberInGroup', participants);
    try {
      await this._threadClient.addParticipants({
        participants,
      });
      logger.debug('[THREAD-FLOW] (Chatthread) member added to group SDK', participants);
    } catch (err) {
      logger.error('Error: While adding member in group', err);
      throw err;
    }
    logger.debug('Member added to group');
  }

  /**
   * This method will be used to update timestamp
   * @param {date} timestamp
   */
  updateTimestamp(timestamp) {
    this.lastMessageTimeStamp = timestamp;
  }

  /**
   * This method will be used to update the unread status
   * @param {boolean} isUnread
   */
  updateUnreadMessage(isUnread) {
    this.hasUnreadMessages = isUnread;
  }
}

export default ChatThread;
