export class Participant {
  constructor({ user }) {
    this.user = user;
    this.activeLanguage = null;
    this.outputLanguages = [];
    this.thread = undefined;
  }

  /**
   * @param  {} thread: Thread object which we received from SDK
   */
  updateThread(thread) {
    this.thread = thread;
  }

  /**
   * @param  {} activeLanguage: active language of participant
   */
  updateActiveLanguage(activeLanguage) {
    this.activeLanguage = activeLanguage;
  }

  /**
   * @param  {} outputLanguages: current booth info
   */
  updateOutputLanguage(outputLanguages) {
    this.outputLanguages = outputLanguages;
  }
}
