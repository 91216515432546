import { useTranslation } from 'SERVICES/i18n';
import { Flex, Text } from '@fluentui/react-northstar';
import FullScreenLoader from 'COMPONENTS/common/FullScreenLoader';

const CallStatus = ({ callState, noParticipants }) => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'FLOOR_MEETING' });

  if (callState === 'Connected' && noParticipants) {
    return (
      <Flex fill hAlign="center" vAlign="center">
        <Text content={t('NO_PARTICIPANTS')} />
      </Flex>
    );
  }
  if (callState === 'Connected' || callState === 'Disconnected') return null;

  const stringMapping = {
    Connecting: t('CONNECTING'),
    InLobby: t('IN_LOBBY'),
    Disconnecting: t('DISCONNECTING'),
  };
  return <FullScreenLoader label={stringMapping[callState]} />;
};

export default CallStatus;
