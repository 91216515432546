import { useEffect, useRef, useState } from 'react';
import { AUDIO_KEYWORD } from 'FEATURES/interpreter/constants';
import { STREAM_TAGS } from 'SERVICES/audioElementUtils';
import logger from 'SERVICES/logger';

const useAudioController = () => {
  const floorAudioRef = useRef();
  const languageAudioRef = useRef();

  const [volumeFloor, setVolumeFloor] = useState(1);
  const [volumeLanguage, setVolumeLanguage] = useState(1);

  // set Audio Element
  const audios = document.querySelectorAll(AUDIO_KEYWORD);

  const changeIncomingVolume = (value, type) => {
    logger.debug('changeIncomingVolume.....', value, type);
    if (floorAudioRef.current && type === STREAM_TAGS.TEAMS) {
      floorAudioRef.current.muted = false;
      floorAudioRef.current.volume = value;
      setVolumeFloor(value); // value is stored in local variable so we can pass it to audioSlider UI component
    }
    if (languageAudioRef.current && type === STREAM_TAGS.LANGUAGE) {
      languageAudioRef.current.muted = false;
      languageAudioRef.current.volume = value;

      setVolumeLanguage(value); // value is stored in local variable so we can pass it to audioSlider UI component
    }
  };

  const muteIncomingAudio = (type) => {
    logger.info('Muting Audio...', type);
    // const audiosList = document.querySelectorAll(AUDIO_KEYWORD);
    if (floorAudioRef.current && type === STREAM_TAGS.TEAMS) {
      floorAudioRef.current.muted = true;
      setVolumeFloor(0);
    }
    if (languageAudioRef.current && type === STREAM_TAGS.LANGUAGE) {
      languageAudioRef.current.muted = true;
      setVolumeLanguage(0);
    }
  };

  const unmuteIncomingAudio = (type) => {
    logger.info('Unmuting Audio...', type);
    if (languageAudioRef.current && type === STREAM_TAGS.LANGUAGE) {
      languageAudioRef.current.muted = false;
      setVolumeLanguage(1);
    }
    if (floorAudioRef.current && type === STREAM_TAGS.TEAMS) {
      floorAudioRef.current.muted = false;
      setVolumeFloor(1);
    }
  };

  useEffect(() => {
    audios.forEach((audioEle) => {
      if (audioEle.streamName === STREAM_TAGS.TEAMS) {
        floorAudioRef.current = audioEle;
      }
      if (audioEle.streamName === STREAM_TAGS.LANGUAGE) {
        languageAudioRef.current = audioEle;
      }
    });
    logger.debug('floorAudioRef', floorAudioRef.current?.streamName);
    logger.debug('languageAudioRef', languageAudioRef.current?.streamName);
    // AKMSTEAMS-635
    if (volumeLanguage === 0) {
      languageAudioRef.current.muted = true;
    } else if (languageAudioRef.current) {
      languageAudioRef.current.volume = volumeLanguage;
    }

    if (volumeFloor === 0) {
      floorAudioRef.current.muted = true;
    } else if (floorAudioRef.current) {
      floorAudioRef.current.volume = volumeFloor;
    }
  }, [audios]);

  return {
    volumeFloor,
    volumeLanguage,
    changeIncomingVolume,
    muteIncomingAudio,
    unmuteIncomingAudio,
  };
};

export default useAudioController;
