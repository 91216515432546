import logger from 'SERVICES/logger';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { CallClient } from '@azure/communication-calling';
import { fetchACSAccessToken } from 'FEATURES/interpreter/api/api';
import { setLogLevel } from '@azure/logger';
import acsUtils from './acsUtils';

setLogLevel('verbose');

class ACSCallClient {
  static CALL_UPDATED = 'callsUpdated';

  static STATE_CHANGED = 'stateChanged';

  static CALL_STATE = {
    none: 'None',
    connected: 'Connected',
    disconnected: 'Disconnected',
    other: 'Other',
  };

  /**
   * This method initialise the Call client Object
   * @param {*} config
   */
  async setupCall(config) {
    try {
      this.listeners = new Map();
      this.config = config;
      this.callClient = new CallClient();
      this.tokenCredential = new AzureCommunicationTokenCredential(this.config.acsToken);
      this.callAgent = await this.callClient.createCallAgent(this.tokenCredential, {
        displayName: this.config.displayName,
      });
      this.callAgent.on(ACSCallClient.CALL_UPDATED, async (eventData) => {
        logger.debug('Current call', this.call);
        if (eventData?.added?.length !== 0) {
          logger.debug('Call added', eventData.added);
          [this.call] = eventData.added;
        }
        if (eventData?.removed.length !== 0 && this.call.id === eventData?.removed[0]?.id) {
          logger.debug('Call removed', eventData?.removed);
          this.call = null;
        }
        this._notify(ACSCallClient.CALL_UPDATED, this.call);
      });
    } catch (error) {
      logger.error('Error in acs call setup', error);
      throw error;
    }
  }

  async setupCallTeamsCall(config) {
    try {
      this.listeners = new Map();
      this.config = config;
      this.callClient = new CallClient();
      this.tokenCredential = new AzureCommunicationTokenCredential({
        refreshProactively: true,
        tokenRefresher: async () => fetchACSAccessToken(),
      });
      this.callAgent = await this.callClient.createTeamsCallAgent(this.tokenCredential, {
        displayName: this.config.displayName,
      });
      this.callAgent.on(ACSCallClient.CALL_UPDATED, async (eventData) => {
        if (eventData?.added?.length !== 0) {
          [this.call] = eventData.added;
        }
        if (eventData?.removed.length !== 0) {
          this.call = null;
        }
        this._notify(ACSCallClient.CALL_UPDATED, this.call);
      });
    } catch (error) {
      logger.error('Error in teams acs call setup', error);
      throw error;
    }
  }

  /**
   * This method returns deviceManager from acsUtils
   * @returns Object {devicemanager}
   */
  async getACSDeviceManager() {
    const deviceManager = await acsUtils.getDeviceManager(this.callClient);
    return deviceManager;
  }

  /**
   * This method will be used to join call
   * @param {Object} meetingId
   * @param {Object} mediaOptions { muted: boolean }
   */
  joinMeeting(meetingId, mediaOptions = { muted: true }) {
    try {
      this.callAgent.join(meetingId, { audioOptions: mediaOptions });
    } catch (error) {
      logger.error('Error while joining call in acsCallClient', error);
      throw error;
    }
  }

  /**
   * This method will be used to hangup call
   */
  async hangupCall() {
    try {
      await this.call?.hangUp();
    } catch (error) {
      logger.error('Error while call hangup in acsCallClient', error);
      throw error;
    }
  }

  /**
   * This method will be used to dispose Call
   */
  async disposeCall() {
    try {
      await this.callAgent?.dispose();
      this._cleanUp();
    } catch (error) {
      logger.error('Error while disposing call in acsCallClient', error);
    }
  }

  /**
   * This method use to mute call
   */
  async muteCall() {
    try {
      if (!this.call?.isMuted) {
        await this.call?.mute();
      }
    } catch (error) {
      logger.error('Error while muting call', error);
    }
  }

  /**
   * This method use to unmute call
   */
  async unmuteCall() {
    try {
      await this.call?.unmute();
    } catch (error) {
      logger.error('Error while unmuting call', error);
    }
  }

  /**
   * This will mute the incoming audio
   */
  async muteIncomingCall() {
    try {
      await this.call?.muteIncomingAudio();
    } catch (error) {
      logger.error('Error while muting incoming audio');
    }
  }

  /**
   * This will unmute the incoming audio
   */
  async unmuteIncomingCall() {
    try {
      await this.call?.unmuteIncomingAudio();
    } catch (error) {
      logger.error('Error while unmuting incoming audio');
    }
  }

  /**
   * This method use to check if the call is muted or not
   * @returns boolean
   */
  isCallMuted() {
    return !!this.call.isMuted;
  }

  /**
   * This method check is call available or not
   * @returns boolean
   */
  isCallAvailable() {
    return !!this.call;
  }

  /**
   * This method use to get call state
   * @returns string {call.State}
   */
  getCallState() {
    if (
      this.call?.state === ACSCallClient.CALL_STATE.none ||
      this.call?.state === ACSCallClient.CALL_STATE.connected ||
      this.call?.state === ACSCallClient.CALL_STATE.disconnected
    ) {
      return this.call?.state;
    }
    return ACSCallClient.CALL_STATE.other;
  }

  /**
   * This method will be used to subscribe for listener
   * @param {string} eventName
   * @param {function} callback
   * @returns string {subscriberId}
   */
  subscribe(eventName, callback) {
    try {
      let listenersForEvent = this.listeners.get(eventName);
      if (!listenersForEvent) {
        listenersForEvent = [];
      }
      // We used randomId to give unique identifier for subscriberID
      const randomId = (Math.random() + 1).toString(36).substring(2);
      listenersForEvent.push({
        id: randomId,
        callback,
      });
      this.listeners.set(eventName, listenersForEvent);

      return randomId;
    } catch (err) {
      logger.error('Error: While subscribing event', eventName, err);
      throw err;
    }
  }

  _notify(eventName, eventData) {
    try {
      const listenersForEvent = this.listeners.get(eventName);
      const dataToSend = eventData;
      if (listenersForEvent) {
        listenersForEvent.forEach((callbackObj) => {
          callbackObj.callback(dataToSend);
        });
      }
    } catch (error) {
      logger.error('Error: While executing listener', error);
      throw error;
    }
  }

  _cleanUp() {
    this.listeners?.clear();
    this.config = {};
    this.listeners = null;
    this.call = null;
    this.callAgent = null;
    this.tokenCredential = null;
  }
}
export default ACSCallClient;
