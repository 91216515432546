/* eslint-disable no-console */

const logger = {
  info: (...messages) => console.info(new Date(), ...messages),
  debug: (...messages) => console.debug(new Date(), ...messages),
  error: (...messages) => console.error(new Date(), ...messages),
};

export default logger;
/**
 * Now logger is only a wrapper around console.log()
 */
