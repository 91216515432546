import { useEffect, useState } from 'react';
import useTeams from 'FEATURES/teams/hooks/useTeams';
import { useTeamsAuthentication } from '../Authentication';

const useTab = () => {
  const { context } = useTeams();
  const [frameContext, setFrameContext] = useState('');
  const { isAuthorized, loading, authenticate: authenticateWithSSO } = useTeamsAuthentication();

  const authenticate = async () => {
    if (['content', 'sidePanel'].includes(context?.frameContext)) {
      await authenticateWithSSO();
      setFrameContext(context?.frameContext);
    }
  };

  useEffect(() => {
    if (context) {
      authenticate();
    }
  }, [context]);

  return { frameContext, loading, authenticate, isAuthorized };
};

export default useTab;
