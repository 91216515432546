import { LOGOUT_TRIGGER } from 'CONSTANTS/enum';
import { BrowserTypes } from 'react-device-detect';

export const API = {
  meetings: '/participant/meeting',
  createParticipant: '/participant/identity',
  fetchAcsToken: '/participant/identity/access-token',
  generateOtp: '/participant/otp/generate',
  validateOtp: '/participant/otp/validate',
};

export const CALL_ADAPTER_STATE = {
  CONNECTED: 'Connected',
  PARTICIPANT_JOINED: 'participantsJoined',
  PARTICIPANT_LEFT: 'participantsLeft',
  IS_PARTICIPANT_MUTED_CHANGED: 'isMutedChanged',
  CALL_ENDED: 'callEnded',
};

export const CALL_COMPOSITE_PAGE = {
  configuration: 'configuration',
};

export const CALL_TYPE = {
  FLOOR: 'floor',
  LANGUAGE: 'language',
};

export const COMPOSITE_MSG = {
  accessDenied: 'accessDenied',
};

export const PARTICIPANT_NAME = 'Non Licensed Participant';

export const DESKTOP_SUPPORTED_BROWSER = [
  BrowserTypes.Chrome,
  BrowserTypes.Edge,
  BrowserTypes.Safari,
];

export const ANDROID_SUPPORTED_BROWSER = [BrowserTypes.Chrome];

export const IOS_SUPPORTED_BROWSER = [BrowserTypes.MobileSafari];
/**
 * In CALL_END_REASON, the 4 digit codes are the callEndReason codes returned by SDK.
 * They are used as keys so that corresponding strings and events can be found easily.
 * 0 & 1 are custom codes. These are not related to callEndReason from SDK.
 */
export const CALL_END_REASON = {
  0: {
    eventText: 'User left call',
    displayText: 'DISCONNECTED_FROM_MEETING',
    triggerType: LOGOUT_TRIGGER.manual,
  },
  1: {
    eventText: 'User was last remaining participant',
    displayText: 'DISCONNECTED_FROM_MEETING',
    triggerType: LOGOUT_TRIGGER.auto,
  },
  4097: {
    eventText: 'Meeting ended',
    displayText: 'CALL_ENDED_FOR_ALL',
    triggerType: LOGOUT_TRIGGER.auto,
  },
  5000: {
    eventText: 'User was removed',
    displayText: 'REMOVED_FROM_MEETING',
    triggerType: LOGOUT_TRIGGER.auto,
  },
  5300: {
    eventText: 'User was removed',
    displayText: 'REMOVED_FROM_MEETING',
    triggerType: LOGOUT_TRIGGER.auto,
  },
  5854: {
    eventText: 'User was dismissed from lobby',
    displayText: 'DISMISSED_FROM_LOBBY',
    triggerType: LOGOUT_TRIGGER.auto,
  },
};
