import { useRef, useEffect } from 'react';
// libraries
import { useSelector } from 'react-redux';

// redux store
import useActions from 'HOOKS/useActions';

// utilities
import logger from 'SERVICES/logger';
import ACSCallClient from 'SERVICES/callClient/index';
import { useParams } from 'react-router-dom';
import { fetchACSAccessToken } from 'FEATURES/interpreter/api/api';

import {
  selectInterpreter,
  interpreterActions,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import { getAudioElements } from '../../utils/audioElementUtils';
import {
  secondaryLangMeetingSelector as selector,
  secondaryLangMeetingActions,
} from './secondaryLangMeetingSlice';

// constants
import {
  MEETING,
  SEC_LANGUAGE_MEETING_DISPLAY_NAME,
  SEC_LANGUAGE_RECORDING_MEETING_DISPLAY_NAME,
  TIMEOUT_FOR_CALL_ID,
} from '../../constants/index';

const useSecondaryLanguageMeeting = () => {
  const secLangCallClient = useSelector(selector.secLangCallClient);
  const selectedSpeaker = useSelector(selector.selectedSpeaker);
  const selectedMicrophone = useSelector(selector.selectedMicrophone);
  const interpreterDetails = useSelector(selectInterpreter.interpreterDetails);
  const secRecordingLangCallClient = useSelector(selector.secRecordingLangCallClient);
  const { meetingId } = useParams();

  // TODO: check redux state and ref issue for dispose() and hangup() issue;
  const acsCallObjectRef = useRef();
  const acsRecordingCallObjectRef = useRef();
  const { CALL_STATE, STATE_CHANGED, CALL_UPDATED } = ACSCallClient;

  const {
    setInterpreterId,
    setDeviceManager,
    setSelectedSpeaker,
    setSelectedMicrophone,
    setSecLangCallClientObject,
    setSecCallLoader,
    setSecLangRecordingCallClient,
    setSecondaryLanguageCallData,
    setSecondaryRecordingLanguageCallData,
  } = useActions(secondaryLangMeetingActions);

  const setSelectedSecondaryLanguageInfo = useActions(
    interpreterActions.setSelectedSecondaryLanguageInfo
  );

  useEffect(() => {
    acsCallObjectRef.current = secLangCallClient;
  }, [secLangCallClient]);

  useEffect(() => {
    acsRecordingCallObjectRef.current = secRecordingLangCallClient;
  }, [secRecordingLangCallClient]);

  const subscribeToDeviceManager = async (callClientObj) => {
    const deviceManager = await callClientObj.getACSDeviceManager();
    await deviceManager.askAudioDevicePermission();
    setDeviceManager(deviceManager);

    if (selectedSpeaker) await deviceManager.setSpeakerDevice(selectedSpeaker);
    else setSelectedSpeaker(deviceManager.getSelectedSpeaker());

    if (selectedMicrophone) await deviceManager.setMicrophoneDevice(selectedMicrophone);
    else setSelectedMicrophone(deviceManager.getSelectedMicrophone());

    deviceManager.onSelectedSpeakerChanged((speaker) => setSelectedSpeaker(speaker));
    deviceManager.onSelectedMicrophoneChanged((mic) => setSelectedMicrophone(mic));
  };

  const setupSecondaryLanguageMeeting = async () => {
    if (interpreterDetails) {
      const acsId = interpreterDetails.secondaryACSId;
      logger.info('Setup Secondary Language Meeting');
      try {
        const token = await fetchACSAccessToken(acsId);
        setInterpreterId(acsId);
        const config = {
          acsToken: token,
          displayName: SEC_LANGUAGE_MEETING_DISPLAY_NAME,
        };
        const callClientObj = new ACSCallClient();
        await callClientObj.setupCall(config);
        setSecLangCallClientObject(callClientObj);
        await subscribeToDeviceManager(callClientObj);

        callClientObj.subscribe(CALL_UPDATED, (addedCall) => {
          try {
            addedCall?.on(STATE_CHANGED, () => {
              if (addedCall.state === CALL_STATE.connected) {
                setSecCallLoader(false);
              }
            });
          } catch (error) {
            logger.error('Error In Secondary language Call Update', error);
            throw error;
          }
        });
      } catch (error) {
        logger.error('Error while secondary language call setup', error);
        throw error;
      }
    }
  };

  const hangUpSecondaryLanguageMeeting = async () => {
    try {
      logger.info('hangup secondary language meeting');
      if (secLangCallClient && secLangCallClient.getCallState() !== CALL_STATE.disconnected) {
        await secLangCallClient?.hangupCall();
        setSelectedSecondaryLanguageInfo(null);
      }
    } catch (error) {
      logger.error('Error while secondary language call hangup', error);
      throw error;
    }
  };

  const disposeSecondaryLanguageMeeting = async () => {
    try {
      logger.debug('Disposing secondary language Meeting call Agent', acsCallObjectRef.current);
      await acsCallObjectRef?.current?.disposeCall();
    } catch (error) {
      logger.error('Error while secondary language call dispose', error);
      throw error;
    }
  };

  const joinSecondaryLanguageMeeting = async (languageInfo) => {
    try {
      // lang meeting
      logger.info('Joining secondary Language Meeting');

      if (secLangCallClient?.getCallState() !== CALL_STATE.disconnected) {
        await hangUpSecondaryLanguageMeeting();
      }

      if (acsCallObjectRef.current) {
        acsCallObjectRef.current.joinMeeting({ groupId: languageInfo.id });
        const secondaryLanguageMeetingAudioTag = await getAudioElements(
          MEETING.secondaryLanguageMeeting
        );
        logger.debug(
          [secondaryLanguageMeetingAudioTag],
          'Secondary Recording language Meeting Audio Tag'
        );
        await acsCallObjectRef.current.muteIncomingCall();
        // Adding timeout as callId will be reflected after few seconds
        setTimeout(() => {
          const updatedData = {
            meetingId,
            language: languageInfo.language,
            callId: acsCallObjectRef.current.call?.id,
          };
          setSecondaryLanguageCallData(updatedData);
        }, TIMEOUT_FOR_CALL_ID);
      }
    } catch (error) {
      logger.error('Error while joining secondary language call', error);
      throw error;
    }
  };

  const setupSecondaryRecordingLanguageMeeting = async () => {
    if (interpreterDetails) {
      const acsId = interpreterDetails.secondaryRecordingACSId;
      logger.info('Setup Secondary Recording Language Meeting');
      try {
        const token = await fetchACSAccessToken(acsId);
        const config = {
          acsToken: token,
          displayName: SEC_LANGUAGE_RECORDING_MEETING_DISPLAY_NAME,
        };
        const callClientObj = new ACSCallClient();
        await callClientObj.setupCall(config);
        setSecLangRecordingCallClient(callClientObj);
        await subscribeToDeviceManager(callClientObj);

        callClientObj.subscribe(CALL_UPDATED, (addedCall) => {
          try {
            addedCall?.on(STATE_CHANGED, () => {
              if (addedCall.state === CALL_STATE.connected) {
                logger.debug('Connected to secondary recording language meeting');
              }
            });
          } catch (error) {
            logger.error('Error In Secondary Recording language Call Update', error);
            throw error;
          }
        });
      } catch (error) {
        logger.error('Error while secondary recording language call setup', error);
        throw error;
      }
    }
  };

  const hangUpSecondaryRecordingLanguageMeeting = async () => {
    try {
      logger.info('hangup secondary recording language meeting');
      if (
        secRecordingLangCallClient &&
        secRecordingLangCallClient.getCallState() !== CALL_STATE.disconnected
      ) {
        await secRecordingLangCallClient?.hangupCall();
      }
    } catch (error) {
      logger.error('Error while secondary recording language call hangup', error);
      throw error;
    }
  };

  const disposeSecondaryRecordingLanguageMeeting = async () => {
    try {
      logger.debug(
        'Disposing secondary recording language Meeting call Agent',
        acsRecordingCallObjectRef.current
      );
      await acsRecordingCallObjectRef?.current?.disposeCall();
    } catch (error) {
      logger.error('Error while secondary recording language call dispose', error);
      throw error;
    }
  };

  const joinSecondaryRecordingLanguageMeeting = async (languageInfo) => {
    try {
      // recording lang meeting
      logger.info('Joining secondary recording Language Meeting');

      if (secLangCallClient?.getCallState() !== CALL_STATE.disconnected) {
        await hangUpSecondaryRecordingLanguageMeeting();
      }

      if (acsRecordingCallObjectRef.current) {
        acsRecordingCallObjectRef.current.joinMeeting({ groupId: languageInfo.recordingMeetingId });
        const secondaryRecordingLanguageMeetingAudioTag = await getAudioElements(
          MEETING.secondaryRecordingLanguageMeeting
        );
        logger.debug(
          [secondaryRecordingLanguageMeetingAudioTag],
          'Secondary language Meeting Audio Tag'
        );
        await acsRecordingCallObjectRef.current?.muteIncomingCall();
        // Adding timeout as callId will be reflected after few seconds
        setTimeout(() => {
          const updatedData = {
            meetingId,
            language: languageInfo.language,
            recordingCallId: acsRecordingCallObjectRef.current.call?.id,
          };
          setSecondaryRecordingLanguageCallData(updatedData);
        }, TIMEOUT_FOR_CALL_ID);
      }
    } catch (error) {
      logger.error('Error while joining secondary recording language call', error);
      throw error;
    }
  };

  return {
    setupSecondaryLanguageMeeting,
    joinSecondaryLanguageMeeting,
    hangUpSecondaryLanguageMeeting,
    disposeSecondaryLanguageMeeting,
    secLangCallClient,
    setupSecondaryRecordingLanguageMeeting,
    joinSecondaryRecordingLanguageMeeting,
    hangUpSecondaryRecordingLanguageMeeting,
    disposeSecondaryRecordingLanguageMeeting,
    secRecordingLangCallClient,
  };
};

export default useSecondaryLanguageMeeting;
