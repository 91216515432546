import { API_CONTEXT } from 'CONSTANTS/apiConstants';
import logger from '../services/logger';
import { SOCKET_RESPONSE, SOCKET_REQUEST, SOCKET_ERROR } from './socketConstant';

class SocketClient {
  connect(userData) {
    logger.info('SocketClient connect');
    /* disabled eslint warning as io is a global object since client library is included in index.html */
    // eslint-disable-next-line no-undef
    this.socket = io(process.env.REACT_APP_SOCKET_URL, {
      auth: {
        token: `Bearer ${userData.authToken}`,
      },
      // initialise the query object for later
      query: {},
    });

    this.socket.io.on(SOCKET_RESPONSE.reconnecting, () => {
      // this will help the server recognise the new socket as a reconnection
      this.socket.io.opts.query.oldSocketId = this.socketIdRef;
    });

    this.socket.on(SOCKET_RESPONSE.connect, () => {
      logger.info('Socket connected!');
      // save an additional reference since the id will change during auto reconnections
      this.socketIdRef = this.socket.id;
      const { userId, meetingId, name } = userData;
      this.emit(SOCKET_REQUEST.join, {
        userId,
        meetingId,
        name,
        context: API_CONTEXT.websocketInterpreter,
      });
    });

    this.socket.on(SOCKET_RESPONSE.joinAck, (data) => {
      logger.debug(SOCKET_RESPONSE.joinAck, data);
    });

    this.socket.on(SOCKET_RESPONSE.userJoinedMeeting, (data) => {
      logger.debug(SOCKET_RESPONSE.userJoinedMeeting, data);
    });

    this.socket.on(SOCKET_RESPONSE.updateBoothAck, (data) => {
      logger.debug(SOCKET_RESPONSE.updateBoothAck, data);
    });

    this.socket.on(SOCKET_RESPONSE.userLeftBooth, (data) => {
      logger.debug(SOCKET_RESPONSE.userLeftBooth, data);
    });

    this.socket.on(SOCKET_RESPONSE.userJoinedBooth, (data) => {
      logger.debug(SOCKET_RESPONSE.userJoinedBooth, data);
    });

    this.socket.on(SOCKET_RESPONSE.updateActiveLanguageAck, (data) => {
      logger.debug(SOCKET_RESPONSE.updateActiveLanguageAck, data);
    });

    this.socket.on(SOCKET_RESPONSE.activeLanguageUpdated, (data) => {
      logger.debug(SOCKET_RESPONSE.activeLanguageUpdated, data);
    });

    this.socket.on(SOCKET_RESPONSE.handOverAck, (data) => {
      logger.debug(SOCKET_RESPONSE.handOverAck, data);
    });

    this.socket.on(SOCKET_RESPONSE.userHandedOver, (data) => {
      logger.debug(SOCKET_RESPONSE.userHandedOver, data);
    });

    this.socket.on(SOCKET_RESPONSE.takeOverAck, (data) => {
      logger.debug(SOCKET_RESPONSE.takeOverAck, data);
    });

    this.socket.on(SOCKET_RESPONSE.unmuteAck, (data) => {
      logger.debug(SOCKET_RESPONSE.unmuteAck, data);
    });

    this.socket.on(SOCKET_RESPONSE.userTookOver, (data) => {
      logger.debug(SOCKET_RESPONSE.userTookOver, data);
    });

    this.socket.on(SOCKET_RESPONSE.userUnmuted, (data) => {
      logger.debug(SOCKET_RESPONSE.userUnmuted, data);
    });

    this.socket.on(SOCKET_RESPONSE.userMuted, (data) => {
      logger.debug(SOCKET_RESPONSE.userMuted, data);
    });

    this.socket.on(SOCKET_RESPONSE.muteAck, (data) => {
      logger.debug(SOCKET_RESPONSE.muteAck, data);
    });

    this.socket.on(SOCKET_RESPONSE.userLeftMeeting, (data) => {
      logger.debug(SOCKET_RESPONSE.userLeftMeeting, data);
    });

    this.socket.on(SOCKET_RESPONSE.disconnect, (reason) => {
      logger.debug('Socket disconnected reason', reason);
      if (
        reason === SOCKET_ERROR.explicitDisconnectByServer ||
        reason === SOCKET_ERROR.explicitDisconnectByClient
      ) {
        // socket was disconnected on purpose
        this.socketIdRef = null;
      }
    });

    this.socket.on(SOCKET_RESPONSE.connectError, (error) => {
      logger.debug(SOCKET_RESPONSE.connectError, error?.data?.message);
    });
  }

  subscribe(event, callback) {
    this.socket?.on(event, callback);
  }

  unsubscribe(event, callback) {
    this.socket?.off(event, callback);
  }

  emit(event, data) {
    logger.debug('SocketClient emit', event);
    this.socket?.emit(event, data);
  }

  disconnect() {
    logger.debug('SocketClient dis-connect');
    // Not passing parameter means unsubscribe all events
    this.socket?.off();
    this.socket?.disconnect();
    this.socket = undefined;
  }
}

export default new SocketClient();
