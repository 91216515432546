import { useState } from 'react';

import logger from 'SERVICES/logger';
import { ERROR } from 'CONSTANTS/apiConstants';

import useActions from 'HOOKS/useActions';
import { useTranslation } from 'SERVICES/i18n';
import { USER_ROLE } from 'FEATURES/teams/constants';
import { getTeamsContext } from 'FEATURES/teams/utils/teamsUtils';
import { fetchMeetingDetailsAPI, submitInterpretationData } from 'FEATURES/teams/api';
import { teamsMeetingActions } from 'FEATURES/teams/slices/teamsMeetingSlice';
import { showGraphConsent } from '../Authentication';

const useMeetingDetails = () => {
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [consentProvided, setConsentProvided] = useState(false);
  const [graphScope, setGraphScope] = useState();
  const { t } = useTranslation('teams', { keyPrefix: 'PREMEETING' });
  const { setCurrentUserRole, setMeetingDetails, updateInterpretingLanguages } =
    useActions(teamsMeetingActions);

  const setLoadingWithMessage = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const setUserRoleInStore = async (organizer, userObjectId) => {
    if (organizer?.id === userObjectId) {
      logger.info('Current user is an Organizer');
      setCurrentUserRole(USER_ROLE.ORGANIZER);
    } else {
      logger.info('Current user is not an Organizer');
      setCurrentUserRole(USER_ROLE.PARTICIPANT);
    }
  };

  const refetchMeetingByProvidingPermission = async (scope) => {
    logger.info('refetchMeetingByProvidingPermission');
    let meetingDetails = null;
    const { userObjectId, meetingId } = await getTeamsContext();
    try {
      const { code } = await showGraphConsent(scope || graphScope);
      logger.debug('Authentication code: ', code);
      meetingDetails = await fetchMeetingDetailsAPI(meetingId, code);
      setMeetingDetails(meetingDetails);
      await setUserRoleInStore(meetingDetails?.organizer, userObjectId);
      setConsentProvided(true);
    } catch (error) {
      if (error.code === ERROR.MEETING_NOT_FOUND) {
        // Current user is participant
        setConsentProvided(true);
      } else {
        setConsentProvided(false);
      }
    }
    return meetingDetails;
  };

  const fetchMeetingDetails = async () => {
    const { userObjectId, meetingId } = await getTeamsContext();
    setLoadingWithMessage(t('LOAD_MEETING_INFO_LOADER_MSG'));
    let meetingDetails = null;
    try {
      meetingDetails = await fetchMeetingDetailsAPI(meetingId);
      setMeetingDetails(meetingDetails);
      await setUserRoleInStore(meetingDetails?.organizer, userObjectId);
      setConsentProvided(true);
    } catch (error) {
      logger.error(error, 'Error while fetching meeting Details');
      if (error.code === ERROR.INVALID_MEETING_REQUEST) {
        logger.debug(`setGraphScope ${error.data.scope}`);
        setGraphScope(error.data.scope);
        meetingDetails = await refetchMeetingByProvidingPermission(error.data.scope);
      } else if (error.code === ERROR.MEETING_NOT_FOUND) {
        // Current user is participant. so we don't require graph consent
        setConsentProvided(true);
      }
    }
    setLoading(false);
    return meetingDetails;
  };

  const submitData = async ({
    languages,
    updatedLanguages,
    universalAccessEnabled,
    isPartialUpdate,
  }) => {
    setLoadingWithMessage(t('SUBMIT_REQ_LOADER_MSG'));
    try {
      const { meetingId } = await getTeamsContext();
      await submitInterpretationData({
        meetingId,
        languages: isPartialUpdate ? updatedLanguages : languages,
        universalAccessEnabled,
        isPartialUpdate,
      });
      updateInterpretingLanguages(languages);
      logger.info('Your request has been successfully submitted.');
      setLoading(false);
    } catch (error) {
      logger.error('Error While submitting languages', error);
      if (error.code === ERROR.INVALID_MEETING_REQUEST) {
        logger.debug(`setGraphScope ${error.data.scope}`);
        setGraphScope(error.data.scope);
        setConsentProvided(false);
      }
      setLoading(false);
      throw error;
    }
  };

  return {
    isAuthorized: consentProvided,
    loader: {
      isLoading: loading,
      message: loadingMessage,
    },
    fetchMeetingDetails,
    submitData,
    refetchMeetingByProvidingPermission,
  };
};

export default useMeetingDetails;
