import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSpeaker: undefined,
  selectedMicrophone: undefined,
};

const testCallSlice = createSlice({
  name: 'testCall',
  initialState,
  reducers: {
    setSelectedSpeaker(state, action) {
      state.selectedSpeaker = action.payload;
    },
    removeSelectedSpeaker(state) {
      state.selectedSpeaker = undefined;
    },
    setSelectedMicrophone(state, action) {
      state.selectedMicrophone = action.payload;
    },
    removeSelectedMicrophone(state) {
      state.selectedMicrophone = undefined;
    },
  },
});

export default testCallSlice.reducer;

export const testCallActions = { ...testCallSlice.actions };

export const testCallSelector = {
  selectedSpeaker: (store) => store.testCall.selectedSpeaker,
  selectedMicrophone: (store) => store.testCall.selectedMicrophone,
};
