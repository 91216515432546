import PropTypes from 'prop-types';
import { useTranslation } from 'SERVICES/i18n';
import { Flex, Text } from '@fluentui/react-northstar';
import AkouoLogo from 'COMPONENTS/common/AkouoLogo';

const TabFooter = ({ className }) => {
  const { t } = useTranslation('teams', { keyPrefix: 'COMMON' });
  return (
    <Flex hAlign="end" vAlign="center" className={className}>
      <Text content={t('POWERED_BY')} />
      <span className="pl-2">
        <AkouoLogo height="60px" />
      </span>
    </Flex>
  );
};

TabFooter.propTypes = {
  className: PropTypes.string,
};

TabFooter.defaultProps = {
  className: null,
};
export default TabFooter;
