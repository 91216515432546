import { Text, Flex } from '@fluentui/react-northstar';
import AkouoSupport from 'COMPONENTS/common/AkouoSupport';
import FullScreenLoader from 'COMPONENTS/common/FullScreenLoader';
import MicrosoftSignInButton from 'COMPONENTS/common/MicrosoftSignInButton';
import { useSelector } from 'react-redux';
import useSnackbar from 'HOOKS/useSnackbar';
import { HTML_ELEMENT_POSITION, SNACKBAR_TYPE } from 'CONSTANTS/enum';

import './PreMeeting.scss';
import { teamsMeetingSelector as selector } from 'FEATURES/teams/slices/teamsMeetingSlice';
import { COPY_ELEMENT } from 'FEATURES/teams/constants';
import usePreMeeting from './usePreMeeting';
import PreMeetingForm from './PreMeetingForm/PreMeetingForm';
import PreMeetingView from './PreMeetingView/PreMeetingView';

const PreMeeting = () => {
  const { editMode, loader, onSubmit, setEditMode, isAuthorized, fetchMeeting, t } =
    usePreMeeting();
  const teamsMeetingDetail = useSelector(selector.teamsMeetingDetail);
  const showSnackbar = useSnackbar();

  if (loader.isLoading) return <FullScreenLoader label={loader.message} />;

  const customLink = `${process.env.REACT_APP_URL}/participant/join/${teamsMeetingDetail.meetingId}`;

  const createCopyDescription = () => {
    const formattedDescription = `
    <div id="${COPY_ELEMENT.CLIPBOARD_COPY_TEXT_SELECTOR_ID}">
      <qt text=black>
      <hr width="55%" align="left"/>
        <p>
          <font size=5>
            ${t('AUA_EMAIL_TITLE')}
          </font>
        </p>
        </br>
        <b>
          <p>
            ${t('AUA_EMAIL_DESCRIPTION')}
          </p>
        </b>
        <a href="${customLink}">
          <u>
            ${t('AUA_EMAIL_LINK_DISPLAY')}
          </u>
        </a>
        </br>
        <hr width="55%" align="left"/>
      </qt>
    </div>
    `;
    document
      .getElementsByClassName(COPY_ELEMENT.ORGANIZER_EDITABLE_PRE_FORM_CONTAINER)[0]
      ?.insertAdjacentHTML(HTML_ELEMENT_POSITION.beforeEnd, formattedDescription);
    return document.getElementById(COPY_ELEMENT.CLIPBOARD_COPY_TEXT_SELECTOR_ID);
  };

  const copyToClip = () => {
    const node = createCopyDescription();
    try {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand(COPY_ELEMENT.EXEC_COMMAND_COPY);
      showSnackbar({
        message: t('AUA_LINK_COPY_SUCCESS'),
        type: SNACKBAR_TYPE.SUCCESS,
      });
    } catch {
      showSnackbar({
        message: t('AUA_LINK_COPY_FAILURE'),
        type: SNACKBAR_TYPE.DANGER,
      });
    } finally {
      node.remove();
    }
  };

  if (!isAuthorized) {
    return (
      <Flex fill hAlign="center" vAlign="center" column>
        <Text content={t('AUTH_ERROR_MSG')} align="center" />
        <AkouoSupport size="small" align="center" className="pt-1" />
        <MicrosoftSignInButton onClick={() => fetchMeeting({ usePermissionScope: true })} />
      </Flex>
    );
  }

  return (
    <div className="pre-meeting-container p-4">
      {editMode ? (
        <PreMeetingForm
          handleSubmit={onSubmit}
          handleCancelButton={() => setEditMode(false)}
          copyToClip={copyToClip}
        />
      ) : (
        <PreMeetingView handleEditClick={() => setEditMode(true)} copyToClip={copyToClip} />
      )}
    </div>
  );
};

export default PreMeeting;
