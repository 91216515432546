import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_CONTEXT } from 'CONSTANTS/apiConstants';

import serverAPI from 'SERVICES/serverAPI';
import { API, FLOOR_INCOMING_AUDIO, MIC_STATE, OUTGOING_CALL_TYPE } from '../constants/index';

const initialState = {
  interpreterName: '',
  interpreterB2CId: '',
  languageMeetings: [],
  floorMeeting: {
    joinUrl: null,
    id: null,
  },
  relayMeeting: {
    id: null,
  },
  selectedPrimaryLanguageInfo: null,
  selectedSecondaryLanguageInfo: null,
  selectedRelayInfo: null,
  activeIncomingAudio: FLOOR_INCOMING_AUDIO,
  activeLanguageStatus: OUTGOING_CALL_TYPE.none,
  errorStatus: false,
  // Added this property so that we can get activelanguage for unmute/takeober
  activeLanguageCode: null,
  interpreterDetails: null,
  // ACS ID & Token for chat
  chatInfo: null,
  // Recording enabled
  isRecordingEnabled: false,
  // For booth audio we will required this to mute/unmute audio
  micStatus: MIC_STATE.disable,
};

const fetchMeetingDetailActions = createAsyncThunk(
  'interpreter/fetchMeetingDetail',
  async (params, thunkAPI) => {
    const url = `${API.meetings}/${params.meetingId}/${API_CONTEXT.interpreter}`;
    try {
      const response = await serverAPI.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const interpreterSlice = createSlice({
  name: 'interpreter',
  initialState,
  reducers: {
    setInterpreterDetails(state, action) {
      state.interpreterDetails = action.payload;
    },
    setSelectedPrimaryLanguageInfo(state, action) {
      state.selectedPrimaryLanguageInfo = action.payload;
    },
    setSelectedSecondaryLanguageInfo(state, action) {
      state.selectedSecondaryLanguageInfo = action.payload;
    },
    setSelectedRelayInfo(state, action) {
      state.selectedRelayInfo = action.payload;
    },
    setInterpreterName(state, action) {
      state.interpreterName = action.payload;
    },
    setInterpreterB2CId(state, action) {
      state.interpreterB2CId = action.payload;
    },
    setActiveIncomingAudio(state, action) {
      state.activeIncomingAudio = action.payload;
    },
    setErrorStatus(state, action) {
      state.errorStatus = action.payload;
    },
    setActiveLanguageStatus(state, action) {
      state.activeLanguageStatus = action.payload;
    },
    setActiveLanguageCode(state, action) {
      state.activeLanguageCode = action.payload;
    },
    setChatInfo(state, action) {
      state.chatInfo = action.payload;
    },
    setMicState(state, action) {
      state.micStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMeetingDetailActions.fulfilled, (interpreter, action) => {
      const { interpreterMeetings, interpreterId, participantId } = action.payload.interpretation;
      interpreter.languageMeetings = interpreterMeetings.filter((i) => i.language !== 'floor');

      interpreter.relayMeeting = {
        id: participantId,
      };
      interpreter.floorMeeting = {
        joinUrl: interpreterMeetings.filter((i) => i.language === 'floor')[0].id,
        id: interpreterId,
      };
      interpreter.isRecordingEnabled = action.payload.isRecordingEnabled;
    });
  },
});

export default interpreterSlice.reducer;

export const interpreterActions = { ...interpreterSlice.actions, fetchMeetingDetailActions };

// selectors
export const selectInterpreter = {
  languageMeetings: (state) => state.interpreter.languageMeetings,
  floorMeeting: (state) => state.interpreter.floorMeeting,
  relayMeeting: (state) => state.interpreter.relayMeeting,
  interpreterName: (state) => state.interpreter.interpreterName,
  interpreterB2CId: (state) => state.interpreter.interpreterB2CId,
  selectedPrimaryLanguageInfo: (state) => state.interpreter.selectedPrimaryLanguageInfo,
  selectedSecondaryLanguageInfo: (state) => state.interpreter.selectedSecondaryLanguageInfo,
  selectedRelayInfo: (state) => state.interpreter.selectedRelayInfo,
  activeIncomingAudio: (state) => state.interpreter.activeIncomingAudio,
  activeLanguageStatus: (state) => state.interpreter.activeLanguageStatus,
  errorStatus: (state) => state.interpreter.errorStatus,
  activeLanguageCode: (state) => state.interpreter.activeLanguageCode,
  interpreterDetails: (state) => state.interpreter.interpreterDetails,
  chatInfo: (state) => state.interpreter.chatInfo,
  isRecordingEnabled: (state) => state.interpreter.isRecordingEnabled,
  micStatus: (state) => state.interpreter.micStatus,
};
