import { useState } from 'react';
import { useSelector } from 'react-redux';
import useActions from 'HOOKS/useActions';
import { useTranslation } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';
import {
  interpreterActions,
  selectInterpreter,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import {
  showToast,
  TOAST_DURATION,
  TOAST_TYPE,
} from 'FEATURES/interpreter/components/CustomToastContainer';
import SplitButton from 'FEATURES/interpreter/components/SplitButton';
import {
  INCOMING_CALL_TYPE,
  RELAY_INCOMING_AUDIO,
  SPLITBUTTON_LANG_NONE,
  OUTGOING_CALL_TYPE,
  FLOOR_INCOMING_AUDIO,
} from 'FEATURES/interpreter/constants/index';
import useRelayMeeting from '../hooks/useRelayMeeting';
import { relayMeetingActions, RelayMeetingSelector as selector } from '../relayMeetingSlice';
import useIncomingAudioController from '../../Dashboard/useIncomingAudioController';

const RelayMeetingButton = () => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });
  const { muteIncomingAudio, unmuteIncomingAudio } = useIncomingAudioController();
  const { language, relayCallClient, joinRelayMeeting, hangUpRelayMeeting } = useRelayMeeting();

  const selectedPrimaryLanguageInfo = useSelector(selectInterpreter.selectedPrimaryLanguageInfo);
  const selectedSecondaryLanguageInfo = useSelector(
    selectInterpreter.selectedSecondaryLanguageInfo
  );
  const selectedRelayInfo = useSelector(selectInterpreter.selectedRelayInfo);
  const activeLanguageStatus = useSelector(selectInterpreter.activeLanguageStatus);

  const { setActiveIncomingAudio } = useActions(interpreterActions);
  const { setCallLoader } = useActions(relayMeetingActions);
  const activeIncomingAudio = useSelector(selectInterpreter.activeIncomingAudio);
  const isLoading = useSelector(selector.isLoading);

  const items = [...language.items.map((item) => item.language), SPLITBUTTON_LANG_NONE];
  const [activeIndex, setActiveIndex] = useState(items.length - 1);
  const activeItem = items[activeIndex];

  const showNullLangError = () => {
    showToast({
      data: {
        content: t('NULL_RELAY_LANG_ERROR'),
        autoClose: TOAST_DURATION.long,
        type: TOAST_TYPE.error,
      },
    });
  };

  const showSimilarLangError = () => {
    showToast({
      data: {
        content: t('SIMILAR_OUTGOING_LANG_ERROR'),
        autoClose: TOAST_DURATION.long,
        type: TOAST_TYPE.error,
      },
    });
  };

  return (
    <SplitButton
      button={{
        content: activeItem,
      }}
      isLoading={isLoading}
      size="larger"
      primary={
        !isLoading &&
        activeIncomingAudio === RELAY_INCOMING_AUDIO &&
        activeIndex !== items.length - 1 // for not showing active/primary status if none('--') is selected
      }
      menu={{
        activeIndex,
        items: items.filter((item) => item !== activeItem),
      }}
      onMenuItemClick={async (e, obj) => {
        logger.debug('Clicked RelayMeetingButton');
        const index = items.findIndex((item) => item === obj.content);
        if (
          (activeLanguageStatus === OUTGOING_CALL_TYPE.primary &&
            selectedPrimaryLanguageInfo === language.items[index]) ||
          (activeLanguageStatus === OUTGOING_CALL_TYPE.secondary &&
            selectedSecondaryLanguageInfo === language.items[index])
        ) {
          // Condition check for: relay is not same as outgoing lang which is currently active
          // if condition is satisfy: Show error notification.
          logger.error('Selected language is same as your output language');
          showSimilarLangError();
        } else if (language.items[index]) {
          // condition to check selected item is language (not '--')
          // Do : 1.hangup old relay lang call, 2.connect to new selected relay lang call, 3.make Relay active, 4.mute floor call
          setCallLoader(true);
          setActiveIndex(index);
          await joinRelayMeeting(language.items[index]);
          muteIncomingAudio(INCOMING_CALL_TYPE.floor);
          setActiveIncomingAudio(RELAY_INCOMING_AUDIO);
        } else {
          // if none('--') is selected
          // Do: 1.hangup old relay lang call, 2.set Floor active, 3.Unmute floor
          setCallLoader(true);
          setActiveIndex(index);
          await hangUpRelayMeeting();
          setActiveIncomingAudio(FLOOR_INCOMING_AUDIO);
          unmuteIncomingAudio(INCOMING_CALL_TYPE.floor);
          setCallLoader(false);
        }
      }}
      onMainButtonClick={() => {
        if (
          (activeLanguageStatus === OUTGOING_CALL_TYPE.primary &&
            selectedPrimaryLanguageInfo === selectedRelayInfo) ||
          (activeLanguageStatus === OUTGOING_CALL_TYPE.secondary &&
            selectedSecondaryLanguageInfo === selectedRelayInfo)
        ) {
          // Condition check for: relay is not same as outgoing lang which is currently active
          // Do: show error notification
          logger.error('Selected language is same as your output language');
          showSimilarLangError();
        } else if (relayCallClient.isCallAvailable()) {
          // if relay lang is not same as active outgoing lang and relay ongoing call exist
          // Do. 1.Make relay active, 2.mute floor, 3.unmute relay.
          muteIncomingAudio(INCOMING_CALL_TYPE.floor);
          unmuteIncomingAudio(INCOMING_CALL_TYPE.relay);
          setActiveIncomingAudio(RELAY_INCOMING_AUDIO);
        } else if (activeItem === SPLITBUTTON_LANG_NONE) {
          showNullLangError();
        }
      }}
    />
  );
};

export default RelayMeetingButton;
