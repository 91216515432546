import * as microsoftTeams from '@microsoft/teams-js';

export const getTeamsContext = () => {
  return new Promise((resolve, reject) => {
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext((context) => {
        if (context) resolve(context);
        else reject(new Error('Unable to get teams context'));
      });
    });
  });
};

export const checkInTeams = () => {
  const microsoftTeamsLib = microsoftTeams || window.microsoftTeams;

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  if (
    (window.parent === window.self && window.nativeInterface) ||
    window.navigator.userAgent.includes('Teams/') ||
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame'
  ) {
    return true;
  }
  return false;
};
