import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ROUTES from 'CONSTANTS/routes';
// import { participantRoutes } from 'FEATURES/participant/routes';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import ParticipantHome from 'FEATURES/participant/features/ParticipantHome';
import { FluentThemeProvider, darkTheme } from '@azure/communication-react';

const ParticipantRouter = () => {
  initializeIcons(undefined, { disableWarnings: true });
  return (
    <FluentThemeProvider fluentTheme={darkTheme}>
      <BrowserRouter>
        <Routes>
          <Route
            path={`${ROUTES.PARTICIPANT}/join/:meetingId`}
            element={<ParticipantHome />}
            key={`Route-${ROUTES.PARTICIPANT}/join/:meetingId`}
          />
        </Routes>
      </BrowserRouter>
    </FluentThemeProvider>
  );
};

export default ParticipantRouter;

// participantRoutes is obsolete but not removed till v1.6.0 is released
