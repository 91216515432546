import { useTranslation } from 'SERVICES/i18n';

// TODO: optimize this function
export const getLanguageNames = (languageCodes) => {
  const { t } = useTranslation('translation', { keyPrefix: 'COMMON' });
  return t('LANGUAGES', { returnObjects: true }).filter((item) =>
    languageCodes.includes(item.value)
  );
};

export const getLanguageList = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'COMMON' });
  return t('LANGUAGES', { returnObjects: true });
};

export const selectLanguageForCaptions = (captionEvents, selectedLanguage) => {
  const DEFAULT_CAPTIONING_LANGUAGE = 'en';
  return (
    // caption language = interpretation language
    captionEvents.find((language) => {
      return language.sourcelanguageid === selectedLanguage;
    }) ||
    // OR caption language = English default, only if available in the list of captioning languages
    captionEvents.find((language) => {
      return language.sourcelanguageid === DEFAULT_CAPTIONING_LANGUAGE;
    }) ||
    // OR caption language = first language available in the list of captioning languages
    captionEvents[0]
  );
};
