import * as microsoftTeams from '@microsoft/teams-js';
import ROUTES from 'CONSTANTS/routes';
import { ORIGIN } from 'FEATURES/teams/constants';

export const getIdToken = async (silent = true) => {
  return new Promise((resolve, reject) => {
    microsoftTeams.authentication.getAuthToken({
      successCallback: (result) => resolve(result),
      failureCallback: (error) => reject(error),
      silent,
    });
  });
};

export const showGraphConsent = async (scope) => {
  return new Promise((resolve, reject) => {
    microsoftTeams.authentication.authenticate({
      url: `${ORIGIN}${ROUTES.CONSENT_POPUP}?scope=${scope}`,
      width: 600,
      height: 535,
      successCallback: (result) => resolve(result),
      failureCallback: (reason) => reject(reason),
    });
  });
};
