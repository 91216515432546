import React from 'react';
import { useTranslation } from 'SERVICES/i18n';
import { Button } from '@fluentui/react-northstar';
import ActiveMic from 'RESOURCES/images/mic-active-white.svg';
import InActiveMic from 'RESOURCES/images/mic-mute-white.svg';
import { ENTER_KEY_CODE } from 'CONSTANTS/appConstants';
import 'RESOURCES/styles/globalStyles.scss';
import './Buttons.scss';
import {
  showToast,
  TOAST_DURATION,
  TOAST_TYPE,
} from 'FEATURES/interpreter/components/CustomToastContainer';
import { MIC_STATE } from 'FEATURES/interpreter/constants';

const mapStateToIcon = {
  unmute: { icon: ActiveMic, bgcolor: 'redButton' },
  mute: { icon: InActiveMic, bgcolor: 'greenButton' },
  disable: { icon: InActiveMic, bgcolor: 'greyButton' },
};

const MicButton = ({ state = 'disable', onClick }) => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });

  return (
    <Button
      onClick={() => {
        if (state === MIC_STATE.disable) {
          showToast({
            data: {
              content: t('DISABLED_MIC_BUTTON_CLICKED'),
              autoClose: TOAST_DURATION.long,
              type: TOAST_TYPE.error,
            },
          });
          return;
        }
        onClick();
      }}
      className={`${mapStateToIcon[state].bgcolor} click-effect icon-button-medium imageButton`}
      onKeyDown={(e) => {
        if (state !== 'disable' && e.keyCode === ENTER_KEY_CODE) onClick();
      }}
      role="button"
      tabIndex={0}
      icon={<img src={mapStateToIcon[state].icon} alt={t('OUTGOING_AUDIO_CONTROL')} />}
      title={t('OUTGOING_AUDIO_CONTROL')}
    />
  );
};

export default MicButton;
