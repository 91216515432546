import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'SERVICES/i18n';
import { selectInterpreter } from 'FEATURES/interpreter/slices/interpreterSlice';
import useDeviceManager from '../hooks/useDeviceManager';

const useConfigurationForm = () => {
  const displayNameInputId = 'Interpreter-Display-Name';
  const [errorMessage, setErrorMessage] = useState(false);
  const { t } = useTranslation('interpreter', { keyPrefix: 'LANDING_PAGE' });

  const interpreterName = useSelector(selectInterpreter.interpreterName);

  const { speaker, microphone } = useDeviceManager();

  const isFormValid = () => {
    const displayName = document.getElementById(displayNameInputId).value;
    if (!displayName) return false;
    if (!speaker.selectedItem) {
      setErrorMessage(t('NO_SPEAKER_SELECTED'));
      return false;
    }
    if (!microphone.selectedItem) {
      setErrorMessage(t('NO_MICROPHONE_SELECTED'));
      return false;
    }

    return true;
  };

  return {
    t,
    isFormValid,
    errorMessage,
    interpreterName,
    speaker,
    microphone,
    displayNameInputId,
  };
};

export default useConfigurationForm;
