import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ROUTES from 'CONSTANTS/routes';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'SRC/features/interpreter/features/Authentication/authConfig';
import { MsalProvider } from '@azure/msal-react';
import { setMsalInstance } from 'FEATURES/interpreter/features/Authentication/authentication';
import LandingPage from 'FEATURES/interpreter/features/LandingPage';

// import { interpreterRoutes } from 'FEATURES/interpreter';

const InterpreterRouter = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  setMsalInstance(msalInstance);

  // const routes = [...interpreterRoutes];
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route
            path={`${ROUTES.INTERPRETER}/:meetingId`}
            element={<LandingPage />}
            key={`Route-${ROUTES.INTERPRETER}/:meetingId`}
          />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  );
};

export default InterpreterRouter;

// interpreterRoutes is obsolete but not removed till v1.6.0 is released
