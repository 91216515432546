import { useEffect } from 'react';
import logger from 'SERVICES/logger';
import acsUtils from 'SERVICES/callClient/acsUtils';
import { useSelector } from 'react-redux';
import useActions from 'HOOKS/useActions';
import { useTestCallDeviceManager } from '../../TestCall';
import { useLangDeviceManager } from '../../LanguageMeeting';
import { useFloorDeviceManager } from '../../FloorMeeting';
import { useRelayDeviceManager } from '../../RelayMeeting';
import { landingPageActions, landingPageSelector as selector } from '../landingPageSlice';

const useDeviceManager = () => {
  const speakerDevices = useSelector(selector.speakerDevices);
  const microphoneDevices = useSelector(selector.microphoneDevices);
  const selectedSpeaker = useSelector(selector.selectedSpeaker);
  const selectedMicrophone = useSelector(selector.selectedMicrophone);

  const {
    addSpeakerDevices,
    addMicrophoneDevices,
    removeMicrophoneDevices,
    removeSpeakerDevices,
    setSelectedMicrophone,
    setSelectedSpeaker,
    setSpeakerDevices,
    setMicrophoneDevices,
  } = useActions(landingPageActions);

  const testCallDeviceManager = useTestCallDeviceManager();
  const langMeetingDeviceManager = useLangDeviceManager();
  const floorMeetingDeviceManager = useFloorDeviceManager();
  const relayMeetingDeviceManager = useRelayDeviceManager();

  const handleSpeakerSelection = (speaker) => {
    if (speaker?.id !== selectedSpeaker?.id) {
      testCallDeviceManager.setSelectedSpeaker(speaker);
      floorMeetingDeviceManager.setSelectedSpeaker(speaker);
      relayMeetingDeviceManager.setSelectedSpeaker(speaker);
      langMeetingDeviceManager.setSelectedSpeaker(speaker);
    }
    setSelectedSpeaker(speaker);
  };

  const handleMicrophoneSelection = (microphone) => {
    if (microphone?.id !== selectedMicrophone?.id) {
      testCallDeviceManager.setSelectedMicrophone(microphone);
      langMeetingDeviceManager.setSelectedMicrophone(microphone);
    }
    setSelectedMicrophone(microphone);
  };

  const subscribeToDeviceManager = async () => {
    const callClient = acsUtils.createCallClient();
    const deviceManager = await acsUtils.getDeviceManager(callClient);
    await deviceManager.askAudioDevicePermission();

    const speakers = await deviceManager.getSpeakers();
    logger.debug('Speaker List: ', speakers);
    setSpeakerDevices(speakers);
    if (!selectedSpeaker) handleSpeakerSelection(deviceManager.getSelectedSpeaker());
    else deviceManager.setSpeakerDevice(selectedSpeaker);

    const microphones = await deviceManager.getMicrophones();
    logger.debug('Microphones List: ', microphones);
    setMicrophoneDevices(microphones);
    if (!selectedMicrophone) handleMicrophoneSelection(deviceManager.getSelectedMicrophone());
    else deviceManager.setMicrophoneDevice(selectedMicrophone);

    deviceManager.onAudioDevicesUpdated({
      onSpeakerAdded: (devices) => addSpeakerDevices(devices),
      onSpeakerRemoved: (devices) => removeSpeakerDevices(devices),
      onMicrophoneAdded: (devices) => addMicrophoneDevices(devices),
      onMicrophoneRemoved: (devices) => removeMicrophoneDevices(devices),
    });

    deviceManager.onSelectedSpeakerChanged((selectedDevice) => {
      handleSpeakerSelection(selectedDevice);
    });

    deviceManager.onSelectedMicrophoneChanged((selectedDevice) => {
      handleMicrophoneSelection(selectedDevice);
    });
  };

  useEffect(() => {
    subscribeToDeviceManager();
  }, []);

  return {
    speaker: {
      items: speakerDevices,
      selectedItem: selectedSpeaker,
      onSelect: handleSpeakerSelection,
    },
    microphone: {
      items: microphoneDevices,
      selectedItem: selectedMicrophone,
      onSelect: handleMicrophoneSelection,
    },
  };
};

export default useDeviceManager;
