import ROUTES from 'CONSTANTS/routes';
import ParticipantHome from 'FEATURES/participant/features/ParticipantHome';

const participantRoutes = [
  { path: `${ROUTES.PARTICIPANT}/join/:meetingId`, element: <ParticipantHome /> },
];

export default participantRoutes;

// this file is obsolete but not removed till v1.6.0 is released
