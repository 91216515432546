import { API_CONTEXT } from 'CONSTANTS/apiConstants';
import serverAPI from 'SERVICES/serverAPI';
import { API } from '../constants';

export const getRecorderMeetingDetails = async (meetingId) => {
  const { data } = await serverAPI.get(`${API.meetings}/${meetingId}/${API_CONTEXT.recorder}`);
  return data;
};

export const fetchACSAccessToken = async (userId) => {
  const { data } = await serverAPI.post(API.fetchAcsToken, { userId });
  return data.token;
};

export const startRecordingCall = async (recordingInformation) => {
  const { data } = await serverAPI.post(API.startRecording, { recordingInformation });
  return data;
};

export const stopRecordingCall = async (recordingData) => {
  const { data } = await serverAPI.post(API.stopRecording, { recordingData });
  return data;
};
