// libraries
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Flex, FlexItem } from '@fluentui/react-northstar';

// utilities
import logger from 'SERVICES/logger';
import useActions from 'HOOKS/useActions';

// resources
import controls from 'RESOURCES/images/controls-grey.svg';
import micActive from 'RESOURCES/images/mic-active-grey.svg';
import { useTranslation } from 'SERVICES/i18n';

// connectors
import socketClient from 'SRC/socket/client';
import {
  interpreterActions,
  selectInterpreter,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import { useSelector } from 'react-redux';

import { Dialog, DialogFooter, PrimaryButton, DefaultButton, Label } from '@fluentui/react';
import {
  OUTGOING_CALL_TYPE,
  MIC_STATE,
  HANDOVER_BUTTON_STATE,
  SPACE_KEYWORD,
} from 'FEATURES/interpreter/constants';
import {
  showToast,
  TOAST_DURATION,
  TOAST_TYPE,
} from 'FEATURES/interpreter/components/CustomToastContainer';
import { SOCKET_REQUEST, SOCKET_RESPONSE } from 'SRC/socket/socketConstant';
import { primaryLangMeetingSelector } from './primaryLangMeetingSlice';
import { secondaryLangMeetingSelector } from './secondaryLangMeetingSlice';

import usePrimaryLanguageMeeting from './usePrimaryLanguageMeeting';
import useSecondaryLanguageMeeting from './useSecondaryLanguageMeeting';

// components
import MicButton from './UIComponents/MicButton';
import HandoverButton from './UIComponents/HandoverButton';
import ControlHeader from '../Dashboard/components/ControlHeader';
import OutgoingLanguageSelector from './UIComponents/OutgoingLanguageSelection';
import 'RESOURCES/styles/globalStyles.scss';

const OutgoingLanguageMeeting = ({ languages }) => {
  const [micState, setMicState] = useState(MIC_STATE.disable);
  const [handoverState, setHandoverState] = useState(HANDOVER_BUTTON_STATE.disabled);
  const [isSpaceBarHold, setSpaceBarHold] = useState(false);
  const [showForceUnmuteDialog, setShowForceUnmuteDialog] = useState(false);
  // To check the forced interpreter ID and display message
  const chatInfo = useSelector(selectInterpreter.chatInfo);
  // Recording
  const isRecordingEnabled = useSelector(selectInterpreter.isRecordingEnabled);

  const callRef = useRef();
  const callRecordingRef = useRef();
  const activeInterpreterIdRef = useRef();
  const activeLanguageCodeRef = useRef();
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });
  const primaryLangCallClient = useSelector(primaryLangMeetingSelector.prilangCallClient);
  const priRecordingLangCallClient = useSelector(
    primaryLangMeetingSelector.priRecordingLangCallClient
  );
  const secondaryLangCallClient = useSelector(secondaryLangMeetingSelector.secLangCallClient);
  const secRecordingLangCallClient = useSelector(
    secondaryLangMeetingSelector.secRecordingLangCallClient
  );
  const activeLanguageStatus = useSelector(selectInterpreter.activeLanguageStatus);
  const setSelectedPrimaryLanguageInfo = useActions(
    interpreterActions.setSelectedPrimaryLanguageInfo
  );
  const activeLanguageCode = useSelector(selectInterpreter.activeLanguageCode);

  const setSelectedSecondaryLanguageInfo = useActions(
    interpreterActions.setSelectedSecondaryLanguageInfo
  );
  const setActiveLanguageStatus = useActions(interpreterActions.setActiveLanguageStatus);
  const setActiveLanguageCode = useActions(interpreterActions.setActiveLanguageCode);
  const selectedPrimaryLanguageInfo = useSelector(selectInterpreter.selectedPrimaryLanguageInfo);
  const selectedSecondaryLanguageInfo = useSelector(
    selectInterpreter.selectedSecondaryLanguageInfo
  );
  // To be used by BoothMeetingButton to mute/unmute the call
  const setMicStatus = useActions(interpreterActions.setMicState);
  const { joinPrimaryLanguageMeeting, joinPrimaryRecordingLanguageMeeting } =
    usePrimaryLanguageMeeting();
  const { joinSecondaryLanguageMeeting, joinSecondaryRecordingLanguageMeeting } =
    useSecondaryLanguageMeeting();

  const showBoothEventNotifications = (notifyInfo) => {
    showToast({
      data: {
        content: notifyInfo,
        autoClose: TOAST_DURATION.long,
        type: TOAST_TYPE.info,
      },
    });
  };

  useEffect(() => {
    logger.debug('Language Meeting Component First load');

    // #region socket
    socketClient.subscribe(SOCKET_RESPONSE.userMuted, async (data) => {
      showBoothEventNotifications(t('EVENT_USER_MUTED', { User: data.name }));
      activeInterpreterIdRef.current = null;
      if (!data.error && activeLanguageCodeRef.current !== OUTGOING_CALL_TYPE.none) {
        setMicState(MIC_STATE.mute);
        setMicStatus(MIC_STATE.mute);
      }
    });
    socketClient.subscribe(SOCKET_RESPONSE.muteAck, async () => {
      activeInterpreterIdRef.current = null;
    });
    socketClient.subscribe(SOCKET_RESPONSE.unmuteAck, async (data) => {
      // If there is an error we are sending code
      if (!data.error) {
        activeInterpreterIdRef.current = data.userId;
        await callRef.current?.unmuteCall();
        await callRecordingRef.current?.unmuteCall();
        setMicState(MIC_STATE.unmute);
        setMicStatus(MIC_STATE.unmute);
        setHandoverState(HANDOVER_BUTTON_STATE.enabled);
      }
      // If want to show error we can show that in else
    });
    socketClient.subscribe(SOCKET_RESPONSE.userLeftBooth, async (data) => {
      showBoothEventNotifications(t('EVENT_USER_LEFT_BOOTH', { User: data.name }));
      // This code is added to tackle the problem of active person leaves meeting and join again
      // But nobody able to active in booth
      if (data.activeLanguage !== null && activeInterpreterIdRef.current === data.userId) {
        activeInterpreterIdRef.current = null;
      }
      // Checking whether user who left booth was active so that we can enable mute button to partners
      if (
        activeLanguageCodeRef.current !== OUTGOING_CALL_TYPE.none &&
        data.activeLanguage !== null
      ) {
        setMicState(MIC_STATE.mute);
        setMicStatus(MIC_STATE.mute);
      }
    });
    socketClient.subscribe(SOCKET_RESPONSE.handOverAck, (data) => {
      // activeInterpreterId.current = null;
      if (!data.error) {
        setHandoverState(HANDOVER_BUTTON_STATE.active);
      }
    });
    socketClient.subscribe(SOCKET_RESPONSE.joinAck, (data) => {
      if (!data.isRejoin) {
        setHandoverState(HANDOVER_BUTTON_STATE.disabled);
      }
    });
    socketClient.subscribe(SOCKET_RESPONSE.userHandedOver, (data) => {
      showBoothEventNotifications(t('EVENT_USER_HANDEDOVER', { User: data.name }));
      activeInterpreterIdRef.current = null;
      if (activeLanguageCodeRef.current !== OUTGOING_CALL_TYPE.none) {
        setHandoverState(HANDOVER_BUTTON_STATE.active);
      }
    });
    socketClient.subscribe(SOCKET_RESPONSE.takeOverAck, async (data) => {
      if (!data.error) {
        activeInterpreterIdRef.current = data.userId;
        await callRef.current?.unmuteCall();
        await callRecordingRef.current?.unmuteCall();
        setMicState(MIC_STATE.unmute);
        setMicStatus(MIC_STATE.unmute);
        setHandoverState(HANDOVER_BUTTON_STATE.enabled);
      }
      // If want to show error we can show that in else
    });
    socketClient.subscribe(SOCKET_RESPONSE.userTookOver, async (data) => {
      // If you are connected to a language
      showBoothEventNotifications(t('EVENT_USER_TOOKOVER', { User: data.name }));
      activeInterpreterIdRef.current = data.userId;
      if (activeLanguageCodeRef.current !== OUTGOING_CALL_TYPE.none) {
        await callRef.current?.muteCall();
        await callRecordingRef.current?.muteCall();
        setHandoverState(HANDOVER_BUTTON_STATE.disabled);
        setMicState(MIC_STATE.mute);
        setMicStatus(MIC_STATE.mute);
      }
    });
    socketClient.subscribe(SOCKET_RESPONSE.updateActiveLanguageAck, (data) => {
      logger.debug(data);
      // If no one in booth active then turn it to mute from disable
      if (
        activeLanguageCodeRef.current !== OUTGOING_CALL_TYPE.none &&
        activeInterpreterIdRef.current !== chatInfo.userId
      ) {
        setMicState(MIC_STATE.mute);
        setMicStatus(MIC_STATE.mute);
      }
    });
    socketClient.subscribe(SOCKET_RESPONSE.userUnmuted, async (data) => {
      if (data.forceInterpreterId === chatInfo.userId) {
        showBoothEventNotifications(t('FORCE_UNMUTE_MESSAGE'));
      } else {
        showBoothEventNotifications(t('EVENT_USER_UNMUTED', { User: data.name }));
      }
      if (activeLanguageCodeRef.current !== OUTGOING_CALL_TYPE.none) {
        activeInterpreterIdRef.current = data.userId;
        await callRef.current?.muteCall();
        await callRecordingRef.current?.muteCall();
        setMicState(MIC_STATE.mute);
        setMicStatus(MIC_STATE.mute);
        setHandoverState(HANDOVER_BUTTON_STATE.disabled);
      }
    });
    socketClient.subscribe(SOCKET_RESPONSE.updateBoothAck, async (data) => {
      if (!data.error) {
        setActiveLanguageStatus(OUTGOING_CALL_TYPE.none);
        setActiveLanguageCode(null);
        setHandoverState(HANDOVER_BUTTON_STATE.disabled);
        activeInterpreterIdRef.current = data.activeInterpreter;
      }
      // If want to show error we can show that in else
    });
    socketClient.subscribe(SOCKET_RESPONSE.userJoinedBooth, async (data) => {
      showBoothEventNotifications(t('EVENT_USER_JOINED_BOOTH', { User: data.name }));
    });
    // #endregion

    const spaceEventListener = (event) => {
      if (
        event?.code === SPACE_KEYWORD &&
        !isSpaceBarHold &&
        !callRef.current?.call?._isMuted &&
        !!callRef.current
      ) {
        const inputAvailable = document.querySelector('textarea');
        if (
          event?.type === 'keydown' &&
          event?.repeat &&
          document.activeElement !== inputAvailable
        ) {
          setSpaceBarHold(true);
          /**
           * WARNING: muteInputAsync() function is not mentioned in acs docs so it may be change in next acs version.
           *
           * alternative way to block outgoing audio
           * tsCall.callStats.mediaStats.localStreams[0].tracks[0].track.enabled = false
           *
           * INFO
           * call.tsCall.mediaSession.muteOutputAsync() function is used to block incoming audio in acs.
           * */
          callRef.current?.call.tsCall.mediaSession.muteInputAsync();
          callRecordingRef.current?.call.tsCall.mediaSession.muteInputAsync();
          setMicState(MIC_STATE.mute);
          setMicStatus(MIC_STATE.mute);
        } else if (event?.type === 'keyup') {
          setSpaceBarHold(false);
          callRef.current?.call.tsCall.mediaSession.unmuteInputAsync();
          callRecordingRef.current?.call.tsCall.mediaSession.unmuteInputAsync();
          setMicState(MIC_STATE.unmute);
          setMicStatus(MIC_STATE.unmute);
        }
      }
    };

    document.addEventListener('keydown', spaceEventListener);
    document.addEventListener('keyup', spaceEventListener);
    return () => {
      document.removeEventListener('keydown', spaceEventListener);
      document.removeEventListener('keyup', spaceEventListener);
    };
  }, []);

  useEffect(async () => {
    activeLanguageCodeRef.current = activeLanguageStatus;
    // When we connect/select Primary language
    if (activeLanguageStatus === OUTGOING_CALL_TYPE.primary) {
      await secondaryLangCallClient?.muteCall();
      await secRecordingLangCallClient?.muteCall();

      // Persist value for fast switching
      if (micState === MIC_STATE.unmute) {
        await primaryLangCallClient?.unmuteCall();
        await priRecordingLangCallClient?.unmuteCall();
      } else if (micState === MIC_STATE.disable) {
        await primaryLangCallClient?.muteCall();
        await priRecordingLangCallClient?.muteCall();
      }
      callRef.current = primaryLangCallClient;
      if (isRecordingEnabled) {
        callRecordingRef.current = priRecordingLangCallClient;
      }
    } else if (activeLanguageStatus === OUTGOING_CALL_TYPE.secondary) {
      // When we connect/select Primary language
      await primaryLangCallClient?.muteCall();
      await priRecordingLangCallClient?.muteCall();

      if (micState === MIC_STATE.unmute) {
        await secondaryLangCallClient?.unmuteCall();
        await secRecordingLangCallClient?.unmuteCall();
      } else if (micState === MIC_STATE.disable) {
        await secondaryLangCallClient?.muteCall();
        await secRecordingLangCallClient?.muteCall();
      }
      callRef.current = secondaryLangCallClient;
      if (isRecordingEnabled) {
        callRecordingRef.current = secRecordingLangCallClient;
      }
    } else {
      // This block will execute when we are not connected to any language or we switched to any
      callRef.current = null;
      if (isRecordingEnabled) {
        callRecordingRef.current = null;
        await priRecordingLangCallClient?.muteCall();
        await secRecordingLangCallClient?.muteCall();
      }
      await primaryLangCallClient?.muteCall();
      await secondaryLangCallClient?.muteCall();
      setMicState(MIC_STATE.disable);
      setMicStatus(MIC_STATE.disable);
    }
  }, [activeLanguageStatus]);

  const onLanguageChange = async (language, type) => {
    logger.debug(language, type, 'language changed');
    if (type === OUTGOING_CALL_TYPE.primary) {
      await joinPrimaryLanguageMeeting(language);
      if (isRecordingEnabled) {
        await joinPrimaryRecordingLanguageMeeting(language);
      }
      setSelectedPrimaryLanguageInfo(language);
      // Changing booth when we select any other language
      socketClient.emit(SOCKET_REQUEST.updateBooth, {
        boothLanguages: [language.language, selectedSecondaryLanguageInfo?.language],
      });
    } else {
      await joinSecondaryLanguageMeeting(language);
      if (isRecordingEnabled) {
        await joinSecondaryRecordingLanguageMeeting(language);
      }
      setSelectedSecondaryLanguageInfo(language);
      // Changing booth when we select any other language
      socketClient.emit(SOCKET_REQUEST.updateBooth, {
        boothLanguages: [selectedPrimaryLanguageInfo?.language, language.language],
      });
    }
  };

  const updateSocketActiveLanguage = (activeLanguage) => {
    socketClient.emit(SOCKET_REQUEST.updateActiveLanguage, {
      newLangCode: activeLanguage,
    });
  };

  const handleMicOnOff = async () => {
    logger.debug('HandleMicOnOff state before changing', micState, callRef.current?.isCallMuted());
    try {
      if (!callRef.current?.isCallMuted()) {
        await callRef.current?.muteCall();
        await callRecordingRef.current?.muteCall();
        setMicState(MIC_STATE.mute);
        setMicStatus(MIC_STATE.mute);
        setHandoverState(HANDOVER_BUTTON_STATE.disabled);
        // Sending WS request
        socketClient.emit(SOCKET_REQUEST.mute);
      } else if (activeInterpreterIdRef.current !== null) {
        setShowForceUnmuteDialog(true);
      } else {
        // Sending WS request
        socketClient.emit(SOCKET_REQUEST.unmute, {
          newLangCode: activeLanguageCode.language,
        });
        // Actual unmuting after acknowledgement
      }
    } catch (e) {
      logger.error(e);
    }
  };
  const closeDialog = () => setShowForceUnmuteDialog(false);
  const forceUnmute = async () => {
    socketClient.emit(SOCKET_REQUEST.unmute, {
      newLangCode: activeLanguageCode.language,
      isForceUnmute: true,
    });
    closeDialog();
  };

  const modalProps = useMemo(
    () => ({
      isBlocking: true,
      styles: { main: { maxWidth: 550 } },
    }),
    []
  );

  const dialogContentProps = {
    title: t('FORCE_UNMUTE_DIALOG_TITLE'),
    showCloseButton: false,
  };

  const handleHandoverClickEvent = async () => {
    logger.debug('handleHandoverClickEvent');
    if (handoverState === HANDOVER_BUTTON_STATE.enabled) {
      // Sending WS request
      setHandoverState(HANDOVER_BUTTON_STATE.active);
      socketClient.emit(SOCKET_REQUEST.handOver);
    } else if (handoverState === HANDOVER_BUTTON_STATE.active) {
      // Sending WS request
      socketClient.emit(SOCKET_REQUEST.takeOver, {
        newLangCode: activeLanguageCode.language,
      });
    }
  };

  return (
    <>
      <FlexItem vAlign="center">
        <div className="sectionBorder flexGrow1">
          <ControlHeader content={t('OUTGOING')} icon={micActive} />
          <Flex
            className="mt-2 px-1 pb-1 flexGrow1"
            gap="gap.small"
            vAlign="center"
            hAlign="center"
          >
            <OutgoingLanguageSelector
              languages={languages}
              prilangCallClient={primaryLangCallClient}
              secLangCallClient={secondaryLangCallClient}
              onLanguageChange={onLanguageChange}
              updateSocketActiveLanguage={updateSocketActiveLanguage}
            />
          </Flex>
        </div>
      </FlexItem>
      <FlexItem>
        <div className="sectionBorder flexGrow1">
          <ControlHeader content={t('CONTROLS')} icon={controls} />
          <Flex
            className="mt-2 px-1 pb-1 flexGrow1"
            gap="gap.small"
            vAlign="center"
            hAlign="center"
          >
            <FlexItem>
              <MicButton state={micState} onClick={handleMicOnOff} />
            </FlexItem>

            <FlexItem>
              <HandoverButton state={handoverState} onClick={handleHandoverClickEvent} />
            </FlexItem>
          </Flex>
        </div>
      </FlexItem>
      <Dialog
        modalProps={modalProps}
        dialogContentProps={dialogContentProps}
        hidden={!showForceUnmuteDialog}
      >
        <Label>{t('FORCE_UNMUTE_DIALOG_TEXT')}</Label>
        <DialogFooter>
          <PrimaryButton onClick={forceUnmute} text={t('FORCE_DIALOG_BUTTON_UNMUTE')} />
          <DefaultButton onClick={closeDialog} text={t('FORCE_DIALOG_BUTTON_CANCEL')} />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default OutgoingLanguageMeeting;
