export const AUDIO_STREAM_NAME = 'streamName';
export const AUDIO_TAG = 'audio';
export const STREAM_TAGS = {
  TEAMS: 'teams',
  LANGUAGE: 'language',
};

export const checkAndUpdateAudioElement = (streamName) => {
  const audios = document.querySelectorAll(AUDIO_TAG);
  let newAudioELe = null;
  // We can’t use find here also length check is also required as it is not a normal collection but it is HTML element
  // and there foreach is little different, Hence keeping code as it is.
  if (audios.length !== 0) {
    audios.forEach((audioEle) => {
      if (audioEle.streamName === undefined) {
        audioEle.streamName = streamName;
        audioEle.TIME = new Date().toString();
        newAudioELe = audioEle;
      }
    });
  }
  return newAudioELe;
};

export const getAudioElement = (streamName) => {
  const audios = document.querySelectorAll(AUDIO_TAG);
  let audioTag = null;
  // We can’t use find here also length check is also required as it is not a normal collection but it is HTML element
  // and there foreach is little different, Hence keeping code as it is.
  if (audios.length !== 0) {
    audios.forEach((audioEle) => {
      if (audioEle.streamName === streamName) {
        audioTag = audioEle;
      }
      return null;
    });
  }
  return audioTag;
};
