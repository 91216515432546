import { LAYOUT_DIRECTION, LAYOUT_OPTIONS, VIDEO_GALLERY } from 'FEATURES/interpreter/constants';
import './VideoGallery.scss';
import { Button } from '@fluentui/react-northstar';
import { ChevronEndIcon, ChevronStartIcon } from '@fluentui/react-icons-northstar';

import { useRef, useState } from 'react';
import logger from 'SERVICES/logger';
import CallStatus from '../CallStatus';
import useVideoGallery from './useVideoGallery';
import VideoGalleryLayout from '../VideoGalleryLayout/VideoGalleryLayout';

const VideoGallery = () => {
  const { callState, remoteParticipantVideo } = useVideoGallery();

  // FIXME: Render issue similar to other component
  // eslint-disable-next-line no-unused-vars
  const [renderComponent, setRenderComponent] = useState(undefined);

  // Active Index of the layout
  const activeIndex = useRef(1);

  // Added this for AKMSTEAMS-536
  const activeSpeakerParticipant = useRef(undefined);

  // Added this for positioning requirement
  const isScreenSharingStarted = useRef(undefined);

  // Active layout
  const activeItem = useRef(LAYOUT_OPTIONS.activeSpeaker);

  // All layout items
  const allItems = [
    {
      id: LAYOUT_OPTIONS.default,
    },
    {
      id: LAYOUT_OPTIONS.activeSpeaker,
    },
  ];

  // Finding out speaker who is currently speaking
  // Additional check for mute flag because when screen sharing started, isSpeaking flag always remains true
  const activeSpeaker =
    remoteParticipantVideo.find((speaker) => speaker.isSpeaking && !speaker.isMuted) || undefined;

  // Finding out if screen is shared for any participant
  const screenSharingParticipant = remoteParticipantVideo.find(
    (speaker) => speaker.isScreenSharingOn
  );

  // As per AKMSTEAMS-536, we need to remember the active speaker even if he stops speaking
  if (
    !activeSpeakerParticipant.current ||
    (activeSpeakerParticipant.current &&
      activeSpeaker &&
      activeSpeakerParticipant.current.userId !== activeSpeaker.userId)
  ) {
    activeSpeakerParticipant.current = activeSpeaker;
  }

  // If screen sharing is stopped we need to focus on active layout
  if (!screenSharingParticipant && isScreenSharingStarted.current) {
    activeItem.current = LAYOUT_OPTIONS.activeSpeaker;
    activeIndex.current = 1;
    isScreenSharingStarted.current = undefined;
    setRenderComponent(Date.now);
  }

  // Case when screenshare started and need to focus on video layout
  if (screenSharingParticipant && !isScreenSharingStarted.current) {
    activeItem.current = LAYOUT_OPTIONS.video;
    activeIndex.current = 3;
    isScreenSharingStarted.current = screenSharingParticipant;
    setRenderComponent(Date.now);
  } else if (!screenSharingParticipant) {
    isScreenSharingStarted.current = undefined;
  }

  const getAllLayouts = () => {
    if (screenSharingParticipant) {
      allItems.push({
        id: LAYOUT_OPTIONS.sharedScreen,
      });

      allItems.push({
        id: LAYOUT_OPTIONS.video,
      });
    }

    return allItems;
  };

  const navigationClick = (direction) => {
    const allLayoutItems = getAllLayouts();
    const currentSelectedIndex = activeIndex.current;
    if (direction === LAYOUT_DIRECTION.previous) {
      if (currentSelectedIndex - 1 < 0) {
        activeIndex.current = allLayoutItems.length - 1;
        activeItem.current = allLayoutItems[allLayoutItems.length - 1].id;
      } else {
        activeIndex.current = currentSelectedIndex - 1;
        activeItem.current = allLayoutItems[currentSelectedIndex - 1].id;
      }
    } else if (direction === LAYOUT_DIRECTION.next) {
      if (currentSelectedIndex + 1 >= allLayoutItems.length) {
        activeIndex.current = 0;
        activeItem.current = allLayoutItems[0].id;
      } else {
        activeIndex.current = currentSelectedIndex + 1;
        activeItem.current = allLayoutItems[currentSelectedIndex + 1].id;
      }
    }
    logger.debug('Active layout:', activeItem?.current);
    setRenderComponent(Date.now);
  };

  const render = () => {
    if (callState === VIDEO_GALLERY.connected && remoteParticipantVideo.length !== 0) {
      return (
        <>
          <Button
            className="button-left"
            circular
            icon={<ChevronStartIcon />}
            onClick={() => navigationClick(LAYOUT_DIRECTION.previous)}
          />

          <VideoGalleryLayout
            layoutType={activeItem.current}
            activeSpeaker={activeSpeakerParticipant.current}
            screenSharingParticipant={screenSharingParticipant}
            remoteParticipantVideo={remoteParticipantVideo}
          />

          <Button
            className="button-right"
            circular
            icon={<ChevronEndIcon />}
            onClick={() => navigationClick(LAYOUT_DIRECTION.next)}
          />
        </>
      );
    }
    return (
      <CallStatus callState={callState} noParticipants={remoteParticipantVideo.length === 0} />
    );
  };

  return <div className="video-gallery">{render()}</div>;
};

export default VideoGallery;
