import { Flex } from '@fluentui/react-northstar';
import { useTranslation } from 'SERVICES/i18n';
import chatIcon from 'RESOURCES/images/chat-grey.svg';
import useChatClient from './useChatClient';
import ParticipantList from './ParticipantList';
import ChatThread from './ChatThread';
import ControlHeader from '../Dashboard/components/ControlHeader';
import '../Dashboard/components/Header.scss';

const ChatWindow = ({ socketConnected }) => {
  const { currentThread, unreadThreads } = useChatClient();
  const { t } = useTranslation('interpreter', { keyPrefix: 'CHAT' });
  const videoGalleryHeight = document.getElementById('videoGallery')?.clientHeight;

  return (
    <Flex column className="sectionBorder" id="chatWindow" style={{ height: videoGalleryHeight }}>
      <ControlHeader
        content={
          unreadThreads.length > 0 ? t('UNREAD_CHATS', { count: unreadThreads.length }) : t('CHATS')
        }
        icon={chatIcon}
      />
      {currentThread?.user?.id ? (
        <ChatThread socketConnected={socketConnected} />
      ) : (
        <ParticipantList />
      )}
    </Flex>
  );
};

export default ChatWindow;
