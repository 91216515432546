import React from 'react';

import useTheme from 'HOOKS/useTheme';

const HyperLink = ({ link }) => {
  const { theme } = useTheme();
  return (
    <a
      href={link}
      className="no-underline pl-1"
      style={{ color: theme.siteVariables?.colorScheme.brand.foreground }}
      target="_blank"
      rel="noreferrer"
    >
      {link}
    </a>
  );
};

export default HyperLink;
