import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'SERVICES/i18n';
import { Flex, Text, Button, Checkbox } from '@fluentui/react-northstar';

import {
  teamsMeetingSelector as selector,
  selectInterpretationLanguages,
} from 'FEATURES/teams/slices/teamsMeetingSlice';
import { USER_ROLE } from 'FEATURES/teams/constants';
import AkouoSupport from 'COMPONENTS/common/AkouoSupport';
import { getLanguageNames } from 'UTILS/languagesUtils';

const ParticipantInformation = ({ noLanguages, t }) => {
  return (
    <Flex column>
      <span>
        {noLanguages
          ? t('NO_LANGUAGES_CONFIGURED_PARTICIPANT')
          : t('LANGUAGES_CONFIGURED_PARTICIPANT')}
      </span>
      <AkouoSupport />
    </Flex>
  );
};

ParticipantInformation.propTypes = {
  noLanguages: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const PreMeetingView = ({ handleEditClick, copyToClip }) => {
  const selectedLanguages = useSelector(selectInterpretationLanguages);
  const currentUserRole = useSelector(selector.currentUserRole);
  const teamsMeetingDetail = useSelector(selector.teamsMeetingDetail);
  const isUserOrganizer = currentUserRole === USER_ROLE.ORGANIZER;

  const { t } = useTranslation('teams', { keyPrefix: 'PREMEETING' });

  return (
    <Flex column gap="gap.large">
      <Flex gap="gap.small" column>
        <Text size="large" weight="bold" content={t('MEETING_LANGUAGES_LABEL')} />
        <Text className="pl-14" styles={{ opacity: 0.68 }}>
          {!isUserOrganizer && (
            <ParticipantInformation noLanguages={selectedLanguages.length === 0} t={t} />
          )}
        </Text>
        <Flex wrap>
          <Text className="language-name pl-14">
            {getLanguageNames(selectedLanguages)
              .map((item) => item.label)
              .join(', ')}
          </Text>
        </Flex>
      </Flex>

      {isUserOrganizer && (
        <Flex column gap="gap.smaller">
          <Checkbox
            className="dropdown-label"
            label={t('AUA_TITLE')}
            style={{ maxWidth: '20em', pointerEvents: 'none' }}
            checked={!!teamsMeetingDetail?.universalAccessEnabled}
          />
          {teamsMeetingDetail?.universalAccessEnabled && (
            <Flex className="my-2">
              <Button content={t('COPY_LINK_FOR_PARTICIPANTS')} secondary onClick={copyToClip} />
            </Flex>
          )}
          <Text styles={{ opacity: 0.5 }} content={t('EDIT_REQUEST_LABEL')} />
          <div>
            <Button content={t('EDIT_LANG_BTN')} primary onClick={() => handleEditClick()} />
          </div>
        </Flex>
      )}
    </Flex>
  );
};

PreMeetingView.propTypes = {
  handleEditClick: PropTypes.func.isRequired,
};

export default PreMeetingView;
