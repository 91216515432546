import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deviceManager: undefined,
  selectedSpeaker: undefined,
  relayInterpreterId: undefined,
  incomingAudioElement: null,
  relayCallClient: null,
  isLoading: false,
};

const relayMeetingSlice = createSlice({
  name: 'relayMeeting',
  initialState,
  reducers: {
    setRelayCallClientObject(state, action) {
      state.relayCallClient = action.payload;
    },
    setRelayInterpreterId(state, action) {
      state.relayInterpreterId = action.payload;
    },
    setIncomingAudioElement(state, action) {
      state.incomingAudioElement = action.payload;
    },
    setDeviceManager(state, action) {
      state.deviceManager = action.payload;
    },
    setSelectedSpeaker(state, action) {
      state.selectedSpeaker = action.payload;
    },
    setCallLoader(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export default relayMeetingSlice.reducer;

export const relayMeetingActions = { ...relayMeetingSlice.actions };

export const RelayMeetingSelector = {
  relayCallClient: (store) => store.relayMeeting.relayCallClient,
  relayInterpreterId: (state) => state.relayMeeting.relayInterpreterId,
  deviceManager: (store) => store.relayMeeting.deviceManager,
  selectedSpeaker: (store) => store.relayMeeting.selectedSpeaker,
  incomingAudioElement: (store) => store.relayMeeting.incomingAudioElement,
  isLoading: (store) => store.relayMeeting.isLoading,
};
