import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import logger from 'SERVICES/logger';
import useActions from 'HOOKS/useActions';
import { fetchACSAccessToken } from 'FEATURES/interpreter/api/api';
import ACSCallClient from 'SERVICES/callClient/index';
import {
  interpreterActions,
  selectInterpreter,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import { getAudioElements } from 'FEATURES/interpreter/utils/audioElementUtils';
import { RELAY_MEETING_DISPLAY_NAME, MEETING } from 'FEATURES/interpreter/constants';
import { relayMeetingActions, RelayMeetingSelector as selector } from '../relayMeetingSlice';
import useInterpreterMeetings from '../../LandingPage/hooks/useInterpreterMeetings';

const useRelayMeeting = () => {
  const setSelectedRelayInfo = useActions(interpreterActions.setSelectedRelayInfo);
  const relayMeeting = useSelector(selectInterpreter.relayMeeting);
  const selectedSpeaker = useSelector(selector.selectedSpeaker);
  const relayCallClient = useSelector(selector.relayCallClient);

  const {
    setRelayCallClientObject,
    setDeviceManager,
    setSelectedSpeaker,
    setIncomingAudioElement,
    setCallLoader,
  } = useActions(relayMeetingActions);
  const language = useInterpreterMeetings();

  // TODO: check redux state and ref issue for dispose() and hangup() issue;
  const acsCallObjectRef = useRef();
  const { CALL_STATE, STATE_CHANGED, CALL_UPDATED } = ACSCallClient;

  useEffect(() => {
    acsCallObjectRef.current = relayCallClient;
  }, [relayCallClient]);

  const subscribeToDeviceManager = async (callClientObj) => {
    const deviceManager = await callClientObj.getACSDeviceManager();
    await deviceManager.askAudioDevicePermission();
    setDeviceManager(deviceManager);

    if (selectedSpeaker) {
      await deviceManager.setSpeakerDevice(selectedSpeaker);
    } else setSelectedSpeaker(deviceManager.getSelectedSpeaker());

    deviceManager.onSelectedSpeakerChanged((speaker) => setSelectedSpeaker(speaker));
  };

  const setupRelayMeeting = async () => {
    try {
      logger.info('setup Relay Meeting');

      // getToken
      const token = await fetchACSAccessToken(relayMeeting.id);
      logger.debug('ACS Token In relay meet', token);

      // setup
      const config = {
        acsToken: token,
        displayName: RELAY_MEETING_DISPLAY_NAME,
      };
      const callClientObj = new ACSCallClient();
      await callClientObj.setupCall(config);
      setRelayCallClientObject(callClientObj);

      // subScribeTodeviceManager
      await subscribeToDeviceManager(callClientObj);

      callClientObj.subscribe(CALL_UPDATED, (addedCall) => {
        try {
          addedCall?.on(STATE_CHANGED, () => {
            if (addedCall.state === CALL_STATE.connected) {
              setCallLoader(false);
            }
          });
        } catch (error) {
          logger.error('Error In Relay Call Update', error);
          throw error;
        }
      });
    } catch (error) {
      logger.error('Error in  relay meeting setup', error);
      throw error;
    }
  };

  const hangUpRelayMeeting = async () => {
    try {
      logger.info('Hangup Relay Meeting');
      await relayCallClient?.hangupCall();
      setSelectedRelayInfo(null);
    } catch (error) {
      logger.error('Error while hangup relay meeting', error);
      throw error;
    }
  };

  const disposeRelayMeeting = async () => {
    try {
      logger.debug('Disposing relayMeeting call Agent');
      acsCallObjectRef.current?.disposeCall();
    } catch (error) {
      logger.error('Error while disposing relay meeting', error);
      throw error;
    }
  };

  const joinRelayMeeting = async (relayLanguage) => {
    try {
      if (relayCallClient?.getCallState() !== CALL_STATE.disconnected) {
        await hangUpRelayMeeting();
      }
      // join Call
      logger.info('Joining Relay Meeting');
      acsCallObjectRef.current.joinMeeting({
        groupId: relayLanguage.id,
      });
      setSelectedRelayInfo(relayLanguage);

      // tag audio element
      const relayMeetingAudioTag = await getAudioElements(MEETING.relayMeeting);
      logger.debug('Relay Meeting Audio Tag', [relayMeetingAudioTag]);
      setIncomingAudioElement(relayMeetingAudioTag);
    } catch (error) {
      logger.error('Error while joining relay meeting', error);
      throw error;
    }
  };

  return {
    language,
    relayCallClient,
    setupRelayMeeting,
    joinRelayMeeting,
    hangUpRelayMeeting,
    disposeRelayMeeting,
  };
};

export default useRelayMeeting;
