import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

/**
 * Turns an object and array whose values are action creators, into an object with the same keys or array in same sequence, but with every function wrapped into a dispatch call so they may be invoked directly.
 * @template T
 * @param {T} actions
 * @param {Array} deps
 * @returns {T}
 */
export default function useActions(actions, deps) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch]
  );
}
