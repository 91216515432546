import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Snackbar from 'COMPONENTS/common/Snackbar/Snackbar';
import { useSelector } from 'react-redux';
import { teamsRoutes } from 'FEATURES/teams';
import useSnackbar from 'HOOKS/useSnackbar';
import { SNACKBAR_DURATION, SNACKBAR_TYPE } from 'CONSTANTS/enum';
import { useTranslation } from 'SERVICES/i18n';
import { appSelector } from 'STORE/appSlice';

const TeamsRouter = () => {
  const routes = [...teamsRoutes];
  const errorTimestamp = useSelector(appSelector.errorTimestamp);
  const showSnackbar = useSnackbar();
  const ct = useTranslation('translation', { keyPrefix: 'COMMON' }).t;

  useEffect(() => {
    if (errorTimestamp) {
      showSnackbar({
        message: ct('GENERIC_ERROR'),
        duration: SNACKBAR_DURATION.LONG,
        type: SNACKBAR_TYPE.DANGER,
      });
    }
  }, [errorTimestamp]);
  return (
    <BrowserRouter>
      <>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route path={path} element={element} key={`Route-${path}`} />
          ))}
        </Routes>
        <Snackbar />
      </>
    </BrowserRouter>
  );
};

export default TeamsRouter;
