import { FLOATING_LOCAL_VIDEO, LAYOUT_OPTIONS } from 'FEATURES/interpreter/constants';
import { VideoGallery, VideoTile, StreamMedia } from '@azure/communication-react';
import './VideoGalleryLayout.scss';
import { Text } from '@fluentui/react-northstar';
import { useTranslation } from 'SERVICES/i18n';

const VideoGalleryLayout = ({
  layoutType,
  activeSpeaker,
  screenSharingParticipant,
  remoteParticipantVideo,
}) => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });

  // For Video gallery layout we need to give one local participant
  // Currently it is mock participant as we don't have interpreter as a participant of call
  // Instead all interpreter represents one entity
  const MockLocalParticipant = {
    userId: 'user1',
    displayName: 'You',
    state: 'Connected',
    isMuted: true,
  };

  const videoTileStyles = {
    root: {
      padding: '0px',
      margin: 0,
      border: '1px solid #999',
    },
  };

  const remoteVideoStreamOption = {
    scalingMode: 'Fit',
  };

  const activeSpeakerStyles = {
    root: {
      border: '1px solid #999',
    },
  };

  if (layoutType === LAYOUT_OPTIONS.activeSpeaker) {
    if (activeSpeaker) {
      return (
        <VideoTile
          displayName={activeSpeaker?.displayName}
          showMuteIndicator="true"
          isMuted={activeSpeaker?.isMuted}
          styles={activeSpeakerStyles}
          isSpeaking={activeSpeaker?.isSpeaking}
          renderElement={
            activeSpeaker?.videoStream ? (
              <StreamMedia videoStreamElement={activeSpeaker?.videoStream?.renderElement} />
            ) : null
          }
        />
      );
    }
    return (
      <div className="no-active-speaker">
        <Text> {t('NO_ACTIVE_PARTICIPANT_SPEAKING')} </Text>
      </div>
    );
  }
  if (layoutType === LAYOUT_OPTIONS.default) {
    return (
      <VideoGallery
        layout={FLOATING_LOCAL_VIDEO}
        localParticipant={MockLocalParticipant}
        remoteParticipants={remoteParticipantVideo}
        remoteVideoViewOptions={remoteVideoStreamOption}
      />
    );
  }
  if (layoutType === LAYOUT_OPTIONS.sharedScreen) {
    return (
      <VideoTile
        styles={videoTileStyles}
        displayName={screenSharingParticipant?.displayName}
        showMuteIndicator="true"
        isMuted={screenSharingParticipant?.isMuted}
        isSpeaking={screenSharingParticipant?.isSpeaking}
        renderElement={
          <StreamMedia
            videoStreamElement={screenSharingParticipant?.screenShareStream?.renderElement}
          />
        }
      />
    );
  }
  if (layoutType === LAYOUT_OPTIONS.video) {
    return (
      <>
        <VideoTile
          styles={videoTileStyles}
          displayName={screenSharingParticipant?.displayName}
          showMuteIndicator="true"
          isMuted={screenSharingParticipant?.isMuted}
          isSpeaking={screenSharingParticipant?.isSpeaking}
          renderElement={
            screenSharingParticipant?.screenShareStream ? (
              <StreamMedia
                videoStreamElement={screenSharingParticipant?.screenShareStream?.renderElement}
              />
            ) : null
          }
        />
        {activeSpeaker && (
          <VideoTile
            displayName={activeSpeaker?.displayName}
            showMuteIndicator="true"
            isMuted={activeSpeaker?.isMuted}
            styles={activeSpeakerStyles}
            isSpeaking={activeSpeaker?.isSpeaking}
            renderElement={
              activeSpeaker?.videoStream ? (
                <StreamMedia videoStreamElement={activeSpeaker?.videoStream?.renderElement} />
              ) : null
            }
          />
        )}
        {!activeSpeaker && (
          <div className="no-active-speaker">
            <Text> {t('NO_ACTIVE_PARTICIPANT_SPEAKING')} </Text>
          </div>
        )}
      </>
    );
  }
  return null;
};

export default VideoGalleryLayout;
