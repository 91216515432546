import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentThread: null,
  currentThreadMessages: [],
  participantList: {},
  unreadThreads: [],
  groupChat: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    initialSetAllMessages(state, action) {
      state.currentThreadMessages = action.payload;
    },
    addMessageToCurrentThread(state, action) {
      const threadMessages = state.currentThreadMessages;
      threadMessages.push(action.payload);
      state.currentThreadMessages = [...new Set(threadMessages)];
    },
    setOpenThread(state, action) {
      state.currentThread = action.payload;
    },
    notifyUnreadMessages(state, action) {
      if (action.payload) {
        state.unreadThreads.push(action.payload);
      }
    },
    removeUnreadThread(state, action) {
      const newList = state.unreadThreads.filter((threadId) => {
        return threadId !== action.payload;
      });
      state.unreadThreads = newList;
    },
    resetUnreadMessage(state) {
      state.unreadThreads = [];
    },
    setGroupChat(state, action) {
      state.groupChat = action.payload;
    },
    updateParticipantList(state, action) {
      state.participantList = action.payload;
    },
  },
});

export default chatSlice.reducer;

export const chatActions = { ...chatSlice.actions };

// selectors
export const chatSelector = {
  currentThread: (state) => state.chat.currentThread,
  currentThreadMessages: (state) => state.chat.currentThreadMessages,
  unreadThreads: (state) => state.chat.unreadThreads,
  groupChat: (state) => state.chat.groupChat,
  participantList: (state) => state.chat.participantList,
};
