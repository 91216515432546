import { createSlice } from '@reduxjs/toolkit';
import { SERVICE_STATUS } from 'SRC/features/interpreter/constants/index';

const initialState = {
  blockScreen: false,
};

const serviceSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setConnectionStatus(state, action) {
      state.blockScreen = action.payload === SERVICE_STATUS.disconnected;
    },
  },
});

export default serviceSlice.reducer;

export const serviceActions = { ...serviceSlice.actions };

export const selectService = {
  blockScreen: (state) => state.services.blockScreen,
};
