import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ROUTES from 'CONSTANTS/routes';
import RecorderHome from 'FEATURES/recorder/features/Home';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'SRC/features/recorder/features/Authentication/authConfig';
import { MsalProvider } from '@azure/msal-react';
import { setMsalInstance } from 'FEATURES/recorder/features/Authentication/authentication';

const RecorderRouter = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  setMsalInstance(msalInstance);

  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route
            path={`${ROUTES.RECORDER}/join/:meetingId`}
            element={<RecorderHome />}
            key={`Route-${ROUTES.RECORDER}/join/:meetingId`}
          />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  );
};

export default RecorderRouter;
