import { Button } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';
import { selectInterpreter } from 'FEATURES/interpreter/slices/interpreterSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';
import { MIC_STATE, OUTGOING_CALL_TYPE } from 'FEATURES/interpreter/constants';
import { primaryLangMeetingSelector } from '../../LanguageMeeting/primaryLangMeetingSlice';
import { secondaryLangMeetingSelector } from '../../LanguageMeeting/secondaryLangMeetingSlice';

const BoothMeetingButton = () => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });

  const [activeLangCode, setLangCode] = useState('--');
  const [outputLanguageChange, setOutputLanguageChange] = useState(undefined);
  const [isBoothButtonClicked, setIsBoothButtonClicked] = useState(false);

  // Selector
  const micStatus = useSelector(selectInterpreter.micStatus);
  const activeLanguageCode = useSelector(selectInterpreter.activeLanguageCode);
  const activeLanguageStatus = useSelector(selectInterpreter.activeLanguageStatus);
  const primaryLanguageCall = useSelector(primaryLangMeetingSelector.prilangCallClient);
  const secondLanguageCall = useSelector(secondaryLangMeetingSelector.secLangCallClient);

  // If active language code changes, update the language code
  useEffect(() => {
    if (!activeLanguageCode) return;
    setLangCode(activeLanguageCode.language);
  }, [activeLanguageCode]);

  // If mic status changes, update the booth button and mute/unmute the incoming call
  useEffect(() => {
    if (micStatus === MIC_STATE.unmute) {
      (async () => {
        // Mute incoming call when we start speaking
        await primaryLanguageCall?.muteIncomingCall();
        await secondLanguageCall?.muteIncomingCall();
        setIsBoothButtonClicked(false);
        // setLangCode('--');
      })();
    } else if (
      micStatus === MIC_STATE.mute &&
      activeLanguageStatus !== OUTGOING_CALL_TYPE.none &&
      isBoothButtonClicked
    ) {
      if (activeLanguageStatus === OUTGOING_CALL_TYPE.primary) {
        (async () => {
          // Unmute primary incoming call when we stop speaking
          await primaryLanguageCall?.unmuteIncomingCall();
        })();
      } else if (activeLanguageStatus === OUTGOING_CALL_TYPE.secondary) {
        (async () => {
          // Unmute secondary incoming call when we stop speaking
          await secondLanguageCall?.unmuteIncomingCall();
        })();
      }
    }
  }, [micStatus]);

  // If language shift happens then we need to mute/unmute the incoming call
  useEffect(() => {
    if (!outputLanguageChange) return;
    if (isBoothButtonClicked && outputLanguageChange) {
      (async () => {
        if (activeLanguageStatus === OUTGOING_CALL_TYPE.primary && micStatus === MIC_STATE.mute) {
          await secondLanguageCall?.muteIncomingCall();
          await primaryLanguageCall?.unmuteIncomingCall();
        } else if (
          activeLanguageStatus === OUTGOING_CALL_TYPE.secondary &&
          micStatus === MIC_STATE.mute
        ) {
          await primaryLanguageCall?.muteIncomingCall();
          await secondLanguageCall?.unmuteIncomingCall();
        } else if (activeLanguageStatus === OUTGOING_CALL_TYPE.none) {
          // Booth resets the audio
          await primaryLanguageCall?.muteIncomingCall();
          await secondLanguageCall?.muteIncomingCall();
          setIsBoothButtonClicked(false);
          setLangCode('--');
        }
      })();
    }
  }, [outputLanguageChange]);

  if (activeLanguageStatus !== outputLanguageChange) {
    setOutputLanguageChange(activeLanguageStatus);
  }

  return (
    <Button
      className={isBoothButtonClicked ? 'customPrimaryButton' : 'customSecondaryButton'}
      content={activeLangCode}
      title={t('INCOMING_BOOTH_AUDIO')}
      onClick={async () => {
        logger.debug('Booth language clicked');
        if (!isBoothButtonClicked) {
          setIsBoothButtonClicked(true);
          if (activeLanguageStatus) {
            if (activeLanguageStatus === OUTGOING_CALL_TYPE.primary) {
              await secondLanguageCall?.muteIncomingCall();
              await primaryLanguageCall?.unmuteIncomingCall();
            } else if (activeLanguageStatus === OUTGOING_CALL_TYPE.secondary) {
              await primaryLanguageCall?.muteIncomingCall();
              await secondLanguageCall?.unmuteIncomingCall();
            }
          }
        } else {
          await primaryLanguageCall?.muteIncomingCall();
          await secondLanguageCall?.muteIncomingCall();
          setIsBoothButtonClicked(false);
        }
      }}
    />
  );
};

export default BoothMeetingButton;
