import { API_CONTEXT } from 'CONSTANTS/apiConstants';
import serverAPI from 'SERVICES/serverAPI';
import { API } from '../constants';

export const getParticipantMeetingDetails = async (meetingId, userId) => {
  const { data } = await serverAPI.get(
    `${API.meetings}/${meetingId}/${API_CONTEXT.participant}/${userId}`
  );
  return data;
};

export const createParticipant = async () => {
  const { data } = await serverAPI.post(API.createParticipant);
  return data.userId;
};

export const fetchACSAccessToken = async (userId) => {
  const { data } = await serverAPI.post(API.fetchAcsToken, { userId });
  return data.token;
};

export const generateOtpForUser = async (meetingId, email) => {
  const { data } = await serverAPI.post(API.generateOtp, { meetingId, email });
  return data;
};

export const validateOtpForUser = async (meetingId, email, passCode) => {
  const { data } = await serverAPI.post(API.validateOtp, { meetingId, email, passCode });
  return data;
};
