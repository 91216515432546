import { registerIcons } from '@fluentui/react';
import { DEFAULT_COMPONENT_ICONS } from '@azure/communication-react';

import TeamsRouter from 'ROUTES/TeamsRouter';
import InterpreterRouter from 'ROUTES/InterpreterRouter';
import ParticipantRouter from 'ROUTES/ParticipantRouter';
import HomeRouter from 'ROUTES/HomeRouter';
import RecorderRouter from 'ROUTES/RecorderRouter';
import ROUTES from 'CONSTANTS/routes';
import useActions from 'HOOKS/useActions';

import useAppInitializer from 'HOOKS/useAppInitializer';
import ThemeProvider from 'RESOURCES/theme/ThemeProvider';
import { registerErrorCallback } from 'SERVICES/serverAPI';
import { useState, useEffect } from 'react';
import { appActions } from 'STORE/appSlice';
import { AKOUO_LOCAL_STORAGE } from 'CONSTANTS/appConstants';

const App = () => {
  const setErrorTimestamp = useActions(appActions.setErrorTimestamp);
  const path = window.location.pathname;
  const [errorValue, setErrorValue] = useState(undefined);
  const errorCallback = () => {
    setErrorValue(Date.now);
  };

  // Cleaning cache problem AKMSTEAMS-539
  // Most important is that teams right panel uses localstorage
  // While clearing localstorage we cannot use localstorage.clear()
  // As all the interface are sharing same code
  const allKeys = Object.keys(localStorage) || [];
  allKeys.forEach((element) => {
    if (!element.startsWith(AKOUO_LOCAL_STORAGE)) {
      localStorage.removeItem(element);
    }
  });

  // TODO: Remove once stale state problem solved
  useEffect(() => {
    if (errorValue && setErrorTimestamp) {
      setErrorTimestamp();
    }
  }, [errorValue]);

  registerErrorCallback(errorCallback);

  registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

  const { isInitialized } = useAppInitializer();
  if (!isInitialized) return null;

  const getRouter = (pathLink) => {
    if (pathLink.startsWith(ROUTES.INTERPRETER)) {
      return <InterpreterRouter />;
    }
    if (pathLink.startsWith(ROUTES.PARTICIPANT)) {
      return <ParticipantRouter />;
    }
    if (pathLink.startsWith(ROUTES.RECORDER)) {
      return <RecorderRouter />;
    }
    return <TeamsRouter />;
  };

  return (
    <ThemeProvider>
      {getRouter(path)}
      <HomeRouter />
    </ThemeProvider>
  );
};

export default App;
