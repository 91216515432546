/* eslint-disable no-restricted-syntax */
import logger from 'SERVICES/logger';
import { ChatClient } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { SOCKET_RESPONSE } from 'SRC/socket/socketConstant';
import ChatThread from './chatThread';

class ACSChatClient {
  static THREAD_CREATED = 'chatThreadCreated';

  static THREAD_DELETED = 'chatThreadDeleted';

  static CHAT_MESSAGE_RECEIVED = 'chatMessageReceived';

  static PARTICIPANT_ADDED = 'participantsAdded';

  listeners = new Map();

  threadCreatedListener;

  threadDeletedListener;

  messageReceivedListener;

  participantAddedListener;

  cbUserJoinedMeeting = async (data) => {
    logger.debug('acsChatClient', '_userJoinedMeeting', data);
    logger.debug('[THREAD-FLOW] (Chatclient) Creating chat thread for new user', data);
    try {
      await this._createChatThread({
        participants: [
          { id: data.userId, name: data.name },
          { id: this.config.acsId, name: this.config.userName },
        ],
      });
    } catch (err) {
      logger.error('Error: while creating one to one thread', err);
      throw err;
    }
  };

  async init(config) {
    this.config = config;
    logger.debug('[THREAD-FLOW] (Chatclient) Initializing chatClient SDK', this.listeners);

    this.socketClient = config.socketClient;

    try {
      logger.debug('[THREAD-FLOW] (Chatclient) Creating chat client SDK');
      this.chatClient = new ChatClient(
        this.config.endpointUrl,
        new AzureCommunicationTokenCredential(this.config.userAccessToken)
      );
      // To start receiving the real time notification we have to call below method
      await this.chatClient.startRealtimeNotifications();
      logger.debug('[THREAD-FLOW] (Chatclient) Chat client created  SDK & started notification');
      this.threadCreatedListener = async (e) => {
        const dataOfThread = {
          newlyCreatedThreadId: e.threadId,
        };
        logger.debug('[THREAD-FLOW] (Chatclient) chat thread created event SDK.', e);

        logger.debug('[THREAD-FLOW] (Chatclient) listeners are with us', this.listeners);
        await this._notify(ACSChatClient.THREAD_CREATED, dataOfThread);
      };
      this.chatClient.on(ACSChatClient.THREAD_CREATED, this.threadCreatedListener);
      logger.debug(
        '[THREAD-FLOW] (Chatclient) Subscribed THREAD_CREATED event of threads  SDK',
        this.threadCreatedListener
      );
      this.threadDeletedListener = async (e) => {
        logger.debug('[THREAD-FLOW] (Chatclient) chat thread deleted SDK.', e);
        logger.debug('[THREAD-FLOW] (Chatclient) listeners are with us', this.listeners);
        await this._notify(ACSChatClient.THREAD_DELETED, e);
      };
      this.chatClient.on(ACSChatClient.THREAD_DELETED, this.threadDeletedListener);
      logger.debug(
        '[THREAD-FLOW] (Chatclient) Subscribed THREAD_DELETED event of threads  SDK',
        this.threadDeletedListener
      );
      this.messageReceivedListener = async (e) => {
        logger.debug('[THREAD-FLOW] (Chatclient) chat message received event SDK.', e);
        logger.debug('[THREAD-FLOW] (Chatclient) listeners are with us', this.listeners);
        await this._notify(ACSChatClient.CHAT_MESSAGE_RECEIVED, e);
      };
      this.chatClient.on(ACSChatClient.CHAT_MESSAGE_RECEIVED, this.messageReceivedListener);
      logger.debug(
        '[THREAD-FLOW] (Chatclient) Subscribed CHAT_MESSAGE_RECEIVED event of threads  SDK',
        this.messageReceivedListener
      );

      this.participantAddedListener = async (e) => {
        logger.debug('[THREAD-FLOW] (Chatclient) chat thread added event SDK.', e);
        logger.debug('[THREAD-FLOW] (Chatclient) listeners are with us', this.listeners);

        // Only fired this event if we are getting added into group-chat-thread
        if (e.participantsAdded[0].id.communicationUserId === this.config?.acsId) {
          const dataOfThread = {
            newlyCreatedThreadId: e.threadId,
          };
          await this._notify(ACSChatClient.THREAD_CREATED, dataOfThread);
        }
      };
      this.chatClient.on(ACSChatClient.PARTICIPANT_ADDED, this.participantAddedListener);
      logger.debug(
        '[THREAD-FLOW] (Chatclient) Subscribed PARTICIPANT_ADDED event of threads  SDK',
        this.participantAddedListener
      );
      this.chatClient.on('realTimeNotificationConnected', async (e) => {
        logger.debug('[THREAD-FLOW] (Chatclient) realTimeNotificationConnected SDK', e);
      });
      this.chatClient.on('realTimeNotificationDisconnected', async (e) => {
        logger.debug('[THREAD-FLOW] (Chatclient) realTimeNotificationDisconnected SDK', e);
      });
    } catch (err) {
      logger.error('Error: While creating chatClient', err);
      this.cleanup();
      throw err;
    }

    try {
      // Newly added WS-event to get notify for other interpreter join meeting
      // Here we create chat-thread for that user
      this.socketClient.subscribe(SOCKET_RESPONSE.userJoinedMeeting, this.cbUserJoinedMeeting);
    } catch (err) {
      logger.error('Error: While operating socket functionality', err);
      throw err;
    }
  }

  async cleanup() {
    logger.info('cleanup');
    // unregister socket events
    this.socketClient.unsubscribe(SOCKET_RESPONSE.userJoinedMeeting, this.cbUserJoinedMeeting);
    this.socketClient = null;

    this.chatClient.off(this.THREAD_CREATED, this.threadCreatedListener);
    this.chatClient.off(this.THREAD_DELETED, this.threadDeletedListener);
    this.chatClient.off(this.CHAT_MESSAGE_RECEIVED, this.messageReceivedListener);
    this.chatClient.off(this.PARTICIPANT_ADDED, this.participantAddedListener);
    await this.chatClient.stopRealtimeNotifications();
    this.config = null;
    this.chatClient = null;
    logger.debug('[THREAD-FLOW] (Chatclient) Calling cleanup ');

    // clear any event listeners
    this.listeners.clear();
  }

  async _deleteThread(threadList, data) {
    const oneOnOneThreads = threadList.filter((chatThread) => !chatThread.isGroupChat);
    if (oneOnOneThreads) {
      // As we are dealing with async data, we used for with await
      for await (const chatThread of oneOnOneThreads) {
        try {
          const participants = await chatThread.getAllParticipants();
          for await (const participantInfo of participants) {
            // Need to delete chat-thread for person who left meeting
            if (participantInfo.id === data.userId) {
              await this._deleteChatThread(chatThread.threadId);
            }
          }
        } catch (err) {
          // DISCONNECTION CASE ERROR
          logger.error('Error: While fetching participant list when we remove user', err);
          throw err;
        }
      }
    }
  }

  async _createChatThread(chatThreadRequest, isGroupChat = false) {
    // logger.debug('createChatThread', chatThreadRequest, isGroupChat);
    logger.debug('[THREAD-FLOW] (Chatclient) create chat thread request isGroup = ', isGroupChat);
    if (chatThreadRequest.participants?.length === 0) {
      throw new Error('Participant has to be there');
    }
    const threadMember = chatThreadRequest.participants.map((element) => {
      return {
        id: {
          communicationUserId: element.id,
        },
        displayName: element.name,
      };
    });

    const chatRequest = {
      topic: isGroupChat ? ChatThread.TOPIC.group : ChatThread.TOPIC.oneOnOne,
      participants: threadMember,
    };

    let chatThread = null;
    if (this.chatClient) {
      chatThread = new ChatThread();
      await chatThread.init(this.chatClient, chatRequest, isGroupChat);
    }
    logger.debug('[THREAD-FLOW] (Chatclient) chat thread created.', chatThread);
    return chatThread;
  }

  async _deleteChatThread(threadId) {
    // logger.debug('deleteChatThread', threadId);
    try {
      await this.chatClient.deleteChatThread(threadId);
    } catch (err) {
      logger.error('Error: While deleting the chatThread', threadId, err);
      throw err;
    }
  }

  /**
   * This method will be used to subscribe for listener
   * @param {string} eventName
   * @param {function} callback
   * @returns string {subscriberId}
   */
  subscribe(eventName, callback) {
    try {
      let listenerForEvent = this.listeners.get(eventName);
      if (!listenerForEvent) {
        listenerForEvent = [];
      }
      // We used randomId to give unique identifier for subscriberID
      const randomId = (Math.random() + 1).toString(36).substring(2);
      listenerForEvent.push({
        id: randomId,
        callback,
      });
      this.listeners.set(eventName, listenerForEvent);

      logger.debug('[THREAD-FLOW] (Chatclient) Thread Subscriber:', eventName, this.listeners);
      return randomId;
    } catch (err) {
      logger.error('Error: While subscribing event', eventName, err);
      throw err;
    }
  }

  /**
   * This method will be used to unsubscribe listener
   * @param {string} subscribeId
   * @returns void
   */
  unsubscribe(subscribeId) {
    logger.debug('[THREAD-FLOW] (Chatclient) unsubscribe:', subscribeId);
    try {
      for (const [key, valueOfListener] of this.listeners) {
        const ifExist = valueOfListener.findIndex((ele) => ele.id === subscribeId);
        if (ifExist !== -1) {
          valueOfListener.splice(ifExist, 1);
          this.listeners.set(key, valueOfListener);
          return;
        }
      }
    } catch (err) {
      logger.error('Error: While unsubscribing event', subscribeId);
      throw err;
    }
    logger.debug('[THREAD-FLOW] (Chatclient) REmaining listeners:', this.listeners);
  }

  async _notify(eventName, eventData) {
    try {
      const listenerForEvent = this.listeners.get(eventName);
      logger.debug('[THREAD-FLOW] (Chatclient) _notify:', eventName, eventData, this.listeners);
      logger.debug('[THREAD-FLOW] (Chatclient) listenerForEvent:', listenerForEvent);
      let dataToSend;
      switch (eventName) {
        case ACSChatClient.THREAD_CREATED:
          dataToSend = await this.getChatThreadById(eventData.newlyCreatedThreadId);
          break;
        case ACSChatClient.THREAD_DELETED:
          dataToSend = {
            deletedThreadId: eventData.threadId,
          };
          break;
        case ACSChatClient.CHAT_MESSAGE_RECEIVED:
          dataToSend = {
            id: eventData.id,
            content: eventData.message,
            senderDisplayName: eventData.senderDisplayName,
            createdOn: eventData.createdOn,
            senderId: eventData.sender.communicationUserId,
            recipientId: eventData.recipient.communicationUserId,
            threadId: eventData.threadId,
          };
          break;
        default:
          break;
      }
      if (listenerForEvent) {
        listenerForEvent.forEach((callbackObj) => {
          logger.debug('[THREAD-FLOW] (Chatclient) _notify:', callbackObj, dataToSend);

          callbackObj.callback(dataToSend);
        });
      }
    } catch (err) {
      logger.error('Error: While executing listener', err);
      throw err;
    }
  }

  /**
   * This method will be used to get all chat-threads
   * @returns list of chat-threads
   */
  async getAllChatThreads() {
    // logger.debug('getAllChatThreads');
    const allThreadDetails = this.chatClient?.listChatThreads();
    if (allThreadDetails) {
      try {
        const allThreadList = [];
        // As we are dealing with async data, we used for with await
        for await (const thread of allThreadDetails) {
          if (thread.deletedOn) {
            // logger.debug(thread.id, ' deleted.');
          } else {
            const chatThread = new ChatThread();
            await chatThread.updateThreadDetails(this.chatClient, thread.id, this.config?.acsId);
            allThreadList.push(chatThread);
          }
        }
        // TODO: remove this log once bug is fixed
        // BUG: AKMSTEAMS-213
        logger.debug('SDK returns', allThreadList);
        logger.debug(
          '[THREAD-FLOW] (Chatclient) result obtained from getAllChatThreads SDK:',
          allThreadList
        );

        return allThreadList;
      } catch (err) {
        // DISCONNECTION CASE ERROR
        logger.error('Error: While getting thread details', err);
        throw err;
      }
    }

    // This will return[] if chat-client was not proper
    // Hence it will show empty on UI
    return [];
  }

  /**
   * This method will return a thread for given thread-id
   * @param {string} threadId
   * @returns
   */
  async getChatThreadById(threadId) {
    const groupChatThread = new ChatThread();
    await groupChatThread.updateThreadDetails(this.chatClient, threadId, this.config?.acsId);

    return groupChatThread;
  }

  async dashboardHandleGetAllChatThreads(data) {
    const threadList = await this.getAllChatThreads();
    // Remove one-on-one chat therad
    logger.debug('[THREAD-FLOW] (Chatclient) Deleting chat thread:', data);
    await this._deleteThread(threadList, data);
  }
}

export default ACSChatClient;
