const prefix = 'akouo-language-meeting-';
const meetingIdKey = `${prefix}meetingId`;
const languageKey = `${prefix}language`;
const userIdKey = `${prefix}userId`;

const meetingLocalStorage = {
  save(meetingId, userId, language) {
    localStorage.setItem(meetingIdKey, String(meetingId));
    localStorage.setItem(languageKey, String(language));
    localStorage.setItem(userIdKey, String(userId));
  },
  get(meetingId, userId) {
    const storedMeetingId = localStorage.getItem(meetingIdKey);
    const storedUserId = localStorage.getItem(userIdKey);
    if (storedUserId === String(userId) && storedMeetingId === String(meetingId)) {
      return localStorage.getItem(languageKey);
    }
    return null;
  },
  remove() {
    localStorage.removeItem(meetingIdKey);
    localStorage.removeItem(languageKey);
    localStorage.removeItem(userIdKey);
  },
};

export default meetingLocalStorage;
