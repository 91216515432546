import useActions from 'HOOKS/useActions';
import { primaryLangMeetingActions as actions } from './primaryLangMeetingSlice';

const useLangDeviceManager = () => {
  const [setSelectedSpeakerAction, setSelectedMicrophoneAction] = useActions([
    actions.setSelectedSpeaker,
    actions.setSelectedMicrophone,
  ]);

  return {
    setSelectedSpeaker: (speaker) => setSelectedSpeakerAction(speaker),
    setSelectedMicrophone: (microphone) => setSelectedMicrophoneAction(microphone),
  };
};

export default useLangDeviceManager;
