import React, { useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

import localCache from 'UTILS/localCache';
import { CONSENT_STATE_KEY } from 'FEATURES/teams/constants';

const ClosePopup = () => {
  // Helper function that converts window.location.hash into a dictionary
  const getHashParameters = () => {
    const hashParams = {};
    window.location.hash
      .substr(1)
      .split('&')
      .forEach((item) => {
        const [key, value] = item.split('=');
        hashParams[key] = decodeURIComponent(value);
      });
    return hashParams;
  };

  useEffect(() => {
    microsoftTeams.initialize(() => {
      const hashParams = getHashParameters();
      const consentState = localCache.getItem(CONSENT_STATE_KEY);
      localCache.removeItem(CONSENT_STATE_KEY);

      if (hashParams?.state !== consentState) {
        microsoftTeams.authentication.notifyFailure('State verification failed');
        return;
      }

      if (hashParams.code) {
        // Notify useThemesAuth that authorization succeeded.
        microsoftTeams.authentication.notifySuccess(hashParams);
      } else {
        microsoftTeams.authentication.notifyFailure(hashParams);
      }
    });
  }, []);

  return (
    <div>
      <h1>Consent flow complete.</h1>
    </div>
  );
};
export default ClosePopup;
