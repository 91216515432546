import PropTypes from 'prop-types';
import { useTranslation } from 'SERVICES/i18n';
import { Text } from '@fluentui/react-northstar';
import { AKOUO_SUPPORT_URL } from 'CONSTANTS/appConstants';
import HyperLink from './HyperLink';

const AkouoSupport = ({ size, align, className }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'COMMON' });
  return (
    <Text size={size} align={align} className={className}>
      {t('NEED_ASSISTANCE')}
      <HyperLink link={AKOUO_SUPPORT_URL} />
    </Text>
  );
};

AkouoSupport.propTypes = {
  size: PropTypes.string,
  align: PropTypes.string,
  className: PropTypes.string,
};

AkouoSupport.defaultProps = {
  size: undefined,
  align: undefined,
  className: undefined,
};

export default AkouoSupport;
