export const SOCKET_RESPONSE = Object.freeze({
  joinAck: '_join',
  updateBoothAck: '_updateBooth',
  userJoinedMeeting: '_userJoinedMeeting',
  updateActiveLanguageAck: '_updateActiveLanguage',
  handOverAck: '_handOver',
  takeOverAck: '_takeOver',
  unmuteAck: '_unmute',
  muteAck: '_mute',
  disconnect: 'disconnect',
  userLeftBooth: '_userLeftBooth',
  userJoinedBooth: '_userJoinedBooth',
  activeLanguageUpdated: '_activeLanguageUpdated',
  userHandedOver: '_userHandedOver',
  userTookOver: '_userTookOver',
  userUnmuted: '_userUnmuted',
  userMuted: '_userMuted',
  userLeftMeeting: '_userLeftMeeting',
  connectError: 'connect_error',
  connect: 'connect',
  reconnecting: 'reconnect_attempt',
});

export const SOCKET_REQUEST = Object.freeze({
  join: 'join',
  updateBooth: 'updateBooth',
  updateActiveLanguage: 'updateActiveLanguage',
  handOver: 'handOver',
  takeOver: 'takeOver',
  mute: 'mute',
  unmute: 'unmute',
  disconnect: 'disconnect',
});

export const SOCKET_ERROR = Object.freeze({
  explicitDisconnectByServer: 'io server disconnect',
  explicitDisconnectByClient: 'io client disconnect',
});
