import { configureStore } from '@reduxjs/toolkit';
import { teamsMeetingSlice } from 'FEATURES/teams';
import {
  interpreterSlice,
  floorMeetingSlice,
  testCallSlice,
  landingPageSlice,
} from 'FEATURES/interpreter';
import primaryLangMeetingSlice from 'FEATURES/interpreter/features/LanguageMeeting/primaryLangMeetingSlice';
import secondaryLangMeetingSlice from 'FEATURES/interpreter/features/LanguageMeeting/secondaryLangMeetingSlice';
import relayMeetingSlice from 'FEATURES/interpreter/features/RelayMeeting/relayMeetingSlice';
import chatSlice from 'FEATURES/interpreter/slices/chatSlice';
import serviceSlice from './serviceSlice';

import appSlice from './appSlice';

// ConfigureStore function internally sets up the thunk middleware
const store = configureStore({
  reducer: {
    app: appSlice,
    services: serviceSlice,
    teamsMeeting: teamsMeetingSlice,
    interpreter: interpreterSlice,
    floorMeeting: floorMeetingSlice,
    primaryLangMeeting: primaryLangMeetingSlice,
    secondaryLangMeeting: secondaryLangMeetingSlice,
    relayMeeting: relayMeetingSlice,
    chat: chatSlice,
    testCall: testCallSlice,
    landingPage: landingPageSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
