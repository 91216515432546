import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import logger from 'SERVICES/logger';
import useActions from 'HOOKS/useActions';
import ACSCallClient from 'SERVICES/callClient/index';
import { fetchACSAccessToken } from 'FEATURES/interpreter/api/api';
import { selectInterpreter } from 'FEATURES/interpreter/slices/interpreterSlice';
import { getAudioElements } from 'FEATURES/interpreter/utils/audioElementUtils';
import { MEETING } from 'FEATURES/interpreter/constants';
import { CALL_END_REASON } from 'FEATURES/participant/constants';
import { floorMeetingActions, floorMeetingSelector as selector } from '../floorMeetingSlice';

const useFloorMeeting = () => {
  const selectedSpeaker = useSelector(selector.selectedSpeaker);
  const floorMeeting = useSelector(selectInterpreter.floorMeeting);
  const floorCallClient = useSelector(selector.floorCallClient);
  // TODO: check redux state and ref issue for dispose() and hangup() issue;
  const acsCallObjectRef = useRef();

  // For removing interpreter from meeting
  const [callEnded, setCallEnded] = useState(undefined);
  const { STATE_CHANGED, CALL_UPDATED } = ACSCallClient;
  const {
    setFloorCallClientObject,
    callAdded,
    setCallState,
    setIncomingAudioElement,
    setDeviceManager,
    setSelectedSpeaker,
  } = useActions(floorMeetingActions);

  useEffect(() => {
    acsCallObjectRef.current = floorCallClient;
  }, [floorCallClient]);

  const subscribeToDeviceManager = async (callClientObj) => {
    const deviceManager = await callClientObj.getACSDeviceManager();
    await deviceManager.askAudioDevicePermission();
    setDeviceManager(deviceManager);

    if (selectedSpeaker) {
      await deviceManager.setSpeakerDevice(selectedSpeaker);
    } else {
      setSelectedSpeaker(deviceManager.getSelectedSpeaker());
    }
    deviceManager.onSelectedSpeakerChanged((speaker) => setSelectedSpeaker(speaker));
  };

  const joinFloorMeeting = async () => {
    try {
      logger.info('Joining Floor Meeting');
      const token = await fetchACSAccessToken();
      const config = {
        acsToken: token,
      };
      const callClientObj = new ACSCallClient();
      await callClientObj.setupCallTeamsCall(config);
      setFloorCallClientObject(callClientObj);
      // subscribe to device manager
      await subscribeToDeviceManager(callClientObj);
      callClientObj.subscribe(CALL_UPDATED, (addedCall) => {
        callAdded(addedCall);
        addedCall?.on(STATE_CHANGED, () => {
          setCallState(addedCall.state);
          if (CALL_END_REASON[addedCall.callEndReason?.subCode]) {
            logger.debug('Meeting ended.');
            setCallEnded(CALL_END_REASON[addedCall.callEndReason?.subCode]);
          }
        });
        setCallState(addedCall?.state);
      });
      // join call
      callClientObj.joinMeeting({ meetingLink: floorMeeting.joinUrl });
      const teamMeetingAudioTag = await getAudioElements(MEETING.teamMeeting);
      logger.debug([teamMeetingAudioTag], 'Team Meeting Audio Tag');
      setIncomingAudioElement(teamMeetingAudioTag);
    } catch (error) {
      logger.error('Error while joining Floor meeting', error);
      throw error;
    }
  };

  const hangUpFloorMeeting = async () => {
    try {
      logger.info('Hangup Floor Meeting');
      await acsCallObjectRef?.current?.hangupCall();
      callAdded(null);
    } catch (error) {
      logger.error('Error while hangup Floor meeting', error);
      throw error;
    }
  };

  const disposeFloorMeeting = async () => {
    try {
      logger.debug('Disposing floorMeeting call Agent');
      await acsCallObjectRef?.current?.disposeCall();
    } catch (error) {
      logger.error('Error while disposing Floor meeting', error);
      throw error;
    }
  };

  return {
    joinFloorMeeting,
    hangUpFloorMeeting,
    disposeFloorMeeting,
    callEnded,
  };
};

export default useFloorMeeting;
