import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { useAzureCommunicationCallAdapter } from '@azure/communication-react';
import { useMemo, useEffect, useState } from 'react';
import logger from 'SERVICES/logger';
import { checkAndUpdateAudioElement } from 'SERVICES/audioElementUtils';
import { CALL_ADAPTER_STATE, CALL_TYPE } from 'FEATURES/participant/constants';

const LanguageCall = ({
  languageMeetingInfo,
  setLanguageLoading,
  callDisconnected,
  languageSelectionErrorHandling,
  noActiveSpeakerHandler,
}) => {
  // Credentials must be memoised
  const credential = useMemo(
    () => new AzureCommunicationTokenCredential(languageMeetingInfo.accessToken),
    [languageMeetingInfo.accessToken]
  );

  const [adapterState, setAdapterState] = useState(null);

  // Locator must be memoised
  const locator = useMemo(() => {
    return { groupId: languageMeetingInfo.languageCallId };
  }, [languageMeetingInfo.languageCallId]);

  // User id must be memoised
  const userIdMemo = useMemo(() => {
    return { communicationUserId: languageMeetingInfo.userId };
  }, [languageMeetingInfo.userId]);

  // For composite to work we need to create adapter
  // Remember locator & credential must be memoised
  const adapter = useAzureCommunicationCallAdapter(
    {
      userId: userIdMemo,
      displayName: languageMeetingInfo.displayName,
      credential,
      locator,
    },
    undefined,
    undefined
  );

  /**
   *
   * @param {[]} allParticipants: List of all participants
   */
  const updateSpeakerAudioState = (allParticipants) => {
    // Checking here that if all the participant are muted, then we need to listen floor call
    if (allParticipants) {
      logger.debug(
        'Active interpreter: ',
        allParticipants.some((user) => !user.isMuted)
      );
      noActiveSpeakerHandler(allParticipants.some((user) => !user.isMuted));
    }
  };

  useEffect(() => {
    if (adapterState?.call?.state === CALL_ADAPTER_STATE.CONNECTED) {
      setLanguageLoading(false);
      // Moving code here as we need to set audio tag once we are connected to call
      // AKMSTEAMS-315
      checkAndUpdateAudioElement(CALL_TYPE.LANGUAGE);
      logger.debug('Setting audio element');
      adapter.offStateChange(setAdapterState);
    }
  }, [adapterState]);

  // Added this useEffect to run only when we change langauge from dropdown
  useEffect(() => {
    if (adapter) {
      // If this adapter don't have any active call, then we need to join language call
      // Sometime due to async behaviour, if we change language then we get old language call
      if (!adapter.getState().call) {
        // Maintaining all the participant locally to save the mute status
        const allParticipants = [];

        try {
          // Joining language call
          // AKMSTEAMS-544
          adapter.joinCall({ microphoneOn: false });
          adapter.onStateChange(setAdapterState);

          // Unlike normal ACS callclient we don't have event for participant hence the following code
          // Listening to mute status to maintain data of all participant
          adapter.on(CALL_ADAPTER_STATE.IS_PARTICIPANT_MUTED_CHANGED, (data) => {
            logger.debug('MUTED', data);
            // Using findIndex as we need to update reference with the state
            const participantIndex = allParticipants.findIndex(
              (element) => element.id === data.participantId.communicationUserId
            );
            if (participantIndex !== -1) {
              const element = allParticipants[participantIndex];
              element.isMuted = data.isMuted;
              allParticipants.splice(participantIndex, 1, element);
              updateSpeakerAudioState(allParticipants);
            } else if (allParticipants.length === 0) {
              // Joining new call and no one is there
              updateSpeakerAudioState(allParticipants);
            }
          });

          // Listening to participant join to get information about participant
          adapter.on(CALL_ADAPTER_STATE.PARTICIPANT_JOINED, (data) => {
            try {
              logger.debug('JOINING', data);
              const joinedParticipant = data.joined[0];

              allParticipants.push({
                id: joinedParticipant.identifier.communicationUserId,
                isMuted: joinedParticipant.isMuted,
              });
              updateSpeakerAudioState(allParticipants);
            } catch (error) {
              logger.log(error);
            }
          });

          // Added this code for handling tab close case
          adapter.on(CALL_ADAPTER_STATE.PARTICIPANT_LEFT, (data) => {
            logger.debug('Left', data);
            const removedParticipant = data.removed[0];
            const filteredData = allParticipants.filter(
              (participant) => participant.id !== removedParticipant.identifier.communicationUserId
            );
            updateSpeakerAudioState(filteredData);
          });
        } catch (error) {
          logger.error(error);
          languageSelectionErrorHandling();
        }
      }
    }
  }, [adapter]);

  useEffect(() => {
    if (adapter && callDisconnected) {
      adapter.leaveCall(false); // false -> other users will not be removed
    }
  }, [callDisconnected]);

  return null;
};

export default LanguageCall;
