import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  call: undefined,
  callState: undefined,
  deviceManager: undefined,
  selectedSpeaker: undefined,
  incomingAudioElement: null,
  floorCallClient: null,
};

const floorMeetingSlice = createSlice({
  name: 'floorMeeting',
  initialState,
  reducers: {
    callAdded(state, action) {
      state.call = action.payload;
    },
    setCallState(state, action) {
      state.callState = action.payload;
    },
    setIncomingAudioElement(state, action) {
      state.incomingAudioElement = action.payload;
    },
    setDeviceManager(state, action) {
      state.deviceManager = action.payload;
    },
    setSelectedSpeaker(state, action) {
      state.selectedSpeaker = action.payload;
    },
    setFloorCallClientObject(state, action) {
      state.floorCallClient = action.payload;
    },
  },
});

export default floorMeetingSlice.reducer;

export const floorMeetingActions = { ...floorMeetingSlice.actions };

export const floorMeetingSelector = {
  call: (store) => store.floorMeeting.call,
  callState: (store) => store.floorMeeting.callState,
  deviceManager: (store) => store.floorMeeting.deviceManager,
  selectedSpeaker: (store) => store.floorMeeting.selectedSpeaker,
  incomingAudioElement: (store) => store.floorMeeting.incomingAudioElement,
  floorCallClient: (store) => store.floorMeeting.floorCallClient,
};
