import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@fluentui/react-northstar';

import './OverlayLoader.scss';

const OverlayLoader = ({ label }) => {
  return (
    <div className="overlay-loader">
      <Loader label={{ content: label, align: 'center' }} />
    </div>
  );
};

OverlayLoader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default OverlayLoader;
