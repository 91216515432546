import { useEffect, useState } from 'react';
import { checkInTeams, getTeamsContext } from '../utils/teamsUtils';

const useTeams = () => {
  const [context, setContext] = useState();
  const [inTeams, setInTeams] = useState(undefined);

  const initializeTeams = async () => {
    const teamContext = await getTeamsContext();
    setInTeams(true);
    setContext(teamContext);
  };

  useEffect(() => {
    const isInTeams = checkInTeams();
    if (isInTeams) {
      initializeTeams();
    } else {
      setInTeams(false);
    }
  }, []);
  return { context, inTeams };
};

export default useTeams;
