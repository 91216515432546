export const API_URL = process.env.REACT_APP_API_URL;

export const AUDIT_LOGGING_API = '/log';

export const AXIOS_CONSTANTS = {
  REQUEST_HEADERS: {
    AUTH_TOKEN_KEY: 'Authorization',
    GRAPH_TOKEN_KEY: 'Authorization',
    CONTENT_TYPE_JSON: 'application/json',
  },
  REQUEST_TIMEOUT: 30000,
};

export const STATUS_CODE = Object.freeze({
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED_USER: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  PAYLOAD_TOO_LARGE: 413,

  // App Error
  SOMETHING_WENT_WRONG: 6000,
  NETWORK_NOT_REACHABLE: 6001,
  ERROR_SETTING_UP_REQUEST: 6002,
});

export const ERROR = Object.freeze({
  TOKEN_MISSING_OR_INVALID: 1001,
  MEETING_NOT_FOUND: 1003,
  INVALID_MEETING_REQUEST: 1008,
  INVALID_MEETING_LANG_LIST: 1009,
  FEATURE_NOT_ENABLED: 1012,
  SOMETHING_WENT_WRONG: 1004,
  USER_IS_NOT_ELIGIBLE: 1016,
  OTP_IS_INVALID: 1015,
  OTP_IS_EXPIRED: 1013,
  OTP_IS_MISMATCH: 1014,
});

export const AUDIT_EVENT_TYPE = Object.freeze({
  interpreterLogout: 'Interpreter Interface Logout',
  uaLogin: 'UA Participant Login',
  uaLogout: 'UA Participant Logout',
  msTeamsLogin: 'MS Teams Login',
  recorderLogout: 'Recorder Interface Logout',
});

export const API_CONTEXT = Object.freeze({
  interpreter: 'INTERPRETER',
  participant: 'AUA',
  recorder: 'RECORDER',
  teams: 'TEAMS',
  websocketInterpreter: 'INTERPRETER-WS',
});
