import { BrowserTypes } from 'react-device-detect';

export const API = {
  meetings: '/interpreter/meetings',
  fetchInterpreterDetails: '/interpreter/identity',
  fetchAcsToken: '/interpreter/identity/access-token',
  createInterpreterIdentity: '/interpreter/identity',
  updateCallDetails: '/interpreter/identity/updateCallDetails',
  updateTeamsCallId: '/interpreter/meetings/updateTeamsCallId',
};

export const AUDIO_STREAM_NAME = 'streamName';

export const ECHO_BOT_ID = '8:echo123';

export const MEETING = Object.freeze({
  teamMeeting: 'TeamMeeting',
  primaryLanguageMeeting: 'PrimaryLanguageMeeting',
  secondaryLanguageMeeting: 'SecondaryLanguageMeeting',
  relayMeeting: 'RelayMeeting',
  primaryRecordingLanguageMeeting: 'PrimaryRecordingLanguageMeeting',
  secondaryRecordingLanguageMeeting: 'SecondaryRecordingLanguageMeeting',
});

export const RELAY_INCOMING_AUDIO = 'relayMeetingAudio';
export const FLOOR_INCOMING_AUDIO = 'floorMeetingAudio';

export const RELAY_MEETING_DISPLAY_NAME = 'Akouo Interpretation (Relay meeting)';
export const LANGUAGE_MEETING_DISPLAY_NAME = 'Akouo Interpretation (Language meeting)';
export const LANGUAGE_RECORDING_MEETING_DISPLAY_NAME =
  'Akouo Interpretation (Language Recording meeting)';
export const SEC_LANGUAGE_MEETING_DISPLAY_NAME =
  'Akouo Interpretation (Secondary Language meeting)';
export const SEC_LANGUAGE_RECORDING_MEETING_DISPLAY_NAME =
  'Akouo Interpretation (Secondary Language Recording meeting)';

export const SELECTED_LANGUAGE_SOCKET_ID = 'selectedLanguageSocketId';

export const SPACE_KEYWORD = 'Space';
export const SPLITBUTTON_LANG_NONE = '--';

export const AUDIO_KEYWORD = 'audio';

export const TIMEOUT_FOR_CALL_ID = 5000;

export const INCOMING_CALL_TYPE = {
  relay: 'relay',
  floor: 'floor',
  booth: 'booth',
};

export const MIC_STATE = {
  mute: 'mute',
  unmute: 'unmute',
  disable: 'disable',
};
export const OUTGOING_CALL_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
  none: 'none',
};

export const HANDOVER_BUTTON_STATE = {
  disabled: 'disable',
  enabled: 'rest',
  active: 'request',
};

export const VIDEO_GALLERY = {
  connected: 'Connected',
  stateChanged: 'stateChanged',
  screenSharing: 'ScreenSharing',
  videoStreamsUpdated: 'videoStreamsUpdated',
  isAvailableChanged: 'isAvailableChanged',
  remoteParticipantsUpdated: 'remoteParticipantsUpdated',
  displayNameChanged: 'displayNameChanged',
  isSpeakingChanged: 'isSpeakingChanged',
  isMutedChanged: 'isMutedChanged',
  optimalVideoCountChanged: 'optimalVideoCountChanged',
};

export const CALL_STREAM_TYPE = Object.freeze({
  VIDEO: 'videoStream',
  SCREEN: 'screenShareStream',
});

export const PARTICIPANT_EVENT_CHANGE = {
  nameChanged: 'nameChanged',
  muteChanged: 'muteChanged',
  speakingChanged: 'speakingChanged',
  stateChanged: 'stateChanged',
  screenShareChanged: 'screenShareChanged',
  videoChanged: 'videoChanged',
};

export const SERVICE_STATUS = {
  connected: 'connected',
  disconnected: 'disconnected',
  error: 'error',
};

export const SERVICES = {
  socket: 'socket',
  chat: 'chat',
  floorCall: 'floorCall',
  relayCall: 'relayCall',
  outGoingPrimaryCall: 'outGoingPrimaryCall',
  outGoingSecondaryCall: 'outGoingSecondaryCall',
};

export const DESKTOP_SUPPORTED_BROWSER = [BrowserTypes.Chrome];

export const FLOATING_LOCAL_VIDEO = 'floatingLocalVideo';

export const LAYOUT_OPTIONS = Object.freeze({
  default: 'DEFAULT',
  activeSpeaker: 'ACTIVE',
  sharedScreen: 'SCREEN-SHARE',
  video: 'VIDEO',
});

export const LAYOUT_DIRECTION = Object.freeze({
  previous: 'previous',
  next: 'next',
});
