import { useTranslation } from 'SERVICES/i18n';
import { Text, Flex } from '@fluentui/react-northstar';

import AkouoSupport from 'COMPONENTS/common/AkouoSupport';
import TabFooter from 'FEATURES/teams/components/TabFooter';
import FullScreenLoader from 'COMPONENTS/common/FullScreenLoader';
import MicrosoftSignInButton from 'COMPONENTS/common/MicrosoftSignInButton';

import './Tab.scss';
import useTab from './useTab';
import PreMeeting from '../PreMeeting';
import InMeeting from '../InMeeting';

const Tab = () => {
  const { frameContext, loading, isAuthorized, authenticate } = useTab();
  const { t } = useTranslation('teams', { keyPrefix: 'TAB' });

  if (loading) return <FullScreenLoader label={t('LOADING_TEAMS_ACCOUNT')} />;

  if (!isAuthorized && frameContext) {
    return (
      <Flex fill hAlign="center" vAlign="center" column>
        <Text content={t('AUTH_ERROR_MSG')} align="center" />
        <AkouoSupport size="small" align="center" className="pt-1" />
        <MicrosoftSignInButton onClick={authenticate} />
      </Flex>
    );
  }

  const render = () => {
    if (frameContext === 'content') return <PreMeeting />;
    if (frameContext === 'sidePanel') return <InMeeting />;

    return <Text align="center" className="pt-4" content={t('UNSUPPORTED_FRAME_MESSAGE')} />;
  };

  return (
    <Flex fill column>
      <div className="meeting-content">{render()}</div>
      <TabFooter className="pr-2" />
    </Flex>
  );
};

export default Tab;
