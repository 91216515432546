import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  getParticipantMeetingDetails,
  createParticipant,
  fetchACSAccessToken,
  generateOtpForUser,
  validateOtpForUser,
} from 'FEATURES/participant/api';
import { logAuditEvent } from 'SERVICES/auditLogger';
import {
  TextField,
  Dialog,
  DialogFooter,
  PrimaryButton,
  Spinner,
  MessageBar,
  MessageBarType,
  Stack,
  StackItem,
  Text,
  Label,
} from '@fluentui/react';
import { useParams } from 'react-router-dom';
import logger from 'SERVICES/logger';
import { useTranslation } from 'SERVICES/i18n';
import { ENTER_KEY_CODE } from 'CONSTANTS/appConstants';
import { ERROR, AUDIT_EVENT_TYPE } from 'CONSTANTS/apiConstants';
import { appSelector } from 'STORE/appSlice';
import { browserName, isAndroid, isMobile } from 'react-device-detect';
import {
  ANDROID_SUPPORTED_BROWSER,
  DESKTOP_SUPPORTED_BROWSER,
  IOS_SUPPORTED_BROWSER,
} from '../constants';

const withHomeParticipant = (WrappedComponent) => {
  const preMeetingComponent = () => {
    // Saving all the details in state as no need of reducer for this
    const [meetingInformation, setMeetingInformation] = useState(undefined);
    const [participantId, setParticipantId] = useState(undefined);
    const [userName, setUserName] = useState('');
    const [hideDialog, setHideDialog] = useState(false);
    const [loadComposite, setLoadComposite] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [callEndReason, setCallEndReason] = useState(false);
    const [hideOtpDialog, setHideOtpDialog] = useState(false);
    const [otpGenerated, setOtpGenerated] = useState(false);
    const [emailValue, setEmailValue] = useState('');
    const [otpValue, setOtpValue] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [loaderToGenerateOtp, setLoaderToGenerateOtp] = useState(false);
    const errorTimestamp = useSelector(appSelector.errorTimestamp);

    const { t } = useTranslation('participant', { keyPrefix: 'PRE_MEETING' });
    const [emailValidationError, setEmailValidationError] = useState(t('EMAIL_ERROR_MESSAGE'));
    const [otpValidationError, setOtpValidationError] = useState(t('OTP_ERROR_MESSAGE'));
    const [loadingOtpMessage, setLoadingOtpMessage] = useState(t('LOADING_GENERATING_OTP'));
    const ct = useTranslation('translation', { keyPrefix: 'COMMON' }).t;

    const isUserValidated = useRef(false);
    const maxRetryAttemptRef = useRef(3);
    // Taking from the routes
    const { meetingId } = useParams();

    // ACS-ID (userId) token
    const [accessToken, setAccessToken] = useState(undefined);

    // Shared ACS-ID (participantID) token
    const [languageMeetingToken, setLanguageMeetingToken] = useState(undefined);

    const dialogContentProps = {
      title: t('JOIN_MEETING_TITLE'),
      showCloseButton: false,
    };

    const modalProps = useMemo(
      () => ({
        isBlocking: true,
        styles: { main: { maxWidth: 550 } },
      }),
      []
    );

    const otpModalProps = useMemo(
      () => ({
        isBlocking: true,
        styles: { main: { maxWidth: 600 } },
      }),
      []
    );

    const otpModalContentProps = {
      title: t('OTP_VALIDATION_TITLE'),
      titleProps: { className: 'otpTitle' },
      showCloseButton: false,
    };

    const closeDialog = () => setHideDialog(true);

    const onDialogButtonClicked = () => {
      const name = userName.trim();
      if (name.length > 0) {
        setLoadComposite(true);
        closeDialog();
        const requestData = {
          Timestamp: new Date(),
          Meeting_ID: meetingId,
          Event_type: AUDIT_EVENT_TYPE.uaLogin,
          Field_1: name,
        };
        logAuditEvent(requestData);
      }
    };

    const onNameChange = (_event, newValue) => {
      setUserName(newValue);
    };

    const onEmailChange = (_event, newValue) => {
      const realText = newValue.trim();
      setEmailValue(realText);
      const pattern =
        /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
      if (!pattern.test(realText)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    };

    const onOtpChange = (_event, newValue) => {
      const otpAfterTrimAndLength = newValue.trim().substring(0, 6);
      const pattern = /^[0-9]*$/;
      if (pattern.test(otpAfterTrimAndLength)) {
        if (otpAfterTrimAndLength.length < 6) {
          setOtpError(true);
          setOtpValue(otpAfterTrimAndLength);

          return;
        }
        setOtpValue(otpAfterTrimAndLength);
        setOtpError(false);
      } else {
        setOtpError(true);
      }
    };

    const generateOtp = async () => {
      setLoaderToGenerateOtp(true);
      // generate otp
      try {
        const response = await generateOtpForUser(meetingId, emailValue);
        setOtpGenerated(true);
        logger.debug(response);
      } catch (error) {
        logger.error(`error: ${error}`);
        if (error && error.code === ERROR.USER_IS_NOT_ELIGIBLE) {
          setEmailValidationError(t('EMAIL_NOT_ELIGIBLE'));
          setEmailError(true);
        } else {
          setApiError(true);
        }
      } finally {
        setLoaderToGenerateOtp(false);
      }
    };

    const validateOtp = async () => {
      logger.debug('validate otp');
      setLoaderToGenerateOtp(true);

      setLoadingOtpMessage(t('LOADING_VALIDATING_OTP'));
      try {
        const response = await validateOtpForUser(meetingId, emailValue, otpValue);
        logger.debug(response);
        isUserValidated.current = true;
        setHideOtpDialog(true);
      } catch (error) {
        maxRetryAttemptRef.current -= 1;
        logger.error(`error: ${error}`);
        if (error && error.code) {
          switch (error.code) {
            case ERROR.OTP_IS_INVALID:
              setOtpValidationError(t('OTP_IS_INVALID'));
              setOtpError(true);
              break;
            case ERROR.OTP_IS_EXPIRED:
              setOtpValidationError(t('OTP_EXPIRED'));
              setOtpError(true);
              break;
            case ERROR.OTP_IS_MISMATCH:
              setOtpValidationError(t('OTP_MISMATCH'));
              setOtpError(true);
              break;
            default:
              break;
          }
          if (maxRetryAttemptRef.current === 0) {
            setHideOtpDialog(true);
          }
          setApiError(true);
        }
      } finally {
        setLoaderToGenerateOtp(false);
      }
    };

    const deviceDetails = () => {
      if (isMobile) {
        return isAndroid ? ANDROID_SUPPORTED_BROWSER : IOS_SUPPORTED_BROWSER;
      }
      return DESKTOP_SUPPORTED_BROWSER;
    };

    /**
     *
     * @returns {} containing meeting information and access token for language call
     */
    const meetingDetails = async (userId) => {
      const meetingInformationResponse = await getParticipantMeetingDetails(meetingId, userId);
      const languageMeetingTokenResponse = await fetchACSAccessToken(
        meetingInformationResponse.interpretation.participantId
      );
      return { meetingInformationResponse, languageMeetingTokenResponse };
    };

    /**
     *
     * @returns {} containing new participant ID and token
     */
    const participantDetails = async () => {
      const participantIdResponse = await createParticipant();
      const accessTokenResponse = await fetchACSAccessToken(participantIdResponse);
      return { participantIdResponse, accessTokenResponse };
    };

    useEffect(() => {
      if (errorTimestamp) {
        setApiError(ct('GENERIC_ERROR'));
      }
    }, [errorTimestamp]);

    // This useEffect will call once when component gets load, hence calling required API here
    useEffect(() => {
      const callApis = async () => {
        try {
          const { participantIdResponse, accessTokenResponse } = await participantDetails();
          const { meetingInformationResponse, languageMeetingTokenResponse } = await meetingDetails(
            participantIdResponse
          );
          isUserValidated.current = !meetingInformationResponse.isTwoFaEnabled;
          setMeetingInformation(meetingInformationResponse);
          setLanguageMeetingToken(languageMeetingTokenResponse);
          setParticipantId(participantIdResponse);
          setAccessToken(accessTokenResponse);
        } catch (err) {
          logger.error(err);
          setApiError(
            err.code === ERROR.FEATURE_NOT_ENABLED
              ? t('MEETING_UNAVAILABLE_UA_ERROR')
              : t('PRE_MEETING_START_ERROR')
          );
        }
      };

      // Calling all the required API's here and setting it to state
      callApis();
    }, []);

    if (!deviceDetails().includes(browserName)) {
      return (
        <Stack verticalFill verticalAlign="center" horizontalAlign="center">
          <StackItem>
            <Text className="alignCenter">
              {isMobile ? t('UNSUPPORTED_MOBILE_BROWSER') : t('UNSUPPORTED_DESKTOP_BROWSER')}
            </Text>
          </StackItem>
          <StackItem>
            <ul>
              {deviceDetails().map((element) => {
                return <li>{element}</li>;
              })}
            </ul>
          </StackItem>
        </Stack>
      );
    }
    if (meetingInformation && participantId && accessToken && languageMeetingToken) {
      if (loadComposite) {
        return (
          <WrappedComponent
            meetingDetails={meetingInformation}
            participantId={participantId}
            accessToken={accessToken}
            languageMeetingToken={languageMeetingToken}
            userName={userName}
            setLoadComposite={setLoadComposite}
            setCallEndReason={setCallEndReason}
          />
        );
      }

      if (hideDialog) {
        return (
          <Stack verticalFill verticalAlign="center" horizontalAlign="center">
            <StackItem>
              <Text className="alignCenter" variant="large">
                {t(callEndReason.displayText)}
              </Text>
            </StackItem>
          </Stack>
        );
      }

      if (maxRetryAttemptRef.current === 0 && hideOtpDialog) {
        return (
          <Stack verticalFill verticalAlign="center" horizontalAlign="center">
            <StackItem>
              <Text className="alignCenter" variant="large">
                {t('RETRY_EXCEEDED')}
              </Text>
            </StackItem>
          </Stack>
        );
      }

      return isUserValidated.current ? (
        <Dialog modalProps={modalProps} dialogContentProps={dialogContentProps} hidden={hideDialog}>
          <TextField
            placeholder={t('ENTER_NAME_PLACEHOLDER')}
            onChange={onNameChange}
            onKeyDown={(e) => {
              if (e.keyCode === ENTER_KEY_CODE) {
                e.preventDefault();
                onDialogButtonClicked();
              }
            }}
          />
          <DialogFooter>
            <PrimaryButton
              onClick={onDialogButtonClicked}
              text={t('JOIN_BUTTON_TEXT')}
              disabled={userName?.trim().length < 1}
            />
          </DialogFooter>
        </Dialog>
      ) : (
        <Dialog
          modalProps={otpModalProps}
          dialogContentProps={otpModalContentProps}
          hidden={hideOtpDialog}
        >
          <TextField
            className="otpInput"
            placeholder={t('OTP_EMAIL_PLACEHOLDER')}
            onChange={onEmailChange}
            disabled={otpGenerated}
            value={emailValue}
            errorMessage={emailError ? emailValidationError : ''}
          />
          {otpGenerated && (
            <>
              <TextField
                className="otpInput"
                placeholder={t('OTP_CODE_PLACEHOLDER')}
                value={otpValue}
                onChange={onOtpChange}
                pattern="[0-9]*"
                errorMessage={otpError ? otpValidationError : ''}
              />
              {maxRetryAttemptRef.current < 3 && (
                <Label className="otpRetryAttempt">
                  {t('MAX_RETRY_ATTEMPT') + maxRetryAttemptRef.current}
                </Label>
              )}
            </>
          )}
          {loaderToGenerateOtp && <Spinner label={loadingOtpMessage} labelPosition="right" />}

          <DialogFooter>
            {!otpGenerated && (
              <PrimaryButton
                onClick={generateOtp}
                text={t('GENERATE_OTP_BUTTON_TEXT')}
                disabled={emailValue?.trim().length < 1 || emailError || loaderToGenerateOtp}
              />
            )}
            {otpGenerated && (
              <PrimaryButton
                onClick={validateOtp}
                text={t('VALIDATE_OTP_BUTTON_TEXT')}
                disabled={otpValue?.trim().length < 6 || otpError || loaderToGenerateOtp}
              />
            )}
          </DialogFooter>
        </Dialog>
      );
    }
    return apiError ? (
      <MessageBar messageBarType={MessageBarType.error}>{apiError}</MessageBar>
    ) : (
      <Spinner label={t('LOADING')} className="screenCenterSpinner" />
    );
  };

  return preMeetingComponent;
};

export default withHomeParticipant;
