import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import logger from 'SERVICES/logger';
import { Features, VideoStreamRenderer } from '@azure/communication-calling';
import utils from 'FEATURES/interpreter/utils/utils';
import { updateTeamsCallId } from 'FEATURES/interpreter/api/api';
import { useParams } from 'react-router-dom';

import { VIDEO_PRIORITY } from 'CONSTANTS/enum';
import { floorMeetingSelector as selector } from '../../floorMeetingSlice';
import {
  CALL_STREAM_TYPE,
  PARTICIPANT_EVENT_CHANGE,
  TIMEOUT_FOR_CALL_ID,
  VIDEO_GALLERY,
} from '../../../../constants/index';

const useVideoGallery = () => {
  const call = useSelector(selector.call);
  const callState = useSelector(selector.callState);
  const { meetingId } = useParams();

  // FIXME: Render issue similar to other component
  // eslint-disable-next-line no-unused-vars
  const [reRenderComponent, setReRenderComponent] = useState(undefined);
  const remoteParticipantVideo = useRef([]);
  // To store the count of ovc
  const ovcCountLocal = useRef(0);
  // To store the count of total stream
  const totalStreamCount = useRef(0);

  /**
   * Function to retry if fails to add stream
   * @param {*} participantId participant id to whom we need to retry
   * @param {*} type type of stream
   * @returns Stream
   */
  const checkIfFailsToAddStream = async (participantId, type) => {
    logger.info(
      '[OVC-CHANGE] Retring to add stream for participant:',
      participantId,
      ' type:',
      type
    );
    const participant = remoteParticipantVideo.current.find(
      (participantEle) => participantEle.userId === participantId
    );
    if (participant) {
      logger.debug('[OVC-CHANGE] Creating stream for:', participant?.displayName, ' type:', type);
      const stream =
        type === CALL_STREAM_TYPE.VIDEO ? participant.stream : participant.screenSharingStream;
      try {
        const renderer = new VideoStreamRenderer(stream);
        // Create a renderer view for the remote video stream.
        const view = await renderer.createView();
        const remoteVideoContainer = document.createElement('div');

        // Attach the renderer view to the UI.
        remoteVideoContainer.appendChild(view.target);

        return remoteVideoContainer;
      } catch (error) {
        logger.error(
          '[OVC-CHANGE] Error in checkIfFailsToAddStream while creating remote stream:',
          error
        );
      }
    }
    logger.info(
      '[OVC-CHANGE] Stream fails again for:',
      participant?.displayName,
      ' type:',
      type,
      'will not be rendered until get some bandwidth again'
    );
    return undefined;
  };
  /**
   * This function is used to create render element for video & screenshare
   * @param {*} stream
   * @returns Render element
   */
  const getRemoteVideoStream = async (stream, userId, type) => {
    try {
      const renderer = new VideoStreamRenderer(stream);
      // Create a renderer view for the remote video stream.
      const view = await renderer.createView();
      const remoteVideoContainer = document.createElement('div');

      // Attach the renderer view to the UI.
      remoteVideoContainer.appendChild(view.target);

      return remoteVideoContainer;
    } catch (error) {
      logger.error('Error in while creating remote stream:', error);
      // Retrying to add stream
      return checkIfFailsToAddStream(userId, type);
    }
  };

  /**
   * Turning on the stream for the participant
   * @param {*} participant person details
   * @param {*} stream stream which needs to be turned on
   */
  const turnOnStream = async (participant, stream) => {
    try {
      logger.info('[OVC-CHANGE] Turning on stream for participant:', participant?.displayName);
      const memberExist = remoteParticipantVideo.current.find(
        (participantEle) => participantEle.userId === participant.userId
      );
      logger.debug(
        '[OVC-CHANGE] memberExist:',
        memberExist?.displayName,
        'participants:',
        remoteParticipantVideo.current
      );
      if (memberExist) {
        memberExist.videoStream = {
          id: stream.id,
          isAvailable: stream.isAvailable,
          renderElement: await getRemoteVideoStream(
            stream,
            memberExist.userId,
            CALL_STREAM_TYPE.VIDEO
          ),
        };
        remoteParticipantVideo.current = [...remoteParticipantVideo.current];

        logger.debug('[OVC-CHANGE] Video started for participant:', participant?.displayName);
        setReRenderComponent(Date.now);
      }
    } catch (error) {
      logger.debug('[OVC-CHANGE] Error in turnOnStream:', error);
    }
  };

  /**
   * Remove low priority stream
   * @param {*} canRemoveUpto number of streams to be removed
   */
  const removeAdditionalStreamIfPresent = async (canRemoveUpto) => {
    logger.debug('[OVC-CHANGE] Need to remove streams upto:', canRemoveUpto);

    let sortedArr = [];

    Object.values(VIDEO_PRIORITY).forEach((priority) => {
      sortedArr = sortedArr.concat(
        remoteParticipantVideo.current.filter(
          (participant) => participant.videoPriority === priority
        )
      );
    });

    // Reversing so that low priority appears first
    sortedArr = sortedArr.reverse();
    logger.debug('[OVC-CHANGE] Reversing participant to remove stream: ', sortedArr);

    sortedArr.forEach((participant, index) => {
      if (
        participant.videoStream &&
        index < canRemoveUpto &&
        participant.videoPriority !== VIDEO_PRIORITY.HIGH
      ) {
        participant.videoStream = undefined;
        logger.debug('[OVC-CHANGE] Turning off stream for participant: ', participant?.displayName);
      }
    });

    // Assigning the sorted array to remoteParticipantVideo
    remoteParticipantVideo.current = sortedArr;
    remoteParticipantVideo.current = [...remoteParticipantVideo.current];
  };

  /**
   * To add stream if available but not rendered
   * @param {*} canAddUpto number of streams
   */
  const checkForAdditionalStreamIfPresent = async (canAddUpto) => {
    let sortedArr = [];

    Object.values(VIDEO_PRIORITY).forEach((priority) => {
      sortedArr = sortedArr.concat(
        remoteParticipantVideo.current.filter(
          (participant) => participant.videoPriority === priority
        )
      );
    });

    logger.debug('[OVC-CHANGE] Sorted participant according to priority:', sortedArr);
    const streamAvailableButNotRenderedParticipants = sortedArr.filter(
      (participant) => !participant.videoStream && participant.stream
    );

    if (streamAvailableButNotRenderedParticipants.length > 0) {
      logger.debug(
        '[OVC-CHANGE] Stream available but not rendered:',
        streamAvailableButNotRenderedParticipants.length,
        'Can add upto:',
        canAddUpto
      );
      streamAvailableButNotRenderedParticipants.forEach(async (participant, index) => {
        if (index < canAddUpto) {
          await turnOnStream(participant, participant.stream);
        }
      });
      // Assigning sorted array after adding the stream
      remoteParticipantVideo.current = sortedArr;
      remoteParticipantVideo.current = [...remoteParticipantVideo.current];
      setReRenderComponent(Date.now);
    } else {
      logger.debug('[OVC-CHANGE] All streams are rendered succesfully!');
    }
  };

  /**
   * Written a common function to update all the operation with switch case to avoid the syncronisation problem
   * though we can write it with async-await, still I prefer to use switch case to avoid code duplication
   * @param {*} participant Actual participant whose information need to be updated
   * @param {*} info Information constant which needs to be updated
   * @param {*} stream In case of screen share OR video stream we will be using this field
   */
  const udpateParticipantInformation = async (participant, info, stream) => {
    logger.debug('[VIDEO-ISSUE] udpateParticipantInformation', participant, info, stream);
    const participantId = utils.getId(participant.identifier);
    const findIndex = remoteParticipantVideo.current.findIndex(
      (ele) => ele.userId === participantId
    );

    logger.debug(
      '[VIDEO-ISSUE] udpateParticipantInformation participantId:',
      participantId,
      'If exist:',
      findIndex
    );
    if (findIndex !== -1) {
      logger.debug(
        '[VIDEO-ISSUE] UPDATING PARTICIPANT INFO',
        info,
        stream,
        participant?.displayName
      );
      const member = remoteParticipantVideo.current[findIndex];
      switch (info) {
        case PARTICIPANT_EVENT_CHANGE.nameChanged:
          member.displayName = participant?.displayName;
          break;
        case PARTICIPANT_EVENT_CHANGE.muteChanged:
          member.isMuted = participant.isMuted;
          if (!member.isScreenSharingOn && participant.isMuted) {
            member.videoPriority = VIDEO_PRIORITY.LOW;

            // Need to turn off the stream if high priority stream exist
            if (member.videoStream) {
              logger.debug(
                '[OV-CHANGE] Curent speaker muted now will check if streams available which needs to render'
              );

              const ifStreamExist = remoteParticipantVideo.current.find(
                (participantEle) =>
                  participantEle.stream && !participantEle.videoStream && !participantEle.isMuted
              );
              if (ifStreamExist) {
                logger.debug('[OV-CHANGE] Stream exist which needs to be rendered:', ifStreamExist);
                // Need to turn off the stream of other low priority
                await removeAdditionalStreamIfPresent(1);
                // Turn on for this participant
                await turnOnStream(ifStreamExist, ifStreamExist.stream);
              }
            }
          }
          // Setting unmuted to medium as there is more chance that person will be speaking actively
          if (!member.isScreenSharingOn && !participant.isMuted) {
            member.videoPriority = VIDEO_PRIORITY.MEDIUM;

            // checking if we have stream and not rendered
            if (member.stream && !member.videoStream) {
              // Need to turn off the stream of other low priority
              await removeAdditionalStreamIfPresent(1);
              // Turn on for this participant
              await turnOnStream(member, member.stream);
            }
          }

          // Case when screensharing person unmuted and have video stream to render
          if (member.isScreenSharingOn && !member.isMuted && member.stream && !member.videoStream) {
            // Need to turn off the stream of other low priority
            await removeAdditionalStreamIfPresent(1);
            // Turn on for this participant
            await turnOnStream(member, member.stream);
          }
          break;
        case PARTICIPANT_EVENT_CHANGE.speakingChanged:
          member.isSpeaking = participant.isSpeaking;
          break;
        case PARTICIPANT_EVENT_CHANGE.stateChanged:
          member.state = participant.state;
          break;
        case PARTICIPANT_EVENT_CHANGE.screenShareChanged:
          if (stream.isAvailable) {
            member.screenSharingStream = stream;
            member.isScreenSharingOn = true;
            member.videoPriority = VIDEO_PRIORITY.HIGH;
            member.screenShareStream = {
              id: stream.id,
              isAvailable: true,
              renderElement: await getRemoteVideoStream(
                stream,
                member.userId,
                CALL_STREAM_TYPE.SCREEN
              ),
            };
            logger.debug('[VIDEO-ISSUE] Screen share started');
          } else {
            member.isScreenSharingOn = false;
            member.screenShareStream = undefined;
            member.stream = undefined;
            member.videoPriority = member.isMuted ? VIDEO_PRIORITY.LOW : VIDEO_PRIORITY.MEDIUM;

            logger.debug('[VIDEO-ISSUE] Screen share stoped');
          }
          break;
        case PARTICIPANT_EVENT_CHANGE.videoChanged:
          if (stream.isAvailable) {
            // Storing stream so that if in future we need to restart
            member.stream = stream;

            if (ovcCountLocal.current > totalStreamCount.current) {
              logger.debug('[OVC-CHANGE] we have more bandwith, so we can add more streams');
              member.videoStream = {
                id: stream.id,
                isAvailable: stream.isAvailable,
                renderElement: await getRemoteVideoStream(
                  stream,
                  member.userId,
                  CALL_STREAM_TYPE.VIDEO
                ),
              };
              logger.debug('[VIDEO-ISSUE] Video started');
            } else if (member.videoPriority !== VIDEO_PRIORITY.LOW && !member.isMuted) {
              // Person who is sharing screen or speaking
              await removeAdditionalStreamIfPresent(1);
              // Turn on for this, so we need to turn off for others
              await turnOnStream(member, stream);
            } else {
              logger.debug(
                '[OVC-CHANGE] we have less bandwith and current participant is having low priority,',
                'so we store the stream but not render for person:',
                member?.displayName
              );
            }
          } else {
            member.videoStream = undefined;
            member.stream = undefined;

            logger.debug('[VIDEO-ISSUE] Video stopped');
          }
          break;
        default:
          logger.debug('Invalid information');
      }
      // remoteParticipantVideo.current.splice(findIndex, 1, member);
      remoteParticipantVideo.current = [...remoteParticipantVideo.current];
      setReRenderComponent(Date.now);

      totalStreamCount.current = remoteParticipantVideo.current.filter(
        (element) => element.videoStream
      ).length;
      return;
    }

    if (stream?.isAvailable) {
      logger.debug('[VIDEO-ISSUE] Required data not found but stream is available');
    }
  };

  const checkForStream = async (stream, participant) => {
    logger.debug(
      '[VIDEO-ISSUE] CHECKING FOR STREAM',
      'AVAILABLE:',
      stream.isAvailable,
      'RECEIVING:',
      stream.isReceiving
    );
    const videoType =
      stream.mediaStreamType === VIDEO_GALLERY.screenSharing
        ? PARTICIPANT_EVENT_CHANGE.screenShareChanged
        : PARTICIPANT_EVENT_CHANGE.videoChanged;

    await udpateParticipantInformation(participant, videoType, stream);
  };

  const subscribeToRemoteParticipant = async (participant) => {
    const participantId = utils.getId(participant.identifier);
    const findIndex = remoteParticipantVideo.current.findIndex(
      (ele) => ele.userId === participantId
    );

    if (findIndex === -1) {
      let videoPriority = VIDEO_PRIORITY.LOW;
      if (participant.isScreenSharingOn) {
        videoPriority = VIDEO_PRIORITY.HIGH;
      } else if (!participant.isMuted) {
        videoPriority = VIDEO_PRIORITY.MEDIUM;
      }
      const participantVideo = {
        userId: utils.getId(participant.identifier),
        displayName: participant?.displayName,
        isMuted: participant?.isMuted,
        isSpeaking: participant?.isSpeaking,
        videoPriority,
      };
      remoteParticipantVideo.current = [...remoteParticipantVideo.current, participantVideo];
    } else {
      remoteParticipantVideo.current = [...remoteParticipantVideo.current];
    }

    // AKMSTEAMS-505
    participant.on(VIDEO_GALLERY.displayNameChanged, async () => {
      await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.nameChanged);
    });

    participant.on(VIDEO_GALLERY.isSpeakingChanged, async () => {
      logger.debug('Speaking changed', participant?.displayName, participant?.isSpeaking);
      await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.speakingChanged);
    });
    participant.on(VIDEO_GALLERY.isMutedChanged, async () => {
      logger.debug('Mute status changed', participant?.displayName, participant?.isMuted);
      await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.muteChanged);
    });

    participant.on(VIDEO_GALLERY.stateChanged, async () => {
      if (participant.state === VIDEO_GALLERY.connected) {
        logger.debug('Participant Connected', participant);
        await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.stateChanged);
      }
    });

    participant.on(VIDEO_GALLERY.videoStreamsUpdated, (e) => {
      logger.debug('[VIDEO-ISSUE] videoStreamsUpdated', e, participant?.displayName);
      e.added.forEach(async (addedStream) => {
        logger.debug('[VIDEO-ISSUE] VIDEO STREAM ADDED', participant?.displayName);
        // Case when someone has already shared screen or video before we logs into the call
        if (addedStream.isAvailable) {
          await checkForStream(addedStream, participant);
        }
        addedStream.on(VIDEO_GALLERY.isAvailableChanged, async () => {
          logger.debug(
            '[VIDEO-ISSUE]',
            'IS_AVAILABLE CHANGED:',
            addedStream?.isAvailable,
            participant?.displayName
          );

          await checkForStream(addedStream, participant);
        });
      });
    });

    participant.videoStreams?.forEach(async (stream) => {
      // This check is added as per TAP discussion
      logger.debug(
        '[VIDEO-ISSUE] Adding the check as per TAP',
        'name:\n',
        participant?.displayName,
        'stream isAvailable:\n',
        stream.isAvailable
      );
      if (stream.isAvailable) {
        await checkForStream(stream, participant);
      }
      stream.on(VIDEO_GALLERY.isAvailableChanged, async () => {
        logger.debug(
          '[VIDEO-ISSUE] Stream Available Changed for participant',
          participant?.displayName
        );
        await checkForStream(stream, participant);
      });
    });
  };

  // Useeffect to handle change in stream or ovc
  useEffect(() => {
    logger.debug(
      '[OVC-CHANGE] Either ovcCount or stream count changed, OVC:',
      ovcCountLocal.current,
      ' Available Stream count: ',
      totalStreamCount.current
    );
    if (ovcCountLocal.current >= totalStreamCount.current) {
      const difference = ovcCountLocal.current - totalStreamCount.current;
      logger.debug('[OVC-CHANGE] We have more bandwidth, so we can add more streams');
      (async () => {
        await checkForAdditionalStreamIfPresent(difference);
      })();
    } else if (ovcCountLocal.current < totalStreamCount.current) {
      logger.debug('[OVC-CHANGE] We have less bandwidth, so we need to remove some streams');
      const difference = totalStreamCount.current - ovcCountLocal.current;
      (async () => {
        await removeAdditionalStreamIfPresent(difference);
      })();
    }
  }, [ovcCountLocal.current, totalStreamCount.current]);

  useEffect(() => {
    if (call) {
      setTimeout(async () => {
        const teamsCallId = call.id;
        if (teamsCallId) {
          logger.debug('[VIDEO-ISSUE] Call id for teams call:', teamsCallId, call);
          const dataToSend = {
            meetingId,
            teamsCallId,
          };
          await updateTeamsCallId(dataToSend);
        }
      }, TIMEOUT_FOR_CALL_ID);
      call.remoteParticipants.forEach(async (rp) => {
        logger.debug('[VIDEO-ISSUE] participant already in call:', rp);
        await subscribeToRemoteParticipant(rp);
      });

      try {
        const optimalVideoCountFeature = call?.feature(Features.OptimalVideoCount);
        optimalVideoCountFeature.on(VIDEO_GALLERY.optimalVideoCountChanged, () => {
          const localOptimalVideoCountVariable = optimalVideoCountFeature.optimalVideoCount;
          ovcCountLocal.current = localOptimalVideoCountVariable;
        });
      } catch (error) {
        logger.error('Error while subscribeToOVC', error);
      }

      call.on(VIDEO_GALLERY.remoteParticipantsUpdated, (e) => {
        logger.debug('[VIDEO-ISSUE] remoteParticipantsUpdated event:', e);
        e.added.forEach(async (p) => {
          await subscribeToRemoteParticipant(p);
        });
        e.removed.forEach((p) => {
          logger.debug('Participant Disconnected', p);
          const id = utils.getId(p.identifier);
          remoteParticipantVideo.current = remoteParticipantVideo.current.filter(
            (ele) => ele.userId !== id
          );
          setReRenderComponent(Date.now);
        });
      });
    }
  }, [call]);

  return {
    callState,
    remoteParticipantVideo: remoteParticipantVideo.current,
  };
};

export default useVideoGallery;
