import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ROUTES from 'CONSTANTS/routes';
import Home from 'FEATURES/home/Home';
// import { homeRoutes } from 'FEATURES/home';

const HomeRouter = () => {
  // const routes = [...homeRoutes];
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} key={`Route-${ROUTES.HOME}`} />
      </Routes>
    </BrowserRouter>
  );
};

export default HomeRouter;

// homeRoutes is obsolete but not removed till v1.6.0 is released
