import { tokenRequest } from 'SRC/features/interpreter/features/Authentication/authConfig';
import logger from 'SERVICES/logger';
import ROUTES from 'CONSTANTS/routes';
import { logAuditEvent } from 'SERVICES/auditLogger';
import { AUDIT_EVENT_TYPE } from 'CONSTANTS/apiConstants';

let msalInstance = null;
const INTERACTION_REQUIRED_ERROR = 'InteractionRequiredAuthError';

export const setMsalInstance = (msal) => {
  msalInstance = msal;
};

export const getInterpreterIdToken = async () => {
  const path = window.location.pathname;
  if (path.startsWith(ROUTES.INTERPRETER)) {
    const allAccounts = msalInstance.getAllAccounts();
    let tokenError = '';
    if (allAccounts.length > 0) {
      const [account] = allAccounts;
      try {
        const tokenData = await msalInstance.acquireTokenSilent({ ...tokenRequest, account });
        return tokenData.idToken;
      } catch (error) {
        tokenError = error.name;
      }

      // If the acquireTokenSilent fails and returns InteractionRequiredAuthError error
      // We need to call acquireTokenPopup to get token
      // AADB2C90077
      // https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes
      if (tokenError === INTERACTION_REQUIRED_ERROR) {
        // Trying to take token from pop-up
        try {
          const tokenInformation = await msalInstance.acquireTokenPopup({
            ...tokenRequest,
            account,
          });
          return tokenInformation.idToken;
        } catch (err) {
          logger.error('Error while acquireTokenPopup', err);
        }
      }
    }
  }
  return null;
};

export const logout = async (meetingId, id, name, email, logoutMode) => {
  const allAccounts = msalInstance.getAllAccounts();
  if (allAccounts.length > 0) {
    const [account] = allAccounts;

    try {
      msalInstance.logoutRedirect({
        account,
        onRedirectNavigate: () => {
          // Return false to implement local logout
          // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#requirements-for-front-channel-logout-page
          return false;
        },
      });

      logAuditEvent({
        Timestamp: new Date(),
        Meeting_ID: meetingId,
        Event_type: AUDIT_EVENT_TYPE.interpreterLogout,
        Field_1: id,
        Field_2: name,
        Field_3: email,
        // Field_4: type,
        Field_5: logoutMode,
      });
    } catch (err) {
      logger.error(err);
    }
  }
};
