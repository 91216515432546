// libraries
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import {
  CallWithChatComposite,
  darkTheme,
  ErrorBar,
  useAzureCommunicationCallWithChatAdapter,
} from '@azure/communication-react';
import { Spinner, Stack, StackItem, TooltipHost } from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';

// services
import logger from 'SERVICES/logger';
import {
  checkAndUpdateAudioElement,
  getAudioElement,
  STREAM_TAGS,
} from 'SERVICES/audioElementUtils';
import { logAuditEvent } from 'SERVICES/auditLogger';

// constants
import {
  CALL_ADAPTER_STATE,
  CALL_TYPE,
  PARTICIPANT_NAME,
  COMPOSITE_MSG,
  CALL_END_REASON,
  CALL_COMPOSITE_PAGE,
} from 'FEATURES/participant/constants';
import { AUDIT_EVENT_TYPE } from 'CONSTANTS/apiConstants';
import { useTranslation } from 'SERVICES/i18n';
import { selectLanguageForCaptions } from 'UTILS/languagesUtils';
import { HTML_ELEMENT_POSITION } from 'CONSTANTS/enum';

// components
import AkouoMiniLogo from 'RESOURCES/images/akouo-mini-logo-white.svg';
import LanguageCall from './LanguageCall';
import withParticipantHome from './withParticipantHome';

import './ParticipantHome.scss';

import LanguageButton from './LanguageButton';

const ParticipantHome = ({
  meetingDetails,
  participantId,
  accessToken,
  languageMeetingToken,
  userName,
  setLoadComposite,
  setCallEndReason,
}) => {
  // two translation files have been imported using different aliases
  const { t } = useTranslation('participant', { keyPrefix: 'IN_MEETING' });
  const ct = useTranslation('translation', { keyPrefix: 'COMMON' }).t;

  const floorSelectionObject = {
    key: CALL_TYPE.FLOOR,
    id: CALL_TYPE.FLOOR,
    text: t('NO_INTERPRETATION_OPTION'),
  };

  const captionsElementId = 'captionsIframe';
  const mobileCaptionsContainerId = 'mobileCaptionsContainer';
  const akouoSplitbuttonId = 'akouoSplitbutton';

  const { meetingId } = useParams();

  // Saving all the details in state as no need of reducer for this
  const [callAdapterState, setCallAdapterState] = useState(undefined);
  const [languageMeetingInfo, setLanguageMeetingInfo] = useState(undefined);
  const [callDisconnected, setCallDisconnected] = useState(false);
  const [connectToLangCall, setConnectToLangCall] = useState(undefined);
  const [languageSelectionError, setLanguageSelectionError] = useState([]);
  const [controlBarHeight, setHeight] = useState(0);
  const [isInterpreting, setIsInterpreting] = useState(false);
  const [akouoButtonHeight, setAkouoButtonHeight] = useState(0);
  const [dropdownSelection, setDropdownSelection] = useState(floorSelectionObject);
  const [lastLanguage, setLastLanguage] = useState(null);
  const [splitbuttonDisplayText, setSplitbuttonDisplayText] = useState(t('DROPDOWN_PLACEHOLDER'));
  const [noActiveSpeaker, setNoActiveSpeaker] = useState(true);

  const [lastRemaining, setLastRemaining] = useState(false);
  const [disconnectionTrigger, setDisconnectionTrigger] = useState(false);
  const [inMeetingView, setInMeetingView] = useState(false);
  const [ccOn, setCcOn] = useState(false);

  // Using reference as we do not want to re-render component as value change
  const setTeamsAudioRef = useRef();

  const [languageLoading, setLanguageLoading] = useState(false);
  const formFactor = isMobile ? 'mobile' : 'desktop';

  // Retrieving floor data from API response
  const floorData = meetingDetails.interpretation.interpreterMeetings.find(
    (languageElement) => languageElement.language === CALL_TYPE.FLOOR
  );

  // Credentials must be memoised
  const credential = useMemo(
    () => new AzureCommunicationTokenCredential(accessToken),
    [accessToken]
  );

  const userId = useMemo(() => {
    return {
      communicationUserId: participantId,
    };
  }, [participantId]);

  // Locator must be memoised
  const callAndChatLocator = useMemo(() => {
    return {
      meetingLink: floorData.id,
    };
  }, []);

  // For composite to work we need to create adapter
  // Remember locator & credential must be memoised
  const callAndChatAdapterObj = useAzureCommunicationCallWithChatAdapter({
    userId,
    displayName: userName,
    credential,
    locator: callAndChatLocator,
    endpoint: process.env.REACT_APP_CHAT_SERVICE_ENDPOINT,
  });

  useEffect(() => {
    // position the dropdown on mobile
    if (callAdapterState === CALL_ADAPTER_STATE.CONNECTED) {
      const stackItems = document.getElementsByClassName('ms-StackItem');
      const controlBar = stackItems[stackItems.length - 1];
      if (controlBar) {
        setHeight(controlBar.clientHeight);
      }
      setAkouoButtonHeight(document.getElementById(akouoSplitbuttonId)?.offsetHeight);
    }
  }, [callAdapterState]);

  const updateTeamsAudioState = (value) => {
    const teamAudio = getAudioElement(STREAM_TAGS.TEAMS);
    if (teamAudio) {
      teamAudio.muted = value;
    }
  };

  const getLogoutReason = (callEndSubCode) => {
    if (callEndSubCode) {
      return CALL_END_REASON[callEndSubCode];
    }
    return CALL_END_REASON[lastRemaining ? 1 : 0];
  };

  const disconnectCalls = async () => {
    /*
    User leaves / User is removed / Meeting has ended / User rejected from lobby:
    CALL_ENDED -> triggerDisconnection -> here
    
    User is last participant:
    PARTICIPANT_LEFT -> setLastRemaining -> CALL_ENDED -> triggerDisconnection -> here

    */
    await setCallDisconnected(true);
    await setLoadComposite(false);
  };

  // Language selection change

  const languageSelectionErrorHandling = async () => {
    await setLanguageLoading(false);
    setLanguageMeetingInfo(undefined);
    setConnectToLangCall(undefined);
    updateTeamsAudioState(false);
    setSplitbuttonDisplayText(t('DROPDOWN_PLACEHOLDER'));
    setLanguageSelectionError([
      {
        type: COMPOSITE_MSG.accessDenied,
        timestamp: new Date(),
      },
    ]);
  };

  const dropDownChange = (event, item) => {
    logger.debug('Dropdown change', event, item);
    if (dropdownSelection.key !== item.key) {
      // connect to new language call only if language selection has changed
      setLanguageSelectionError([]);
      setDropdownSelection(item);

      if (item.id === CALL_TYPE.FLOOR) {
        // Here it is requierd to have this extra variable, though we are clearing in else condition
        // If we use same object i.e, languageMeetingInfo then there is a bug that it takes 5 seconds to recieve voice in updated channel
        // Hence keeping it as it was
        setLanguageMeetingInfo(undefined);
        setConnectToLangCall(undefined);
        updateTeamsAudioState(false);
        setSplitbuttonDisplayText(t('DROPDOWN_PLACEHOLDER'));
        setCcOn(false);
      } else {
        setLanguageLoading(true);
        const dataToUpdate = {
          languageCallId: item.id,
          accessToken: languageMeetingToken,
          userId: meetingDetails.interpretation.participantId,
          displayName: PARTICIPANT_NAME,
        };
        setLanguageMeetingInfo(dataToUpdate);
        setConnectToLangCall(item.id);
        setLastLanguage(item);
      }
    }
  };
  useEffect(() => {
    // on main button click when language is already selected
    if (lastLanguage && isInterpreting) {
      dropDownChange({}, lastLanguage);
    } else {
      dropDownChange({}, floorSelectionObject);
    }
  }, [isInterpreting]);

  useEffect(() => {
    if (languageLoading) {
      setSplitbuttonDisplayText(t('LANGUAGE_SELECTION_LOADING'));
    } else {
      setSplitbuttonDisplayText(
        dropdownSelection?.id === CALL_TYPE.FLOOR
          ? t('DROPDOWN_PLACEHOLDER')
          : dropdownSelection.text
      );
    }
  }, [languageLoading]);

  useEffect(() => {
    if (lastRemaining) {
      callAndChatAdapterObj.leaveCall(false);
    }
  }, [lastRemaining]);

  useEffect(() => {
    if (disconnectionTrigger) {
      disconnectCalls();
    }
  }, [disconnectionTrigger]);

  useEffect(() => {
    if (callDisconnected) {
      const logoutReason =
        getLogoutReason(
          callAndChatAdapterObj?.callAdapter?.context?.state?.endedCall?.callEndReason?.subCode
          // in case of unexpected logout, due to network disconnection or any other reasons- event "user left call"
        ) || CALL_END_REASON[0];
      setCallEndReason(logoutReason);
      logAuditEvent({
        Timestamp: new Date(),
        Meeting_ID: meetingId,
        Event_type: AUDIT_EVENT_TYPE.uaLogout,
        Field_1: userName,
        Field_2: logoutReason.eventText,
        Field_3: logoutReason.triggerType,
      });
    }
  }, [callDisconnected]);

  const removeCaptionsElement = () => {
    document.getElementById(captionsElementId)?.remove();
  };

  useEffect(() => {
    if (inMeetingView && ccOn) {
      // get language data
      const captionLanguage = selectLanguageForCaptions(
        meetingDetails.captionEvents,
        dropdownSelection?.key
      );

      logger.info(`Captioning event ${captionLanguage?.eventname}`);
      // create and insert iframe
      const captionsElement = document.createElement('iframe');

      captionsElement.setAttribute('id', captionsElementId);
      if (isMobile) {
        captionsElement.setAttribute(
          'src',
          `//www.streamtext.net/player/?event=${captionLanguage.eventname}&language=${captionLanguage.sourcelanguageid}&chat=false&title=off&controls-transcript=off&height=100%&width=100%&bgc=222&fgc=fff&ff=Helvetica&fs=14`
        );
        const composite = document.getElementById(mobileCaptionsContainerId);
        composite?.insertAdjacentElement(HTML_ELEMENT_POSITION.afterBegin, captionsElement);
      } else {
        captionsElement.setAttribute(
          'src',
          `//www.streamtext.net/player/?event=${captionLanguage.eventname}&language=${captionLanguage.sourcelanguageid}&chat=false&title=off&controls-transcript=off&height=20%&bgc=222&fgc=fff&ff=Helvetica&fs=14`
        );
        const composite = document.querySelector('[id^="callWithChatCompositeParentDiv"]');
        composite?.children[0]?.insertAdjacentElement(
          HTML_ELEMENT_POSITION.afterEnd,
          captionsElement
        );
      }
    }
    return removeCaptionsElement;
  }, [inMeetingView, dropdownSelection, ccOn]);

  const triggerDisconnection = () => {
    setDisconnectionTrigger(true);
  };
  // Showing spinner till adapter created
  if (!callAndChatAdapterObj) {
    return <Spinner label={t('SPINNER_LABEL')} className="screenCenterSpinner" />;
  }

  // Subscribing onStateChange of callAdapter
  callAndChatAdapterObj.callAdapter.onStateChange((data) => {
    if (data?.page === CALL_COMPOSITE_PAGE.configuration || !data?.page) {
      setInMeetingView(false);
    } else {
      setInMeetingView(true);
    }
    if (data && data.call && data.call.state === CALL_ADAPTER_STATE.CONNECTED) {
      // Using the following if condition as we are getting multiple Connected event
      // and we need to execute following code once when we are joined in teams meeting
      if (!setTeamsAudioRef.current) {
        setTeamsAudioRef.current = true;
        setCallAdapterState(CALL_ADAPTER_STATE.CONNECTED);
        logger.debug('Setting teams audio tag');
        checkAndUpdateAudioElement(STREAM_TAGS.TEAMS);
      }
    } else if (data && !data.call && data.endedCall && !callDisconnected) {
      // case for "meeting ended for all participants"
      // endedcall object from state change data
      triggerDisconnection();
    } else {
      setTeamsAudioRef.current = false;
      setCallAdapterState(data?.call?.state);
    }
  });

  callAndChatAdapterObj.callAdapter.on(CALL_ADAPTER_STATE.PARTICIPANT_LEFT, () => {
    const callData = callAndChatAdapterObj?.callAdapter?.context?.state?.call;
    if (callData?.state) {
      // true for all states including none
      if (
        callData?.remoteParticipants &&
        Object.keys(callData.remoteParticipants).length === 0 &&
        callAndChatAdapterObj?.callAdapter?.context?.state?.endedCall?.callEndReason?.subCode ===
          undefined // so that "end call for all" option does not satisfy this condition
      ) {
        // runs when no other remote participants connected
        setLastRemaining(true);
      }
    }
  });

  callAndChatAdapterObj.callAdapter.on(CALL_ADAPTER_STATE.CALL_ENDED, triggerDisconnection);

  // Data to show on UI
  const languageInterpretation = [];
  if (callAdapterState === CALL_ADAPTER_STATE.CONNECTED) {
    meetingDetails.interpretation.interpreterMeetings.forEach((ele) => {
      // This function is available in languagesUtils but cannot be used to hook rendering issue
      let languageObj = {};
      if (ele.language === CALL_TYPE.FLOOR) {
        languageInterpretation.push(floorSelectionObject);
      } else {
        const text = ct('LANGUAGES', { returnObjects: true }).find(
          (item) => ele.language === item.value
        );
        languageObj = {
          key: ele.language,
          id: ele.id,
          text: text?.label,
        };
        languageInterpretation.push(languageObj);
      }
    });
  }

  const responsiveStyles = isMobile
    ? {
        bottom: controlBarHeight + 10,
        width: '100%',
        textAlign: '-webkit-center',
        justifyContent: 'center',
      }
    : {
        // AKMSTEAMS-1193
        bottom: ccOn ? `${150 + 20}px` : '16px',
        left: '2vw',
        lineHeight: '2.7em',
        maxHeight: '2.9em',
      };

  const toggleInterpreter = () => {
    setIsInterpreting(!isInterpreting);
  };

  return (
    <>
      <ErrorBar
        id="languageSelectionError"
        strings={{ accessDenied: t('LANGUAGE_SELECTION_ERROR') }}
        activeErrorMessages={languageSelectionError}
      />
      {callAdapterState === CALL_ADAPTER_STATE.CONNECTED && (
        <div id="akouoSelection" style={responsiveStyles}>
          <span
            className={`verticalCenter ${
              languageLoading ? 'noRightBorderLogoLoading' : 'noRightBorderLogo'
            }`}
          >
            <img src={AkouoMiniLogo} alt="" id="uaBrandingLogo" width="95%" />
          </span>
          <TooltipHost content={t('DROPDOWN_PLACEHOLDER')} directionalHint={0}>
            <Stack horizontal>
              <StackItem>
                <LanguageButton
                  languageItems={languageInterpretation}
                  displayText={splitbuttonDisplayText}
                  languageChangeHandler={dropDownChange}
                  onButtonClickHandler={toggleInterpreter}
                  activeElement={dropdownSelection}
                  isLanguageLoading={languageLoading}
                  noActiveSpeaker={noActiveSpeaker}
                />
              </StackItem>
              {meetingDetails?.captionEvents?.length > 0 && (
                <StackItem
                  style={
                    ccOn
                      ? {
                          height: akouoButtonHeight,
                          background: darkTheme.palette.neutralLighter,
                        }
                      : {
                          height: akouoButtonHeight,
                        }
                  }
                  onClick={() => setCcOn(!ccOn)}
                  className={`captionsButton ${
                    (splitbuttonDisplayText === t('DROPDOWN_PLACEHOLDER') ||
                      splitbuttonDisplayText === t('LANGUAGE_SELECTION_LOADING')) &&
                    'disabled-cc'
                  }`}
                >
                  CC
                </StackItem>
              )}
            </Stack>
          </TooltipHost>
        </div>
      )}
      {isMobile && ccOn && <div id={mobileCaptionsContainerId} />}
      {connectToLangCall && languageMeetingInfo && (
        <LanguageCall
          languageMeetingInfo={languageMeetingInfo}
          setLanguageLoading={setLanguageLoading}
          callDisconnected={callDisconnected}
          languageSelectionErrorHandling={languageSelectionErrorHandling}
          noActiveSpeakerHandler={setNoActiveSpeaker}
        />
      )}

      {/* Disabling more options  */}
      <CallWithChatComposite
        adapter={callAndChatAdapterObj}
        formFactor={formFactor}
        fluentTheme={darkTheme}
        options={{
          callControls: {
            moreButton: false,
          },
        }}
      />
    </>
  );
};

export default withParticipantHome(ParticipantHome);
