import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Flex,
  Text,
  Form,
  FormInput,
  FormDropdown,
  Grid,
  FormButton,
} from '@fluentui/react-northstar';
import {
  interpreterActions,
  selectInterpreter,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import useConfigurationForm from './useConfigurationForm';
import {
  CustomToastContainer as ToastContainer,
  showToast,
  TOAST_TYPE,
} from '../../../components/CustomToastContainer';
// utility function to convert data to fluentui drop down compatible data
const getDropDownCompatibleData = ({
  items,
  selectedItem,
  onSelect,
  titleKey = 'name',
  idKey = 'id',
}) => {
  return {
    selectedItem: selectedItem && { header: selectedItem[titleKey], key: selectedItem[idKey] },
    items: items?.map((item) => ({ header: item[titleKey], key: item[idKey] })),
    onSelect: (item) => onSelect(items?.find((lang) => lang.id === item.key)),
  };
};

const ConfigurationForm = ({ joinMeeting, joinTestCall }) => {
  const dispatch = useDispatch();
  const errorStatus = useSelector(selectInterpreter.errorStatus);
  const { t, speaker, microphone, isFormValid, errorMessage, interpreterName, displayNameInputId } =
    useConfigurationForm();
  const speakerDD = getDropDownCompatibleData({ ...speaker });
  const microphoneDD = getDropDownCompatibleData({ ...microphone });
  const handleSubmit = () => {
    if (isFormValid()) {
      dispatch(
        interpreterActions.setInterpreterName(document.getElementById(displayNameInputId).value)
      );
      joinMeeting();
    }
  };

  const handleTestCall = () => {
    if (isFormValid()) {
      joinTestCall();
    }
  };

  useEffect(() => {
    if (errorStatus) {
      showToast({
        data: {
          content: t('SOMETHING_WRONG'),
          autoClose: false,
          type: TOAST_TYPE.error,
        },
      });
    }
  }, [errorStatus]);
  return (
    <Flex className="landing-page" fill hAlign="center" vAlign="center">
      <div className="container">
        <Text content={t('HEADING')} weight="semibold" size="large" />
        <Form className="pt-4">
          <FormInput
            fluid
            required
            defaultValue={interpreterName}
            label={t('NAME_LABEL')}
            id={displayNameInputId}
            placeholder={t('NAME_PLACEHOLDER')}
          />

          <FormDropdown
            fluid
            items={speakerDD.items}
            value={speakerDD.selectedItem}
            placeholder={t('SPEAKER_PLACEHOLDER')}
            noResultsMessage={t('SPEAKER_NO_DATA')}
            label={{ content: t('SPEAKER_LABEL'), required: true }}
            getA11ySelectionMessage={{
              onAdd: (item) => {
                speakerDD.onSelect(item);
              },
            }}
          />

          <FormDropdown
            fluid
            items={microphoneDD.items}
            value={microphoneDD.selectedItem}
            placeholder={t('MICROPHONE_PLACEHOLDER')}
            noResultsMessage={t('MICROPHONE_NO_DATA')}
            label={{ content: t('MICROPHONE_LABEL'), required: true }}
            getA11ySelectionMessage={{
              onAdd: (item) => {
                microphoneDD.onSelect(item);
              },
            }}
          />
          <Grid columns="2" style={{ columnGap: '10px' }}>
            <FormButton
              fluid
              type="button"
              onClick={handleTestCall}
              content={t('Test_BUTTON_LABEL')}
            />
            <FormButton
              primary
              fluid
              type="submit"
              onClick={handleSubmit}
              content={t('JOIN_BUTTON_LABEL')}
              disabled={errorStatus}
            />
          </Grid>
        </Form>
        {errorMessage && <Text className="pt-2 pl-2" important content={errorMessage} />}
      </div>
      <ToastContainer />
    </Flex>
  );
};

export default ConfigurationForm;
