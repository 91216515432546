import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'SERVICES/i18n';

import logger from 'SERVICES/logger';
import useSnackbar from 'HOOKS/useSnackbar';
import useActions from 'HOOKS/useActions';
import { SNACKBAR_TYPE } from 'CONSTANTS/enum';
import { USER_ROLE } from 'FEATURES/teams/constants';
import {
  teamsMeetingSelector as selector,
  teamsMeetingActions,
} from 'FEATURES/teams/slices/teamsMeetingSlice';
import useMeetingDetails from './useMeetingDetails';

const usePreMeeting = () => {
  const showSnackbar = useSnackbar();
  const [editMode, setEditMode] = useState(true);
  const currentUserRole = useSelector(selector.currentUserRole);
  const { t } = useTranslation('teams', { keyPrefix: 'PREMEETING' });
  const {
    isAuthorized,
    fetchMeetingDetails,
    loader,
    submitData,
    refetchMeetingByProvidingPermission,
  } = useMeetingDetails();
  const { updateMeetingDetailsUniversalAccess } = useActions(teamsMeetingActions);

  const fetchMeeting = async ({ usePermissionScope } = { usePermissionScope: false }) => {
    logger.debug('fetchMeeting', 'usePermissionScope:', usePermissionScope);
    const meetingDetails = usePermissionScope
      ? await refetchMeetingByProvidingPermission()
      : await fetchMeetingDetails();
    if (meetingDetails?.interpretation?.interpreterMeetings.length === 0) {
      // If there is not language show PreMeetingForm component
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  useEffect(() => {
    fetchMeeting();
  }, []);

  const onSubmit = async ({
    languages,
    updatedLanguages,
    universalAccessEnabled,
    isPartialUpdate,
  }) => {
    try {
      await submitData({
        languages,
        updatedLanguages,
        universalAccessEnabled,
        isPartialUpdate,
      });
      showSnackbar({ message: t('SUBMIT_REQ_SUCCESS_MSG'), type: SNACKBAR_TYPE.SUCCESS });
      await updateMeetingDetailsUniversalAccess(universalAccessEnabled);
      setEditMode(false);
    } catch (error) {
      logger.error('Error While submitting languages', error);
      showSnackbar({ message: error.message, type: SNACKBAR_TYPE.DANGER });
    }
  };

  return {
    isAuthorized,
    loader,
    editMode: editMode && currentUserRole === USER_ROLE.ORGANIZER,
    setEditMode,
    onSubmit,
    fetchMeeting,
    t,
  };
};

export default usePreMeeting;
