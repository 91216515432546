import logger from 'SERVICES/logger';
import useLoader from 'HOOKS/useLoader';
import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'SERVICES/i18n';
import { CallClient } from '@azure/communication-calling';
import { ECHO_BOT_ID } from 'FEATURES/interpreter/constants';
import acsUtils from 'SERVICES/callClient/acsUtils';
import { fetchACSAccessToken } from 'FEATURES/interpreter/api/api';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { selectInterpreter } from 'FEATURES/interpreter/slices/interpreterSlice';
import { testCallSelector as selector } from './testCallSlice';

const useTestCall = ({ endHandCall }) => {
  const [call, setCall] = useState();
  const [isMuted, setIsMuted] = useState(false);
  const { isLoading, loadingMessage, startLoading, stopLoading } = useLoader(true);

  const interpreterName = useSelector(selectInterpreter.interpreterName);
  const selectedSpeaker = useSelector(selector.selectedSpeaker);
  const selectedMicrophone = useSelector(selector.selectedMicrophone);

  const testCallAgent = useRef();

  const floorMeeting = useSelector(selectInterpreter.floorMeeting);
  const { t } = useTranslation('interpreter', { keyPrefix: 'TEST_CALL' });

  useEffect(() => {
    if (call) {
      const callStateChanged = () => {
        logger.debug(`Test call state: ${call.state} `);

        if (call.state === 'Connected') stopLoading();
        if (call.state === 'Disconnected') endHandCall();
      };

      callStateChanged();
      call.on('stateChanged', callStateChanged);

      call.on('isMutedChanged', () => {
        logger.info('Local microphone muted changed ', call.isMuted);
        setIsMuted(call.isMuted);
      });
    }
  }, [call]);

  const getToken = async (userId) => {
    const token = await fetchACSAccessToken(userId);
    logger.debug(token, 'ACS Token for Test call');
    return new AzureCommunicationTokenCredential(token);
  };

  const subscribeToDeviceManager = async (callClient) => {
    const deviceManager = await acsUtils.getDeviceManager(callClient);
    await deviceManager.askAudioDevicePermission();
    deviceManager.setSpeakerDevice(selectedSpeaker);
    deviceManager.setMicrophoneDevice(selectedMicrophone);
  };

  const createCallAgent = async (tokenCredential, displayName) => {
    const callClient = new CallClient();
    const callAgent = await callClient.createCallAgent(tokenCredential, { displayName });
    await subscribeToDeviceManager(callClient);

    return callAgent;
  };

  const registerToCallEvents = (callAgent) => {
    callAgent.on('callsUpdated', (e) => {
      e.added.forEach((addedCall) => {
        logger.info(`Test Call added : Call Id = ${addedCall.id}`);
        setCall(addedCall);
      });

      e.removed.forEach((removedCall) => {
        if (call && call === removedCall) {
          setCall(null);
          logger.debug(removedCall.callEndReason);
        }
      });
    });
  };

  const startCall = async (callAgent) => {
    logger.info('Joining Floor Meeting');
    await callAgent.startCall([{ id: ECHO_BOT_ID }], {});
  };

  const setupTestCall = async () => {
    startLoading(t('CONNECTING'));
    try {
      const tokenCredential = await getToken(floorMeeting.id);
      const callAgent = await createCallAgent(tokenCredential, interpreterName);
      testCallAgent.current = callAgent;
      registerToCallEvents(callAgent);
      startCall(callAgent);
    } catch (error) {
      logger.error('Error while connecting echo bot', error);
      stopLoading();
    }
  };

  const hangUp = async () => {
    logger.info('Hangup Floor Meeting');
    startLoading(t('DISCONNECTING'));
    await call?.hangUp();
    endHandCall();
  };

  const toggleMute = async () => {
    if (call.isMuted) {
      await call.unmute();
    } else {
      await call.mute();
    }
  };

  const cleanUp = async () => {
    logger.debug('Test call agent dispose');
    await testCallAgent.current?.dispose();
  };

  useEffect(() => {
    setupTestCall();
    return cleanUp;
  }, []);

  return { t, isLoading, loadingMessage, hangUp, isMuted, toggleMute };
};

export default useTestCall;
