import { Button, Flex, Slider } from '@fluentui/react-northstar';
import speakerActive from 'RESOURCES/images/speaker-active-grey.svg';
import speakerMute from 'RESOURCES/images/speaker-mute-grey.svg';
import { useTranslation } from 'SERVICES/i18n';
import useIncomingAudioController from '../useIncomingAudioController';
import useBoothIncomingAudioController from '../useBoothIncomingAudioController';

const IncomingAudioSlider = ({ isRelay = true, activeCall }) => {
  const { volume, changeIncomingVolume } = useIncomingAudioController();
  const { volumeBooth, changeIncomingVolumeBooth } = useBoothIncomingAudioController(activeCall);
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });

  return (
    <Flex gap="gap.small" vAlign="center">
      <Button
        text
        iconOnly
        icon={<img src={speakerMute} alt={t('ZERO_VOLUME')} />}
        onClick={() => (isRelay ? changeIncomingVolume(0) : changeIncomingVolumeBooth(0, !isRelay))}
        title={t('ZERO_VOLUME')}
      />
      <Slider
        min="0"
        max="0.5"
        step={0.01}
        value={isRelay ? volume : volumeBooth}
        fluid
        onChange={(e, data) =>
          isRelay
            ? changeIncomingVolume(data.value, !isRelay)
            : changeIncomingVolumeBooth(data.value, !isRelay)
        }
        className="volumeSlider pt-12"
        title={isRelay ? t('FLOOR_RELAY_AUDIO') : t('BOOTH_AUDIO')}
      />
      <Button
        text
        iconOnly
        icon={<img src={speakerActive} alt={t('FULL_VOLUME')} />}
        onClick={() =>
          isRelay ? changeIncomingVolume(0.5, !isRelay) : changeIncomingVolumeBooth(1, !isRelay)
        }
        title={t('FULL_VOLUME')}
      />
    </Flex>
  );
};

export default IncomingAudioSlider;
