// libraries
import React, { useState } from 'react';
import { FlexItem } from '@fluentui/react-northstar';

// utilities
import useActions from 'HOOKS/useActions';
import { useTranslation } from 'SERVICES/i18n';

// connectors
import {
  interpreterActions,
  selectInterpreter,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import { useSelector } from 'react-redux';

import { OUTGOING_CALL_TYPE, RELAY_INCOMING_AUDIO } from 'FEATURES/interpreter/constants';
import {
  showToast,
  TOAST_DURATION,
  TOAST_TYPE,
} from 'FEATURES/interpreter/components/CustomToastContainer';
import SplitButton from 'FEATURES/interpreter/components/SplitButton';
import { primaryLangMeetingActions, primaryLangMeetingSelector } from '../primaryLangMeetingSlice';
import {
  secondaryLangMeetingActions,
  secondaryLangMeetingSelector,
} from '../secondaryLangMeetingSlice';
import 'RESOURCES/styles/globalStyles.scss';

const OutgoingLanguageSelector = ({
  languages,
  prilangCallClient,
  secLangCallClient,
  onLanguageChange,
  updateSocketActiveLanguage,
}) => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });
  const activeIncomingAudio = useSelector(selectInterpreter.activeIncomingAudio);
  const selectedRelayInfo = useSelector(selectInterpreter.selectedRelayInfo);
  const selectedPrimaryLanguageInfo = useSelector(selectInterpreter.selectedPrimaryLanguageInfo);
  const selectedSecondaryLanguageInfo = useSelector(
    selectInterpreter.selectedSecondaryLanguageInfo
  );

  const setActiveLanguageStatus = useActions(interpreterActions.setActiveLanguageStatus);
  const setActiveLanguageCode = useActions(interpreterActions.setActiveLanguageCode);
  const activeLanguageStatus = useSelector(selectInterpreter.activeLanguageStatus);
  const { setPriCallLoader } = useActions(primaryLangMeetingActions);
  const { setSecCallLoader } = useActions(secondaryLangMeetingActions);

  const isPriLoading = useSelector(primaryLangMeetingSelector.isPriLoading);
  const isSecLoading = useSelector(secondaryLangMeetingSelector.isSecLoading);

  const [activePrimaryIndex, setActivePrimaryIndex] = useState(-1);
  const [activeSecondaryIndex, setActiveSecondaryIndex] = useState(-1);

  const items = languages.map((item) => item.language);
  const activePrimaryItem = items[activePrimaryIndex];
  const activeSecondaryItem = items[activeSecondaryIndex];

  const showNullLangError = () => {
    showToast({
      data: {
        content: t('NULL_OUTGOING_LANG_ERROR'),
        autoClose: TOAST_DURATION.long,
        type: TOAST_TYPE.error,
      },
    });
  };

  const showSimilarLangError = () => {
    showToast({
      data: {
        content: t('SIMILAR_RELAY_LANG_ERROR'),
        autoClose: TOAST_DURATION.long,
        type: TOAST_TYPE.error,
      },
    });
  };

  return (
    <>
      <FlexItem>
        <SplitButton
          button={{
            content: activePrimaryItem || '--',
          }}
          isLoading={isPriLoading}
          primary={!isPriLoading && activeLanguageStatus === OUTGOING_CALL_TYPE.primary}
          size="larger"
          menu={{
            items: items.filter(
              (item) =>
                item !== selectedPrimaryLanguageInfo?.language &&
                item !== languages[activePrimaryIndex]?.language &&
                item !== languages[activeSecondaryIndex]?.language
            ),
          }}
          onMenuItemClick={async (e, obj) => {
            // TODO - Should not match with content, instead we need to localised the code
            const newIndex = items.findIndex((item) => item === obj.content);
            if (
              activeLanguageStatus === OUTGOING_CALL_TYPE.primary &&
              activeIncomingAudio === RELAY_INCOMING_AUDIO &&
              selectedRelayInfo === languages[newIndex]
            ) {
              showSimilarLangError();
            } else {
              setPriCallLoader(true);
              await onLanguageChange(languages[newIndex], OUTGOING_CALL_TYPE.primary);
              setActiveLanguageStatus(OUTGOING_CALL_TYPE.none);
              setActiveLanguageCode(null);
              setActivePrimaryIndex(newIndex);
            }
          }}
          onMainButtonClick={() => {
            if (
              activeIncomingAudio === RELAY_INCOMING_AUDIO &&
              selectedRelayInfo === selectedPrimaryLanguageInfo
            ) {
              showSimilarLangError();
            } else if (!activePrimaryItem || !activeSecondaryItem) {
              showNullLangError();
            } else if (prilangCallClient.isCallAvailable()) {
              setActiveLanguageStatus(OUTGOING_CALL_TYPE.primary);
              setActiveLanguageCode(languages[activePrimaryIndex]);
              updateSocketActiveLanguage(languages[activePrimaryIndex].language);
            }
          }}
        />
      </FlexItem>
      <FlexItem>
        <SplitButton
          button={{
            content: activeSecondaryItem || '--',
          }}
          isLoading={isSecLoading}
          primary={!isSecLoading && activeLanguageStatus === OUTGOING_CALL_TYPE.secondary}
          size="larger"
          menu={{
            items: items.filter(
              (item) =>
                item !== selectedPrimaryLanguageInfo?.language &&
                item !== languages[activePrimaryIndex]?.language &&
                item !== languages[activeSecondaryIndex]?.language
            ),
          }}
          onMenuItemClick={async (e, obj) => {
            const newIndex = items.findIndex((item) => item === obj.content);
            if (
              activeLanguageStatus === OUTGOING_CALL_TYPE.secondary &&
              activeIncomingAudio === RELAY_INCOMING_AUDIO &&
              selectedRelayInfo === languages[newIndex]
            ) {
              showSimilarLangError();
            } else {
              setSecCallLoader(true);
              await onLanguageChange(languages[newIndex], OUTGOING_CALL_TYPE.secondary);
              setActiveLanguageStatus(OUTGOING_CALL_TYPE.none);
              setActiveLanguageCode(null);
              setActiveSecondaryIndex(newIndex);
            }
          }}
          onMainButtonClick={() => {
            if (
              activeIncomingAudio === RELAY_INCOMING_AUDIO &&
              selectedRelayInfo === selectedSecondaryLanguageInfo
            ) {
              showSimilarLangError();
            } else if (!activePrimaryItem || !activeSecondaryItem) {
              showNullLangError();
            } else if (secLangCallClient.isCallAvailable()) {
              setActiveLanguageStatus(OUTGOING_CALL_TYPE.secondary);
              setActiveLanguageCode(languages[activeSecondaryIndex]);
              updateSocketActiveLanguage(languages[activeSecondaryIndex].language);
            }
          }}
        />
      </FlexItem>
    </>
  );
};
export default OutgoingLanguageSelector;
