import {
  VideoGallery,
  ControlBar,
  MicrophoneButton,
  EndCallButton,
} from '@azure/communication-react';
import { Flex } from '@fluentui/react-northstar';
import FullScreenLoader from 'COMPONENTS/common/FullScreenLoader';

import useTestCall from './useTestCall';

const TestCall = ({ endHandCall }) => {
  const { isLoading, loadingMessage, hangUp, isMuted, toggleMute, t } = useTestCall({
    endHandCall,
  });

  if (isLoading) return <FullScreenLoader label={loadingMessage} />;
  return (
    <Flex fill column padding="padding.medium">
      <Flex fill column>
        <VideoGallery
          layout="floatingLocalVideo"
          localParticipant={{ isMuted }}
          remoteParticipants={[{ displayName: t('ECHO_BOT_NAME') }]}
        />
      </Flex>
      <Flex hAlign="center">
        <ControlBar layout="floatingBottom">
          <MicrophoneButton checked={!isMuted} onClick={toggleMute} />
          <EndCallButton onClick={hangUp} />
        </ControlBar>
      </Flex>
    </Flex>
  );
};

export default TestCall;
