import { useState, useEffect } from 'react';
import ACSCallClient from 'SERVICES/callClient/index';
import logger from 'SERVICES/logger';
import {
  CALL_RECORDER_EVENT,
  RECORDER_LANGUAGE_MEETING,
  RECORDING_TIMEOUT,
} from 'FEATURES/recorder/constants';

const { CALL_STATE, STATE_CHANGED, CALL_UPDATED } = ACSCallClient;

const RecorderLanguageCall = ({ languageCallData, teamsStream, updateServerCallId }) => {
  const [languageRecordingCall, setLanguageRecordingCall] = useState(undefined);
  const [isMuteTeamsCall, setIsMuteTeamsCall] = useState(undefined);

  useEffect(async () => {
    if (languageRecordingCall) {
      if (isMuteTeamsCall) {
        await languageRecordingCall.unmuteCall();
      } else {
        await languageRecordingCall.muteCall();
      }
    }
  }, [isMuteTeamsCall]);

  const subscribeToRemoteParticipant = (participant, callAdded) => {
    // check if participant is not already present
    participant.on(CALL_RECORDER_EVENT.PARTICIPANT_MUTED_CHANGED, async () => {
      const isAllInterpreterMuted = callAdded.remoteParticipants.every((rp) => rp.isMuted === true);
      if (isAllInterpreterMuted && callAdded) {
        setIsMuteTeamsCall(true);
      } else {
        setIsMuteTeamsCall(false);
      }
    });
  };

  const setUpRecordingLanguageCall = async () => {
    const config = {
      acsToken: languageCallData.token,
      displayName: RECORDER_LANGUAGE_MEETING,
    };
    const callClientObj = new ACSCallClient();
    await callClientObj.setupCall(config);
    setLanguageRecordingCall(callClientObj);
    callClientObj.subscribe(CALL_UPDATED, (addedCall) => {
      try {
        addedCall?.on(STATE_CHANGED, async () => {
          if (addedCall.state === CALL_STATE.connected) {
            const result = await addedCall.info.getServerCallId();
            logger.debug('serverCallId', result);

            updateServerCallId({
              language: languageCallData.languageCode,
              serverCallId: result,
            });
            setTimeout(async () => {
              if (teamsStream) {
                await addedCall.startAudio(teamsStream);
                logger.debug('Succesfully merged', teamsStream);
              }
              if (addedCall.remoteParticipants) {
                addedCall.remoteParticipants.forEach((p) => {
                  subscribeToRemoteParticipant(p, addedCall);
                });
              }
            }, RECORDING_TIMEOUT);
          }
        });

        addedCall?.on(CALL_RECORDER_EVENT.REMOTE_PARTICIPANT_UPDATED, (data) => {
          if (data.added) {
            data?.added?.forEach((p) => {
              subscribeToRemoteParticipant(p, addedCall);
            });
          }
        });
      } catch (error) {
        logger.error('Error In Recorder language call', error);
        throw error;
      }
    });
    callClientObj.joinMeeting({ groupId: languageCallData.recordingMeetingId }, { muted: false });
  };

  const callCleanup = async () => {
    logger.debug('Hanging up language call');
    await languageRecordingCall?.hangupCall();
    logger.debug('Cleaning up language call');
    await languageRecordingCall?.disposeCall();
  };

  // init
  useEffect(() => {
    setUpRecordingLanguageCall();
    return callCleanup;
  }, []);

  return null;
};

export default RecorderLanguageCall;
