import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import FullScreenLoader from 'COMPONENTS/common/FullScreenLoader';
import { Flex, FlexItem, Text } from '@fluentui/react-northstar';
import Home from 'FEATURES/home/Home';

import { interpreterActions } from 'FEATURES/interpreter/slices/interpreterSlice';
import logger from 'SERVICES/logger';
import './LandingPage.scss';
import { showToast, TOAST_TYPE } from 'FEATURES/interpreter/components/CustomToastContainer';
import { appSelector } from 'STORE/appSlice';
import { browserName, isDesktop, isMobile } from 'react-device-detect';
import { DESKTOP_SUPPORTED_BROWSER } from 'FEATURES/interpreter/constants';
import TestCall from '../TestCall';
import useLandingPage from './useLandingPage';
import { Dashboard } from '../Dashboard';
import ConfigurationForm from './components/ConfigurationForm';

import { loginRequest } from '../Authentication/authConfig';

const LandingPage = () => {
  const dispatch = useDispatch();
  const {
    t,
    ct,
    loading,
    joinMeeting,
    handleCallEnd,
    hasLanguages,
    testCall,
    isCallStarted,
    loggedOut,
  } = useLandingPage();

  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const errorTimestamp = useSelector(appSelector.errorTimestamp);

  useEffect(() => {
    if (errorTimestamp) {
      showToast({
        data: {
          content: ct('GENERIC_ERROR'),
          autoClose: false,
          type: TOAST_TYPE.error,
        },
      });
    }
  }, [errorTimestamp]);

  useEffect(() => {
    const [account] = accounts;
    // TODO: Remove below log once bug AKMSTEAMS-218 is fixed
    logger.debug(
      'isAuthenticated: ',
      isAuthenticated,
      'account: ',
      account,
      'accounts: ',
      accounts
    );
    if (isAuthenticated && account) {
      dispatch(interpreterActions.setInterpreterName(account.idTokenClaims.name));
      dispatch(interpreterActions.setInterpreterB2CId(account.idTokenClaims.sub));
    }
  }, [accounts, isAuthenticated]);

  if (loading.isLoading) return <FullScreenLoader label={loading.message} />;

  if (!hasLanguages) {
    return (
      <Flex fill hAlign="center" vAlign="center">
        <Text important content={t('NO_LANGUAGE_MEETING')} />
      </Flex>
    );
  }

  if (isCallStarted) {
    return <Dashboard handleCallEnd={handleCallEnd} />;
  }

  if (loggedOut) {
    return (
      <Flex column fill vAlign="center" hAlign="center">
        <FlexItem>
          <Text variant="large">{t('DISCONNECTED_FROM_MEETING')}</Text>
        </FlexItem>
      </Flex>
    );
  }

  if (testCall.isStarted) {
    return <TestCall endHandCall={testCall.end} />;
  }

  const homeComponent = () => {
    return <Home />;
  };

  if (isDesktop ? !DESKTOP_SUPPORTED_BROWSER.includes(browserName) : isMobile) {
    return (
      <Flex fill column hAlign="center" vAlign="center">
        <FlexItem>
          <Text align="center">{t('UNSUPPORTED_BROWSER')}</Text>
        </FlexItem>
        <FlexItem>
          <ul>
            {DESKTOP_SUPPORTED_BROWSER.map((element) => {
              return <li>{element}</li>;
            })}
          </ul>
        </FlexItem>
      </Flex>
    );
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={homeComponent}
    >
      <ConfigurationForm joinMeeting={joinMeeting} joinTestCall={testCall.start} />
    </MsalAuthenticationTemplate>
  );
};

export default LandingPage;
