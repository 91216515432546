import PropTypes from 'prop-types';

import useTheme from 'HOOKS/useTheme';
import { ReactComponent as AkouoLogoBlack } from 'RESOURCES/images/akouo-logo-black.svg';
import { ReactComponent as AkouoLogoWhite } from 'RESOURCES/images/akouo-logo-white.svg';

const AkouoLogo = ({ height, width }) => {
  const { themeMode } = useTheme();
  return themeMode === 'default' ? (
    <AkouoLogoBlack width={width} height={height} />
  ) : (
    <AkouoLogoWhite width={width} height={height} />
  );
};

AkouoLogo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

AkouoLogo.defaultProps = {
  height: undefined,
  width: undefined,
};
export default AkouoLogo;
