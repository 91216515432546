import logger from 'SERVICES/logger';
import { useEffect, useRef, useState } from 'react';
import { AUDIO_KEYWORD, INCOMING_CALL_TYPE, MEETING } from 'FEATURES/interpreter/constants';

const useIncomingAudioController = () => {
  const relayAudioRef = useRef();
  const floorAudioRef = useRef();
  const [volume, setVolume] = useState(0.5);

  // set Audio Element
  const audios = document.querySelectorAll(AUDIO_KEYWORD);

  const changeIncomingVolume = (value) => {
    setVolume(value); // value is stored in local variable so we can pass it to audioSlider UI component
    logger.debug('changeIncomingVolume.....', value);
    if (relayAudioRef.current) {
      relayAudioRef.current.volume = value;
    }
    if (floorAudioRef.current) {
      floorAudioRef.current.volume = value;
    }
  };

  const muteIncomingAudio = (type) => {
    logger.info('Muting Audio...', type);
    const audiosList = document.querySelectorAll(AUDIO_KEYWORD);
    audiosList.forEach((audioEle) => {
      if (audioEle.streamName === MEETING.relayMeeting && type === INCOMING_CALL_TYPE.relay) {
        audioEle.muted = true;
        relayAudioRef.current = audioEle;
      } else if (audioEle.streamName === MEETING.teamMeeting && type === INCOMING_CALL_TYPE.floor) {
        audioEle.muted = true;
        floorAudioRef.current = audioEle;
      }
    });
  };

  const unmuteIncomingAudio = (type) => {
    logger.info('Unmuting Audio...', type);
    if (relayAudioRef && type === INCOMING_CALL_TYPE.relay) {
      relayAudioRef.current.muted = false;
    } else if (floorAudioRef && type === INCOMING_CALL_TYPE.floor) {
      floorAudioRef.current.muted = false;
    }
  };

  useEffect(() => {
    audios.forEach((audioEle) => {
      if (audioEle.streamName === MEETING.relayMeeting) {
        relayAudioRef.current = audioEle;
      } else if (audioEle.streamName === MEETING.teamMeeting) {
        floorAudioRef.current = audioEle;
      }
    });
  }, [audios]);

  return {
    volume,
    changeIncomingVolume,
    muteIncomingAudio,
    unmuteIncomingAudio,
  };
};

export default useIncomingAudioController;
