import { Flex, FlexItem } from '@fluentui/react-northstar';
/**
 *
 * @param {content} Section title
 * @param {icon} Section icon
 * @returns Header for call control sections, this is a visual-only component
 */
const ControlHeader = ({ content, icon }) => {
  return (
    <Flex className="sectionTitle" gap="gap.medium" hAlign="center" vAlign="center">
      <FlexItem>
        <img className="icon" src={icon} alt={content} />
      </FlexItem>
      <FlexItem>
        <h5>{content}</h5>
      </FlexItem>
    </Flex>
  );
};

export default ControlHeader;
