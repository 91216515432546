import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Card, Flex, FlexItem, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'SERVICES/i18n';
import { ENTER_KEY_CODE } from 'CONSTANTS/appConstants';
import { chatSelector } from 'FEATURES/interpreter/slices/chatSlice';
import logger from 'SERVICES/logger';
import useChatClient from './useChatClient';
import './ParticipantList.scss';

const ParticipantCard = ({ participant, handleOpenChatThread }) => {
  const [unread, setUnread] = useState(false);
  const { t } = useTranslation('interpreter', { keyPrefix: 'CHAT' });

  useEffect(() => {
    setUnread(participant?.thread?.hasUnreadMessages || false);
  }, [participant?.thread?.hasUnreadMessages]);

  if (participant) {
    const { user, thread, outputLanguages, activeLanguage } = participant;
    const unreadFlag = unread;
    const isGroupChat = thread?.isGroupChat || false;
    const displayName = user.name === '' ? t('INTERPRETING_TEAM') : user.name;

    return (
      <li
        onClick={() => handleOpenChatThread(participant)}
        onKeyDown={(e) => {
          if (e.keyCode === ENTER_KEY_CODE) {
            e.preventDefault();
            handleOpenChatThread(participant);
          }
        }}
        className={`participantCard ${participant.thread ? 'cursor-pointer' : 'pointerEventsNone'}`}
        tabIndex="0"
        key={user?.id}
      >
        <Card aria-roledescription="card avatar" key={user?.id} disabled={!participant?.thread}>
          <Card.Header fitted>
            <Flex gap="gap.small">
              {/* TODO: add akouo logo as group chat avatar */}
              <Avatar name={displayName} />
              <Flex className="alignSelfCenter width30">
                <FlexItem>
                  <Text
                    content={displayName}
                    className={`darkGreyText ${unreadFlag ? 'bold' : 'light'}`}
                  />
                </FlexItem>
              </Flex>
              <FlexItem push>
                <span className="alignSelfCenter nowrap">
                  {!isGroupChat &&
                    outputLanguages?.length === 2 &&
                    outputLanguages
                      .map((language) => {
                        return (
                          <Text
                            className="darkGreyText uppercaseText"
                            weight={language === activeLanguage ? 'bold' : 'regular'}
                            // currently active language is displayed in bold
                          >
                            {language}
                          </Text>
                        );
                      })
                      .reduce((arr, next) => {
                        return arr === null
                          ? [next]
                          : [...arr, <Text className="darkGreyText"> | </Text>, next];
                      }, null)}
                </span>
              </FlexItem>
            </Flex>
          </Card.Header>
        </Card>
      </li>
    );
  }
  return null;
};

const ParticipantList = () => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'CHAT' });
  const { participantList, handleOpenChatThread } = useChatClient();
  const groupChat = useSelector(chatSelector.groupChat);
  const [sortedList, setSortedList] = useState(participantList);

  useEffect(() => {
    const participantMembers = Object.values(participantList) || [];
    participantMembers.sort((participant1, participant2) => {
      const thread1 = participant1?.thread;
      const thread2 = participant2?.thread;
      /*
      return thread2?.lastMessageTimeStamp - thread1?.lastMessageTimeStamp;
      This has been removed due to "Unsafe arithmetic operation on optional chaining" error.
      */
      if (thread1?.lastMessageTimeStamp && thread2?.lastMessageTimeStamp) {
        return thread2.lastMessageTimeStamp - thread1.lastMessageTimeStamp;
      }
      return 0;
    });
    participantMembers.unshift(groupChat);
    setSortedList(participantMembers);
    logger.debug('[THREAD-FLOW] (ParticipantList) Rendering on UI', participantMembers);
  }, [participantList, groupChat]);

  return sortedList?.length > 0 ? (
    <ul className="noListType">
      {sortedList?.map((participant) => {
        // ^ verify whether ? check can be removed
        return (
          <ParticipantCard
            key={`${participant?.user?.id}`}
            participant={participant}
            handleOpenChatThread={handleOpenChatThread}
          />
        );
      })}
    </ul>
  ) : (
    <Flex fill hAlign="center" vAlign="center">
      <Text size="medium" weight="semibold" align="center" content={t('FETCHING_CHATS')} />
    </Flex>
  );
};

export default ParticipantList;
