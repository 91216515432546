// Don't change the value
export const SNACKBAR_TYPE = Object.freeze({
  INFO: 'info',
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
});

export const SNACKBAR_DURATION = Object.freeze({
  SHORT: 1000,
  MEDIUM: 2000,
  LONG: 4000,
});

export const VIDEO_PRIORITY = Object.freeze({
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3,
});

export const THEME_MODE = Object.freeze({
  DEFAULT: 'default',
  DARK: 'dark',
  CONTRAST: 'contrast',
});

export const ENV = Object.freeze({
  PROD: 'production',
  DEV: 'dev',
  QA: 'qa',
});

export const LOGOUT_TRIGGER = Object.freeze({
  auto: 'Automatically by Application',
  manual: 'By Manual User Action',
});

export const HTML_ELEMENT_POSITION = Object.freeze({
  afterBegin: 'afterbegin',
  beforeEnd: 'beforeend',
  afterEnd: 'afterend',
});
