import { Text, Flex, FlexItem, Dropdown, Tooltip, ChatIcon } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';

import { useTranslation } from 'SERVICES/i18n';
import { ENTER_KEY_CODE } from 'CONSTANTS/appConstants';

import './LanguageSelectionDropdown.scss';

const LanguageSelectionDropdown = ({
  interpreterMeetings,
  handleLanguageSelection,
  selectedLanguage,
  teamsMeetingDetail,
  ccOn,
  setCcOn,
}) => {
  const { t } = useTranslation('teams', { keyPrefix: 'IN_MEETING' });

  const chatOnClick = () => {
    /* 
      This method will be convert joinUrl from response object into required chat Url.
      microsoftTeams.executeDeepLink() method redirects to conversation of current meeting.
    */
    const chatLink = teamsMeetingDetail.joinUrl.replace('meetup-join', 'chat');
    microsoftTeams.executeDeepLink(chatLink);
  };

  const languageDropdown = interpreterMeetings.map((lang) => {
    const obj = {
      ...lang,
      header: lang.label, // for display
      onClick: () => handleLanguageSelection(lang),
    };
    return obj;
  });

  return (
    <Flex column className="height30">
      <FlexItem>
        <Flex gap="gap.large">
          <Text
            className="bottomPadding"
            align="start"
            size="medium"
            width="50%"
            content={t('SIDE_PANEL_MUST_REMAIN_OPEN')}
          />
          <Tooltip
            content={t('TOOLTIP_MSG')}
            trigger={
              <div
                // FIXME: align=center is not allowed by eslint but removing it breaks chat icon
                // alignment. Add this as a temporary fix.
                // eslint-disable-next-line react/no-unknown-property
                align="center"
                className="cursor-pointer chatBorder"
                role="button"
                tabIndex="0"
                onClick={() => chatOnClick()}
                onKeyDown={(e) => {
                  if (e.keyCode === ENTER_KEY_CODE) {
                    e.preventDefault();
                    chatOnClick();
                  }
                }}
              >
                <ChatIcon outline size="large" />
                <Text size="small" content={t('CHAT_BTN')} />
              </div>
            }
          />
        </Flex>
      </FlexItem>
      <Text
        className="bottomPadding"
        align="center"
        size="smaller"
        content={t('INTERPRETERS_CONNECTED_WHEN_AI_IS_PARTICIPANT')}
      />
      <Flex>
        <Dropdown
          items={languageDropdown}
          id="languageSelectionDropdown"
          placeholder={t('DROPDOWN_PLACEHOLDER')}
          fluid
          value={selectedLanguage ? selectedLanguage?.label : t('DROPDOWN_PLACEHOLDER')}
        />
        {teamsMeetingDetail?.captionEvents?.length > 0 && (
          <Flex
            className={`lang-btn px-2 ${!selectedLanguage?.label && 'disabled-cc'} ${
              ccOn && 'selected-lang-btn'
            }`}
            onClick={() => {
              setCcOn(!ccOn);
            }}
          >
            <Text>CC</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default LanguageSelectionDropdown;
