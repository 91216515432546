import useActions from 'HOOKS/useActions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'SERVICES/i18n';
import { Button, Flex, FlexItem } from '@fluentui/react-northstar';
import logger from 'SERVICES/logger';
import {
  interpreterActions,
  selectInterpreter,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import { INCOMING_CALL_TYPE, FLOOR_INCOMING_AUDIO } from 'FEATURES/interpreter/constants/index';
import IncomingAudioSlider from './IncomingAudioSlider';
import RelayMeetingButton from '../../RelayMeeting/components/relayMeeting';
import useIncomingAudioController from '../useIncomingAudioController';
import BoothMeetingButton from './BoothMeetingButton';

const FloorMeetingButton = () => {
  const setActiveIncomingAudio = useActions(interpreterActions.setActiveIncomingAudio);
  const activeIncomingAudio = useSelector(selectInterpreter.activeIncomingAudio);
  const { muteIncomingAudio, unmuteIncomingAudio } = useIncomingAudioController();

  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });

  return (
    <Button
      className={`${
        activeIncomingAudio === FLOOR_INCOMING_AUDIO
          ? 'customPrimaryButton'
          : 'customSecondaryButton'
      } mx-2`}
      primary={activeIncomingAudio === FLOOR_INCOMING_AUDIO}
      content={t('FLOOR')}
      title={t('INCOMING_FLOOR_AUDIO')}
      onClick={() => {
        logger.debug('Clicked FloorMeetingButton');
        unmuteIncomingAudio(INCOMING_CALL_TYPE.floor);
        muteIncomingAudio(INCOMING_CALL_TYPE.relay);
        setActiveIncomingAudio(FLOOR_INCOMING_AUDIO);
      }}
    />
  );
};

const IncomingMediaController = () => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });
  const activeLanguageStatus = useSelector(selectInterpreter.activeLanguageStatus);
  return (
    <Flex size="size.quarter" className="mt-2 px-3 pb-1" gap="gap.small" vAlign="top">
      <FlexItem>
        <FloorMeetingButton />
      </FlexItem>
      <Flex gap="gap.small" column>
        <FlexItem>
          <RelayMeetingButton />
        </FlexItem>
        <FlexItem>
          <BoothMeetingButton />
        </FlexItem>
      </Flex>
      <Flex vAlign="center" gap="gap.small" column>
        <FlexItem size="size.half" title={t('INCOMING_RELAY_AUDIO')} column={false}>
          <IncomingAudioSlider />
        </FlexItem>
        <FlexItem size="size.half" title={t('INCOMING_BOOTH_AUDIO')} column={false}>
          <IncomingAudioSlider isRelay={false} activeCall={activeLanguageStatus} />
        </FlexItem>
      </Flex>
    </Flex>
  );
};
export default IncomingMediaController;
