import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSpeaker: undefined,
  selectedMicrophone: undefined,
  speakerDevices: [],
  microphoneDevices: [],
};

const landingPageSlice = createSlice({
  name: 'landingPage',
  initialState,
  reducers: {
    setSelectedSpeaker(state, action) {
      state.selectedSpeaker = action.payload;
    },
    setSelectedMicrophone(state, action) {
      state.selectedMicrophone = action.payload;
    },
    setSpeakerDevices(state, action) {
      state.speakerDevices = action.payload;
    },
    addSpeakerDevices(state, action) {
      const speakers = action.payload;
      state.speakerDevices = [...state.speakerDevices, ...speakers];
    },
    removeSpeakerDevices(state, action) {
      const speakers = action.payload;
      state.speakerDevices = state.speakerDevices.filter((item) => !speakers.includes(item));
    },
    setMicrophoneDevices(state, action) {
      state.microphoneDevices = action.payload;
    },
    addMicrophoneDevices(state, action) {
      const microphones = action.payload;
      state.microphoneDevices = [...state.microphoneDevices, ...microphones];
    },
    removeMicrophoneDevices(state, action) {
      const microphones = action.payload;
      state.microphoneDevices = state.microphoneDevices.filter(
        (item) => !microphones.includes(item)
      );
    },
  },
});

export default landingPageSlice.reducer;

export const landingPageActions = { ...landingPageSlice.actions };

export const landingPageSelector = {
  selectedSpeaker: (store) => store.landingPage.selectedSpeaker,
  selectedMicrophone: (store) => store.landingPage.selectedMicrophone,
  microphoneDevices: (store) => store.landingPage.microphoneDevices,
  speakerDevices: (store) => store.landingPage.speakerDevices,
};
