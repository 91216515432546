import { useRef, useState, useEffect } from 'react';
import {
  DefaultButton,
  getTheme,
  Stack,
  Slider,
  StackItem,
  IconButton,
  Spinner,
  TooltipHost,
  TooltipDelay,
} from '@fluentui/react';
import { VolumeUpIcon, AudioOffIcon } from '@fluentui/react-icons-northstar';
import { CALL_TYPE } from 'FEATURES/participant/constants';
import { STREAM_TAGS } from 'SERVICES/audioElementUtils';
import { useTranslation } from 'SERVICES/i18n';
import useAudioController from './useAudioController';
import { VOLUME_VALUES } from '../constants/enums';

const LanguageButton = ({
  languageItems,
  displayText,
  activeElement,
  languageChangeHandler,
  isLanguageLoading,
  onButtonClickHandler,
  noActiveSpeaker,
}) => {
  const theme = getTheme();
  const { t } = useTranslation('participant', { keyPrefix: 'IN_MEETING' });

  // Volume Controls
  const { changeIncomingVolume, volumeFloor, volumeLanguage } = useAudioController();

  // To store the dropdown ref
  const dropDownRef = useRef(null);

  // To check if langugae controls are open or not
  const [languageControls, setLanguageControls] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [streamVolume, setstreamVolume] = useState(VOLUME_VALUES.MAX);

  const closeDropdown = (e) => {
    const button = document.getElementById('drawerButton');

    if (
      dropDownRef.current &&
      languageControls &&
      !dropDownRef.current.contains(e.target) &&
      !button.contains(e.target)
    )
      setLanguageControls(false);
  };

  const cleanup = () => {
    document.removeEventListener('mousedown', closeDropdown);
    document.removeEventListener('oncontextmenu', closeDropdown);
  };

  const volumeChangeHandler = (volume, stream) => {
    if (stream === STREAM_TAGS.TEAMS) {
      changeIncomingVolume(volume, STREAM_TAGS.TEAMS);
      changeIncomingVolume(VOLUME_VALUES.MAX - volume, STREAM_TAGS.LANGUAGE);
    } else {
      changeIncomingVolume(volume, STREAM_TAGS.LANGUAGE);
      changeIncomingVolume(VOLUME_VALUES.MAX - volume, STREAM_TAGS.TEAMS);
    }
  };

  // changing streams and slider state
  useEffect(() => {
    if (isLanguageLoading) return;

    if (activeElement.key !== CALL_TYPE.FLOOR && noActiveSpeaker) {
      volumeChangeHandler(streamVolume, STREAM_TAGS.LANGUAGE);
      setIsDisabled(false);
    } else {
      volumeChangeHandler(VOLUME_VALUES.MIN, STREAM_TAGS.LANGUAGE);
      setIsDisabled(true);
    }
  }, [isLanguageLoading, activeElement]);

  // switching audiostream based on active interpreter's presence
  useEffect(() => {
    if (activeElement.key !== CALL_TYPE.FLOOR) {
      if (!noActiveSpeaker) {
        volumeChangeHandler(VOLUME_VALUES.MAX, STREAM_TAGS.TEAMS);
        setIsDisabled(true);
      } else {
        volumeChangeHandler(streamVolume, STREAM_TAGS.LANGUAGE);
        setIsDisabled(false);
      }
    }
  }, [noActiveSpeaker]);

  // To close dropdown on click outside
  document.addEventListener('mousedown', closeDropdown);
  document.addEventListener('oncontextmenu', closeDropdown);

  // To use as desctructor
  useEffect(() => {
    return cleanup;
  }, []);

  return (
    <Stack style={{ position: 'relative' }}>
      {languageControls && (
        <div
          id="language-list-and-controls"
          className="languageDropdown"
          style={{
            boxShadow: theme.effects.elevation16,
          }}
          ref={dropDownRef}
        >
          <ul className="languageList">
            {languageItems.map((ele) => {
              const isFloor = ele.key === CALL_TYPE.FLOOR;
              const streamType = isFloor ? STREAM_TAGS.TEAMS : STREAM_TAGS.LANGUAGE;

              return (
                <li className="languageOption" key={ele.id}>
                  <DefaultButton
                    text={ele.text}
                    style={{ border: 'none', width: '' }}
                    onClick={(event) => languageChangeHandler(event, ele)}
                  />
                  {(activeElement.key === ele.key || isFloor) && (
                    <TooltipHost
                      content={isDisabled ? t('TOOLTIP_DISABLED_TEXT') : t('TOOLTIP_TEXT')}
                      directionalHint={12}
                      delay={isDisabled ? TooltipDelay.zero : TooltipDelay.long}
                      styles={{ marginRight: '2rem' }}
                    >
                      {isLanguageLoading && ele.key !== CALL_TYPE.FLOOR ? (
                        <Spinner ariaLive="assertive" />
                      ) : (
                        <Stack
                          horizontal
                          className={`auaLanguageVolumeControls ${
                            isDisabled ? 'disabled-slider' : ''
                          }`}
                        >
                          <AudioOffIcon
                            onClick={() => {
                              if (isDisabled) return;
                              volumeChangeHandler(VOLUME_VALUES.MIN, streamType);
                              // AKMSTEAMS-727
                              if (isFloor) {
                                setstreamVolume(VOLUME_VALUES.MAX);
                              } else {
                                setstreamVolume(VOLUME_VALUES.MIN);
                              }
                            }}
                          />
                          <StackItem grow={1}>
                            <Slider
                              min={VOLUME_VALUES.MIN}
                              max={VOLUME_VALUES.MAX}
                              step={0.01}
                              value={isFloor ? volumeFloor : volumeLanguage}
                              showValue={false}
                              onChange={(e) => {
                                volumeChangeHandler(e, streamType);
                                if (isFloor) {
                                  setstreamVolume(VOLUME_VALUES.MAX - e);
                                } else {
                                  setstreamVolume(e);
                                }
                              }}
                              disabled={isDisabled}
                            />
                          </StackItem>
                          <VolumeUpIcon
                            onClick={() => {
                              if (isDisabled) return;
                              volumeChangeHandler(VOLUME_VALUES.MAX, streamType);
                              // AKMSTEAMS-727
                              if (isFloor) {
                                setstreamVolume(VOLUME_VALUES.MIN);
                              } else {
                                setstreamVolume(VOLUME_VALUES.MAX);
                              }
                            }}
                          />
                        </Stack>
                      )}
                    </TooltipHost>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <Stack horizontal>
        <DefaultButton
          text={displayText}
          style={{ paddingTop: '1.4em', paddingBottom: '1.4em', borderRight: 'none' }}
          onClick={() => onButtonClickHandler()}
          disabled={isLanguageLoading}
        />
        <IconButton
          id="drawerButton"
          className="drawerButton"
          iconProps={{ iconName: 'ChevronDownMed' }}
          style={{ padding: '1.4em', border: '1px solid grey' }}
          onClick={() => setLanguageControls(!languageControls)}
          disabled={isLanguageLoading}
        />
      </Stack>
    </Stack>
  );
};

export default LanguageButton;
