import serverAPI from 'SERVICES/serverAPI';
import { API } from '../constants';

export const fetchACSAccessToken = async (userId) => {
  const { data } = await serverAPI.post(API.fetchAcsToken, { userId });
  return data.token;
};

export const getInterpreterDetails = async () => {
  const { data } = await serverAPI.get(API.fetchInterpreterDetails);
  return data;
};

export const createAcsIdForChat = async () => {
  const { data } = await serverAPI.post(API.createInterpreterIdentity, { includeToken: true });
  return data;
};

export const updateCallDetails = async (callUpdateData) => {
  const { data } = await serverAPI.post(API.updateCallDetails, { callUpdateData });
  return data;
};

export const updateTeamsCallId = async (callUpdateData) => {
  const { data } = await serverAPI.post(API.updateTeamsCallId, { callUpdateData });
  return data;
};
