import { API_CONTEXT } from 'CONSTANTS/apiConstants';
import serverAPI from 'SERVICES/serverAPI';
import { API } from './constants';

/**
 * Fetch Meeting details from backend.
 * @param {String} meetingId: Teams Meeting Id
 * @param {?String} code: Authentication code received from auth code flow
 * @returns response data
 */
export const fetchMeetingDetailsAPI = async (meetingId, code) => {
  const url = `${API.MEETINGS}/${meetingId}/${API_CONTEXT.teams}`;
  const response = await serverAPI.get(url, { params: { code } });
  return response.data;
};

/**
 *
 * @param {string} meetingId: Teams Meeting Id
 * @param {[string]} languages:
 * @returns
 */
export const submitInterpretationData = async (interpretationData) => {
  return serverAPI.put(API.MEETINGS, interpretationData);
};
