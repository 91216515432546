import { CallClient } from '@azure/communication-calling';

const acsDeviceManager = (deviceManager) => {
  const askAudioDevicePermission = async () => deviceManager.askDevicePermission({ audio: true });

  const isSpeaker = (device) => device.deviceType === 'Speaker';
  const isNullSpeaker = (device) => device.id === 'speaker:';
  const isMicrophone = (device) => device.deviceType === 'Microphone';
  const isNullMicrophone = (device) => device.id === 'microphone:';

  const getSpeakers = async () => {
    const speakers = await deviceManager.getSpeakers();
    if (speakers && !isNullSpeaker(speakers[0])) return speakers;
    return [];
  };

  const getMicrophones = async () => {
    const microphones = await deviceManager.getMicrophones();
    if (microphones && !isNullMicrophone(microphones[0])) return microphones;
    return [];
  };

  const getSelectedSpeaker = () => {
    if (!isNullSpeaker(deviceManager.selectedSpeaker)) return deviceManager.selectedSpeaker;
    return null;
  };

  const getSelectedMicrophone = () => {
    if (!isNullMicrophone(deviceManager.selectedMicrophone))
      return deviceManager.selectedMicrophone;
    return null;
  };

  const setSpeakerDevice = async (speaker) => {
    if (speaker && getSelectedSpeaker()?.id !== speaker.id) {
      await deviceManager.selectSpeaker(speaker);
    }
  };

  const setMicrophoneDevice = async (microphone) => {
    if (microphone && getSelectedMicrophone()?.id !== microphone.id) {
      await deviceManager.selectMicrophone(microphone);
    }
  };

  /**
   * @callback OnSelectedChangeCallback
   * @param  {object} selectedDevice - selected device
   */
  /**
   * @param {OnSelectedChangeCallback} callback
   */
  const onSelectedSpeakerChanged = async (callback) => {
    deviceManager.on('selectedSpeakerChanged', () => {
      callback(getSelectedSpeaker());
    });
  };

  /**
   * @param {OnSelectedChangeCallback} callback
   */
  const onSelectedMicrophoneChanged = (callback) => {
    deviceManager.on('selectedMicrophoneChanged', () => {
      callback(getSelectedMicrophone());
    });
  };

  /**
   * @callback OnDevicesChangeCallback
   * @param  {object} Devices - devices} listener
   */
  /**
   * Listen to audio devices update
   * @param {Object} listener - Subscribe function for audioDevicesUpdated
   * @param {OnDevicesChangeCallback} listener.onSpeakerAdded - Subscribe function for speaker added
   * @param {OnDevicesChangeCallback} listener.onSpeakerRemoved - Subscribe function for speaker removed
   * @param {OnDevicesChangeCallback} listener.onMicrophoneAdded - Subscribe function for microphone added
   * @param {OnDevicesChangeCallback} listener.onMicrophoneRemoved - Subscribe function for microphone removed
   */
  const onAudioDevicesUpdated = (listener) => {
    deviceManager.on('audioDevicesUpdated', (e) => {
      // Emit onSpeakerAdded
      const addedSpeakers = e.added.filter((device) => isSpeaker(device) && !isNullSpeaker(device));
      listener?.onSpeakerAdded?.(addedSpeakers);

      // Emit onMicrophoneAdded
      const addedMicrophones = e.added.filter(
        (device) => isMicrophone(device) && !isNullMicrophone(device)
      );
      listener?.onMicrophoneAdded?.(addedMicrophones);

      // Emit onSpeakerRemoved
      const removedSpeakers = e.removed.filter((device) => isSpeaker(device));
      listener?.onSpeakerRemoved?.(removedSpeakers);

      // Emit onMicrophoneRemoved
      const removedMicrophones = e.removed.filter((device) => isMicrophone(device));
      listener?.onMicrophoneRemoved?.(removedMicrophones);
    });
  };

  return {
    askAudioDevicePermission,
    getSpeakers,
    getMicrophones,
    getSelectedSpeaker,
    getSelectedMicrophone,
    setSpeakerDevice,
    setMicrophoneDevice,
    onSelectedSpeakerChanged,
    onSelectedMicrophoneChanged,
    onAudioDevicesUpdated,
  };
};

const acsUtils = {
  createCallClient: () => new CallClient(),

  getDeviceManager: async (callClient) => {
    const deviceManager = await callClient.getDeviceManager();
    return acsDeviceManager(deviceManager);
  },
};

export default acsUtils;
