import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'SERVICES/i18n';

import { getLanguageNames, getLanguageList } from 'UTILS/languagesUtils';

import { Flex, Text, Dropdown, Button, Checkbox } from '@fluentui/react-northstar';
import {
  selectInterpretationLanguages,
  teamsMeetingSelector,
} from 'FEATURES/teams/slices/teamsMeetingSlice';

import './PreMeetingForm.scss';
import useSnackbar from 'HOOKS/useSnackbar';
import { SNACKBAR_TYPE } from 'CONSTANTS/enum';
import { COPY_ELEMENT, LANGUAGES_SELECTED } from 'FEATURES/teams/constants';

const PreMeetingForm = ({ handleSubmit, handleCancelButton, copyToClip }) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation('teams', { keyPrefix: 'PREMEETING' });
  const [copyButtonText, setCopyButtonText] = useState(t('COPY_LINK_FOR_PARTICIPANTS'));

  const selectedInterpretationLanguages = useSelector(selectInterpretationLanguages);
  const teamsMeetingDetail = useSelector(teamsMeetingSelector.teamsMeetingDetail);
  const [selectedLanguages, setSelectedLanguages] = useState(
    getLanguageNames(selectedInterpretationLanguages)
  );
  const [isChecked, setIsChecked] = useState(!!teamsMeetingDetail?.universalAccessEnabled);
  const interpretationLanguages = getLanguageList();
  const languages = useMemo(
    () =>
      interpretationLanguages.map((lang) => ({
        header: lang.label,
        value: lang.value,
        key: lang.value,
      })),
    []
  );

  const getA11ySelectionMessage = {
    onAdd: (item) => setSelectedLanguages((prevArray) => [...prevArray, item]),
    onRemove: (item) =>
      setSelectedLanguages((prevArray) =>
        prevArray.filter((lang) => lang.label !== item && lang.value !== item.value)
      ),
  };

  const onSubmit = () => {
    const langCodes = selectedLanguages.map((item) => item.value);
    const updatedData = [];
    const isPartialUpdate = teamsMeetingDetail.interpretation.interpreterMeetings.length > 0;
    langCodes.forEach((lang) => {
      const existData = teamsMeetingDetail.interpretation.interpreterMeetings.find(
        (ele) => ele.language === lang
      );
      if (existData) {
        updatedData.push({
          language: existData.language,
          id: existData.id,
        });
      } else {
        updatedData.push({ language: lang });
      }
    });

    return selectedLanguages.length < LANGUAGES_SELECTED.minimum
      ? showSnackbar({
          message: t('MINIMUM_LANGUAGES', { languageCount: LANGUAGES_SELECTED.minimum }),
          type: SNACKBAR_TYPE.DANGER,
        })
      : handleSubmit({
          languages: langCodes,
          updatedLanguages: updatedData,
          universalAccessEnabled: isChecked,
          isPartialUpdate,
        });
  };

  useEffect(() => {
    setCopyButtonText(t('COPY_LINK_FOR_PARTICIPANTS'));
  }, [isChecked]);

  return (
    <Flex fill column className={COPY_ELEMENT.ORGANIZER_EDITABLE_FORM_CONTAINER}>
      <Text
        className="meeting-title"
        content={t('CONFIGURE_INTERPRETATION_LANGUAGES')}
        weight="bold"
      />

      <Text className="dropdown-label" content={t('ADD_LANGUAGES_LABEL')} />
      <Dropdown
        items={languages}
        placeholder={t('CHOOSE_LANG_DROPDOWN_MSG')}
        multiple
        getA11ySelectionMessage={getA11ySelectionMessage}
        noResultsMessage={t('LANGUAGE_NOT_FOUND')}
        // defaultActiveSelectedIndex={2}
        defaultValue={selectedLanguages.map((lang) => lang.label)}
        search
        fluid
        inverted
      />
      <Flex column className="pt-4">
        <Checkbox
          checked={isChecked}
          onChange={(e, { checked }) => {
            setIsChecked(checked);
          }}
          className="dropdown-label"
          label={t('AUA_TITLE')}
        />
        <Text>{t('AUA_DESCRIPTION1')}</Text>
        <Text content={t('AUA_POINT1')} className="auaListItem" />
        <Text content={t('AUA_POINT2')} className="auaListItem" />
        <Text className="pt-4">{t('AUA_DESCRIPTION2')}</Text>
        {isChecked && (
          <Flex className="my-2">
            <Button content={copyButtonText} secondary onClick={copyToClip} />
          </Flex>
        )}
      </Flex>
      <Flex gap="gap.medium" className="pt-4">
        <Button content={t('SUBMIT_LANG_REQ_BTN')} primary onClick={onSubmit} />
        <Button content={t('CANCEL_LANG_REQ_BTN')} onClick={handleCancelButton} />
      </Flex>
    </Flex>
  );
};

PreMeetingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancelButton: PropTypes.func.isRequired,
};

export default PreMeetingForm;
