export const ORIGIN = window.location.origin;
export const APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CONSENT_STATE_KEY = 'Graph-consent-state';

export const API = {
  GRAPH_TOKEN: '/auth/token',
  MEETINGS: '/teams/meetings',
  ACS_ACCESS_TOKEN: '/teams/identity/access-token',
};

export const USER_ROLE = Object.freeze({
  ORGANIZER: 1,
  PARTICIPANT: 2,
  EXTERNAL: 3,
  ANONYMOUS: 4,
});

export const LANGUAGES_SELECTED = Object.freeze({
  minimum: 2,
});

export const CALL_TYPE = {
  FLOOR: 'floor',
};

export const COPY_ELEMENT = Object.freeze({
  EXEC_COMMAND_COPY: 'copy',
  ORGANIZER_EDITABLE_PRE_FORM_CONTAINER: 'pre-meeting-container',
  ORGANIZER_EDITABLE_FORM_CONTAINER: 'form-container',
  CLIPBOARD_COPY_TEXT_SELECTOR_ID: 'copyContents',
});
