import { useRef, useEffect } from 'react';
// libraries
import { useSelector } from 'react-redux';

// redux store
import useActions from 'HOOKS/useActions';

// utilities
import logger from 'SERVICES/logger';
import ACSCallClient from 'SERVICES/callClient/index';
import { fetchACSAccessToken } from 'FEATURES/interpreter/api/api';
import { useParams } from 'react-router-dom';

import {
  interpreterActions,
  selectInterpreter,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import { getAudioElements } from '../../utils/audioElementUtils';
import {
  primaryLangMeetingSelector as selector,
  primaryLangMeetingActions,
} from './primaryLangMeetingSlice';
// constants
import {
  MEETING,
  LANGUAGE_MEETING_DISPLAY_NAME,
  LANGUAGE_RECORDING_MEETING_DISPLAY_NAME,
  TIMEOUT_FOR_CALL_ID,
} from '../../constants/index';

const usePrimaryLanguageMeeting = () => {
  const prilangCallClient = useSelector(selector.prilangCallClient);
  const selectedSpeaker = useSelector(selector.selectedSpeaker);
  const selectedMicrophone = useSelector(selector.selectedMicrophone);
  const interpreterDetails = useSelector(selectInterpreter.interpreterDetails);
  const priRecordingLangCallClient = useSelector(selector.priRecordingLangCallClient);
  const { meetingId } = useParams();

  // TODO: check redux state and ref issue for dispose() and hangup() issue;
  const acsCallObjectRef = useRef();
  const acsRecordingCallObjectRef = useRef();
  const { CALL_STATE, STATE_CHANGED, CALL_UPDATED } = ACSCallClient;

  const {
    setInterpreterId,
    setDeviceManager,
    setSelectedSpeaker,
    setSelectedMicrophone,
    setAcsToken,
    setPriLangCallClientObject,
    setPriCallLoader,
    setPriRecordingLangCallClientObject,
    setPrimaryLanguageCallData,
    setPrimaryRecordingLanguageCallData,
  } = useActions(primaryLangMeetingActions);
  const setSelectedPrimaryLanguageInfo = useActions(
    interpreterActions.setSelectedPrimaryLanguageInfo
  );
  useEffect(() => {
    acsCallObjectRef.current = prilangCallClient;
  }, [prilangCallClient]);

  useEffect(() => {
    acsRecordingCallObjectRef.current = priRecordingLangCallClient;
  }, [priRecordingLangCallClient]);

  const subscribeToDeviceManager = async (callClientObj) => {
    const deviceManager = await callClientObj.getACSDeviceManager();
    await deviceManager.askAudioDevicePermission();
    setDeviceManager(deviceManager);

    if (selectedSpeaker) await deviceManager.setSpeakerDevice(selectedSpeaker);
    else setSelectedSpeaker(deviceManager.getSelectedSpeaker());

    if (selectedMicrophone) await deviceManager.setMicrophoneDevice(selectedMicrophone);
    else setSelectedMicrophone(deviceManager.getSelectedMicrophone());

    deviceManager.onSelectedSpeakerChanged((speaker) => setSelectedSpeaker(speaker));
    deviceManager.onSelectedMicrophoneChanged((mic) => setSelectedMicrophone(mic));
  };

  const setupPrimaryLanguageMeeting = async () => {
    if (interpreterDetails) {
      const acsId = interpreterDetails.primaryACSId;
      try {
        logger.info('Setup Language Meeting');
        const token = await fetchACSAccessToken(acsId);
        setInterpreterId(acsId);
        setAcsToken(token);
        const config = {
          acsToken: token,
          displayName: LANGUAGE_MEETING_DISPLAY_NAME,
        };
        const callClientObj = new ACSCallClient();
        await callClientObj.setupCall(config);
        setPriLangCallClientObject(callClientObj);
        await subscribeToDeviceManager(callClientObj);

        callClientObj.subscribe(CALL_UPDATED, (addedCall) => {
          try {
            addedCall?.on(STATE_CHANGED, () => {
              if (addedCall.state === CALL_STATE.connected) {
                setPriCallLoader(false);
              }
            });
          } catch (error) {
            logger.error('Error In Primary Language call Update', error);
            throw error;
          }
        });
      } catch (error) {
        logger.error('Error in Primary Language Meeting Setup', error);
        throw error;
      }
    }
  };

  const hangUpPrimaryLanguageMeeting = async () => {
    try {
      logger.info('hangup language meeting');
      setAcsToken(null);
      if (prilangCallClient && prilangCallClient.getCallState() !== CALL_STATE.disconnected) {
        await prilangCallClient?.hangupCall();
        setSelectedPrimaryLanguageInfo(null);
      }
    } catch (error) {
      logger.error('Error while hangup Primary language meeting', error);
      throw error;
    }
  };

  const disposePrimaryLanguageMeeting = async () => {
    try {
      logger.debug('Disposing language Meeting call Agent');
      await acsCallObjectRef?.current?.disposeCall();
      setInterpreterId(null);
    } catch (error) {
      logger.error('Error while disposing Primary Language Meeting', error);
      throw error;
    }
  };

  const joinPrimaryLanguageMeeting = async (languageInfo) => {
    try {
      // lang meeting
      logger.info('Joining Language Meeting');

      if (prilangCallClient?.getCallState() !== CALL_STATE.disconnected) {
        await hangUpPrimaryLanguageMeeting();
      }

      if (acsCallObjectRef.current) {
        acsCallObjectRef.current.joinMeeting({ groupId: languageInfo.id });

        const primaryLanguageMeetingAudioTag = await getAudioElements(
          MEETING.primaryLanguageMeeting
        );
        logger.debug([primaryLanguageMeetingAudioTag], 'Primary language Meeting Audio Tag');
        await acsCallObjectRef.current.muteIncomingCall();
        // Adding timeout as callId will be reflected after few seconds
        setTimeout(() => {
          const updateCallData = {
            meetingId,
            language: languageInfo.language,
            callId: acsCallObjectRef.current.call?.id,
          };
          setPrimaryLanguageCallData(updateCallData);
        }, TIMEOUT_FOR_CALL_ID);
      }
    } catch (error) {
      logger.error('Error while joining Primary Language Meeting', error);
      throw error;
    }
  };

  const setupPrimaryRecordingLanguageMeeting = async () => {
    if (interpreterDetails) {
      const acsId = interpreterDetails.primaryRecordingACSId;
      try {
        logger.info('Setup Recording Language Meeting');
        const token = await fetchACSAccessToken(acsId);
        const config = {
          acsToken: token,
          displayName: LANGUAGE_RECORDING_MEETING_DISPLAY_NAME,
        };
        const callClientObj = new ACSCallClient();
        await callClientObj.setupCall(config);
        setPriRecordingLangCallClientObject(callClientObj);
        await subscribeToDeviceManager(callClientObj);

        callClientObj.subscribe(CALL_UPDATED, (addedCall) => {
          try {
            addedCall?.on(STATE_CHANGED, () => {
              logger.debug('Joined Recording Primary language meeting');
            });
          } catch (error) {
            logger.error('Error In Primary Recording Language call Update', error);
            throw error;
          }
        });
      } catch (error) {
        logger.error('Error in Primary Recording Language Meeting Setup', error);
        throw error;
      }
    }
  };

  const hangUpPrimaryRecordingLanguageMeeting = async () => {
    try {
      logger.info('hangup recording language meeting');
      setAcsToken(null);
      if (
        priRecordingLangCallClient &&
        priRecordingLangCallClient.getCallState() !== CALL_STATE.disconnected
      ) {
        await priRecordingLangCallClient?.hangupCall();
      }
    } catch (error) {
      logger.error('Error while hangup Primary recording language meeting', error);
      throw error;
    }
  };

  const disposePrimaryRecordingLanguageMeeting = async () => {
    try {
      logger.debug('Disposing recording language Meeting call Agent');
      await acsRecordingCallObjectRef?.current?.disposeCall();
    } catch (error) {
      logger.error('Error while disposing recording Primary Language Meeting', error);
      throw error;
    }
  };

  const joinPrimaryRecordingLanguageMeeting = async (languageInfo) => {
    try {
      logger.info('Joining Recording Language Meeting');
      if (priRecordingLangCallClient?.getCallState() !== CALL_STATE.disconnected) {
        await hangUpPrimaryRecordingLanguageMeeting();
      }

      if (acsRecordingCallObjectRef.current) {
        acsRecordingCallObjectRef.current.joinMeeting({ groupId: languageInfo.recordingMeetingId });
        const primaryRecordingLanguageMeetingAudioTag = await getAudioElements(
          MEETING.primaryRecordingLanguageMeeting
        );
        logger.debug(
          [primaryRecordingLanguageMeetingAudioTag],
          'Primary Recording language Meeting Audio Tag'
        );
        await acsRecordingCallObjectRef.current.muteIncomingCall();
        // Adding timeout as callId will be reflected after few seconds
        setTimeout(() => {
          const updateData = {
            meetingId,
            language: languageInfo.language,
            recordingCallId: acsRecordingCallObjectRef.current.call?.id,
          };
          setPrimaryRecordingLanguageCallData(updateData);
        }, TIMEOUT_FOR_CALL_ID);
      }
    } catch (error) {
      logger.error('Error while joining recording Primary Language Meeting', error);
      throw error;
    }
  };

  return {
    setupPrimaryLanguageMeeting,
    joinPrimaryLanguageMeeting,
    hangUpPrimaryLanguageMeeting,
    disposePrimaryLanguageMeeting,
    prilangCallClient,
    setupPrimaryRecordingLanguageMeeting,
    joinPrimaryRecordingLanguageMeeting,
    hangUpPrimaryRecordingLanguageMeeting,
    disposePrimaryRecordingLanguageMeeting,
    priRecordingLangCallClient,
  };
};

export default usePrimaryLanguageMeeting;
