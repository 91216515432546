import logger from 'SERVICES/logger';
import { AUDIO_STREAM_NAME } from '../constants';

const checkIfNewAudioElementPresent = (streamName) => {
  const audios = document.querySelectorAll('audio');
  let newAudioELe = null;
  if (audios.length !== 0) {
    audios.forEach((audioEle) => {
      // check if AUDIO_STREAM_NAME property present or not
      if (audioEle[AUDIO_STREAM_NAME] === undefined) {
        logger.debug('Found new audio element', [audioEle]);
        audioEle[AUDIO_STREAM_NAME] = streamName;
        // TODO : remove time tag in v1.3
        audioEle.TIME = new Date().toString();
        newAudioELe = audioEle;
      }
    });
  }
  return newAudioELe;
};

export const getAudioElements = (streamName) => {
  // TODO: reject after some time-out
  return new Promise((resolve) => {
    const targetNode = document.querySelector('body');
    const audioElement = checkIfNewAudioElementPresent(streamName);
    if (audioElement) {
      resolve(audioElement);
      return;
    }
    const config = { childList: true };
    let observer = null;
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          logger.debug('A child node has been added or removed.');
          const audioEle = checkIfNewAudioElementPresent(streamName);
          if (audioEle) {
            resolve(audioEle);
            observer.disconnect();
          }
        }
      });
    };

    // Create an observer instance linked to the callback function
    observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  });
};
