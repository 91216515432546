import { Dialog, Loader, Flex } from '@fluentui/react-northstar';
import { useTranslation } from 'SERVICES/i18n';
import './ServiceErrorOverlay.scss';

const ServiceErrorOverlay = ({ open }) => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });
  return (
    <Dialog
      backdrop
      header={t('NETWORK_ERROR_HOLD_ON')}
      content={
        <Flex column>
          <div>{t('NETWORK_ERROR_INTERFACE_DISABLED')}</div>
          <Loader />
        </Flex>
      }
      className="connectionErrorDialog"
      open={open}
    />
  );
};

export default ServiceErrorOverlay;
