import { meeting } from '@microsoft/teams-js';
import logger from 'SERVICES/logger';

let requiredIncomingAudioMuteState = false;
let isToggleIncomingAudioPending = false;

const toggleIncomingClientAudio = () => {
  if (!isToggleIncomingAudioPending) {
    isToggleIncomingAudioPending = true;
    meeting.toggleIncomingClientAudio((error, result) => {
      isToggleIncomingAudioPending = false;
      if (error) {
        logger.error('Error while toggling Incoming Client Audio', error.message);
        return;
      }
      logger.debug(`Teams Audio mute state successfully changed to : ${result}`);
      if (requiredIncomingAudioMuteState !== result) {
        toggleIncomingClientAudio();
      }
    });
  }
};

/**
 *
 * @param {Boolean} mute True means incoming audio will be muted and false means incoming audio will be unmuted
 */
export const changeTeamsIncomingAudio = (mute) => {
  if (requiredIncomingAudioMuteState !== mute) {
    requiredIncomingAudioMuteState = mute;
    toggleIncomingClientAudio();
  }
};

export const unmuteTeamsIncomingAudio = () => {
  meeting.getIncomingClientAudioState((error, state) => {
    if (error) {
      logger.error('Error while getting Incoming Client Audio State', error.message);
      return;
    }
    requiredIncomingAudioMuteState = state;
    changeTeamsIncomingAudio(false); // unmute the team audio
  });
};
