import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  interpreterId: null,
  deviceManager: undefined,
  selectedSpeaker: undefined,
  selectedMicrophone: undefined,
  acsToken: undefined,
  prilangCallClient: null,
  incomingAudioElement: undefined,
  isPriLoading: false,
  priRecordingLangCallClient: null,
  primaryLanguageCallData: undefined,
  primaryRecordingLanguageCallData: undefined,
};

const primaryLangMeetingSlice = createSlice({
  name: 'primaryLangMeeting',
  initialState,
  reducers: {
    setInterpreterId(state, action) {
      state.interpreterId = action.payload;
    },
    setDeviceManager(state, action) {
      state.deviceManager = action.payload;
    },
    setSelectedMicrophone(state, action) {
      state.selectedMicrophone = action.payload;
    },
    setSelectedSpeaker(state, action) {
      state.selectedSpeaker = action.payload;
    },
    setAcsToken(state, action) {
      state.acsToken = action.payload;
    },
    setPriLangCallClientObject(state, action) {
      state.prilangCallClient = action.payload;
    },
    setPriRecordingLangCallClientObject(state, action) {
      state.priRecordingLangCallClient = action.payload;
    },
    setIncomingAudioElement(state, action) {
      state.incomingAudioElement = action.payload;
    },
    setPriCallLoader(state, action) {
      state.isPriLoading = action.payload;
    },
    setPrimaryLanguageCallData(state, action) {
      state.primaryLanguageCallData = action.payload;
    },
    setPrimaryRecordingLanguageCallData(state, action) {
      state.primaryRecordingLanguageCallData = action.payload;
    },
  },
});

export default primaryLangMeetingSlice.reducer;

export const primaryLangMeetingActions = { ...primaryLangMeetingSlice.actions };

// selectors
export const primaryLangMeetingSelector = {
  interpreterId: (state) => state.primaryLangMeeting.interpreterId,
  deviceManager: (store) => store.primaryLangMeeting.deviceManager,
  selectedSpeaker: (store) => store.primaryLangMeeting.selectedSpeaker,
  selectedMicrophone: (store) => store.primaryLangMeeting.selectedMicrophone,
  acsToken: (state) => state.primaryLangMeeting.acsToken,
  prilangCallClient: (state) => state.primaryLangMeeting.prilangCallClient,
  incomingAudioElement: (state) => state.primaryLangMeeting.incomingAudioElement,
  isPriLoading: (state) => state.primaryLangMeeting.isPriLoading,
  priRecordingLangCallClient: (state) => state.primaryLangMeeting.priRecordingLangCallClient,
  primaryLanguageCallData: (state) => state.primaryLangMeeting.primaryLanguageCallData,
  primaryRecordingLanguageCallData: (state) =>
    state.primaryLangMeeting.primaryRecordingLanguageCallData,
};
