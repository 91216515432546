import { useState } from 'react';

import logger from 'SERVICES/logger';
import { getIdToken } from './authentication';

const useTeamsAuthentication = () => {
  const [loading, setLoading] = useState(false);
  const [consentProvided, setConsentProvided] = useState(false);

  const authenticate = async () => {
    setLoading(true);
    try {
      const idToken = await getIdToken(false);
      logger.debug('Got SSO Token: ', idToken);
      setConsentProvided(true);
      setLoading(false);
    } catch (error) {
      logger.error('Error: unable to sign in', error);
      setLoading(false);
    }
  };

  return {
    isAuthorized: consentProvided,
    loading,
    authenticate,
  };
};

export default useTeamsAuthentication;
