import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'SERVICES/i18n';
import { useParams } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import useActions from 'HOOKS/useActions';
import {
  selectInterpreter,
  interpreterActions,
} from 'FEATURES/interpreter/slices/interpreterSlice';
import logger from 'SERVICES/logger';
import useLoader from 'HOOKS/useLoader';
import { createAcsIdForChat } from 'FEATURES/interpreter/api/api';

const useLandingPage = () => {
  const { meetingId } = useParams();
  const [isCallStarted, setCallStarted] = useState(false);
  const [isTestCallStarted, setIsTestCallStarted] = useState(false);
  const { t } = useTranslation('interpreter', { keyPrefix: 'LANDING_PAGE' });
  const ct = useTranslation('translation', { keyPrefix: 'COMMON' }).t;
  const { isLoading, loadingMessage, startLoading, stopLoading } = useLoader({ isLoading: true });
  const [loggedOut, setLoggedOut] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  const languageMeetings = useSelector(selectInterpreter.languageMeetings);
  const interpreterB2CId = useSelector(selectInterpreter.interpreterB2CId);
  const fetchMeetingDetail = useActions(interpreterActions.fetchMeetingDetailActions);
  const setErrorStatus = useActions(interpreterActions.setErrorStatus);
  const setChatInfo = useActions(interpreterActions.setChatInfo);
  const prepareForMeeting = async () => {
    try {
      if (meetingId) {
        startLoading(t('LOADING_MESSAGE'));
        if (isAuthenticated && interpreterB2CId) {
          const result = await Promise.all([
            fetchMeetingDetail({ meetingId, interpreterB2CId }).unwrap(),
            createAcsIdForChat(),
          ]);
          setChatInfo(result[1]);
        }
      }
    } catch (error) {
      await setErrorStatus(true);
      logger.error(error);
    }
    stopLoading();
  };

  useEffect(async () => {
    prepareForMeeting();
  }, [meetingId, isAuthenticated, interpreterB2CId]);

  const joinMeeting = () => {
    setCallStarted(true);
  };

  const handleCallEnd = () => {
    setCallStarted(false);
    setLoggedOut(true);
  };

  const startTestCall = () => {
    setIsTestCallStarted(true);
  };

  const endTestCall = () => {
    setIsTestCallStarted(false);
  };

  return {
    t,
    ct,
    loading: {
      isLoading,
      message: loadingMessage,
    },
    isCallStarted,
    loggedOut,
    handleCallEnd,
    joinMeeting,
    testCall: {
      isStarted: isTestCallStarted,
      start: startTestCall,
      end: endTestCall,
    },
    hasLanguages: !!languageMeetings,
  };
};

export default useLandingPage;
