const ROUTES = Object.freeze({
  HOME: '/',
  TAB: '/tab',
  TAB_CONFIG: '/config',
  CLOSE_POPUP: '/auth-end',
  CONSENT_POPUP: '/auth-start',
  INTERPRETER: '/interpreter',
  DASHBOARD: '/interpreter/dashboard',
  PARTICIPANT: '/participant',
  RECORDER: '/recording',
});

export default ROUTES;
