import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  interpreterId: null,
  deviceManager: undefined,
  selectedSpeaker: undefined,
  selectedMicrophone: undefined,
  acsToken: undefined,
  secLangCallClient: null,
  incomingAudioElement: undefined,
  isSecLoading: false,
  secRecordingLangCallClient: null,
  secondaryLanguageCallData: undefined,
  secondaryRecordingLanguageCallData: undefined,
};

const secondaryLangMeetingSlice = createSlice({
  name: 'secondaryLangMeeting',
  initialState,
  reducers: {
    setInterpreterId(state, action) {
      state.interpreterId = action.payload;
    },
    setDeviceManager(state, action) {
      state.deviceManager = action.payload;
    },
    setSelectedMicrophone(state, action) {
      state.selectedMicrophone = action.payload;
    },
    setSelectedSpeaker(state, action) {
      state.selectedSpeaker = action.payload;
    },
    setSecLangCallClientObject(state, action) {
      state.secLangCallClient = action.payload;
    },
    setIncomingAudioElement(state, action) {
      state.incomingAudioElement = action.payload;
    },
    setSecCallLoader(state, action) {
      state.isSecLoading = action.payload;
    },
    setSecLangRecordingCallClient(state, action) {
      state.secRecordingLangCallClient = action.payload;
    },
    setSecondaryLanguageCallData(state, action) {
      state.secondaryLanguageCallData = action.payload;
    },
    setSecondaryRecordingLanguageCallData(state, action) {
      state.secondaryRecordingLanguageCallData = action.payload;
    },
  },
});

export default secondaryLangMeetingSlice.reducer;

export const secondaryLangMeetingActions = { ...secondaryLangMeetingSlice.actions };

// selectors
export const secondaryLangMeetingSelector = {
  interpreterId: (state) => state.secondaryLangMeeting.interpreterId,
  deviceManager: (store) => store.secondaryLangMeeting.deviceManager,
  selectedSpeaker: (store) => store.secondaryLangMeeting.selectedSpeaker,
  selectedMicrophone: (store) => store.secondaryLangMeeting.selectedMicrophone,
  secLangCallClient: (state) => state.secondaryLangMeeting.secLangCallClient,
  incomingAudioElement: (state) => state.secondaryLangMeeting.incomingAudioElement,
  isSecLoading: (state) => state.secondaryLangMeeting.isSecLoading,
  secRecordingLangCallClient: (state) => state.secondaryLangMeeting.secRecordingLangCallClient,
  secondaryLanguageCallData: (state) => state.secondaryLangMeeting.secondaryLanguageCallData,
  secondaryRecordingLanguageCallData: (state) =>
    state.secondaryLangMeeting.secondaryRecordingLanguageCallData,
};
