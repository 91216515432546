// libraries
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'SERVICES/i18n';
import { useSelector } from 'react-redux';

// utilities
import logger from 'SERVICES/logger';

// constants
import { ENTER_KEY_CODE } from 'CONSTANTS/appConstants';

// components
import { Avatar, Chat, ChatMessage, Flex, FlexItem, Text } from '@fluentui/react-northstar';
import TextareaAutosize from 'react-textarea-autosize';
import { SendIcon, ChevronStartIcon } from '@fluentui/react-icons-northstar';

// store
import { selectInterpreter } from 'FEATURES/interpreter/slices/interpreterSlice';
// hooks
import useChatClient from './useChatClient';

// styles
import './ChatThread.scss';

const ChatThread = ({ socketConnected }) => {
  const { handleCloseChatThread, sendNewMessage, currentThread, currentThreadMessages } =
    useChatClient();
  // #region selectors
  const chatInfo = useSelector(selectInterpreter.chatInfo);
  // #endregion

  // #region state variables
  const [msgError, setMsgError] = useState(!socketConnected);
  const [threadError, setThreadError] = useState(false);
  const [content, setContent] = useState('');
  const [messages, setMessages] = useState([]);
  // #endregion

  const { t } = useTranslation('interpreter', { keyPrefix: 'CHAT' });

  // #region send a new message

  /**
   *
   * @param {timestamp} converts timestamp to "DD Mon HH:MM" in 12 hour format
   * @returns
   */
  const getFormattedDate = (timestamp) => {
    const options = { hour: 'numeric', minute: 'numeric' };
    const formattedDateTime = Intl.DateTimeFormat('en-US', options).format(timestamp);
    return formattedDateTime;
  };

  /**
   * Convert received message details to ChatMessage compliant format
   */
  const assignMessageDetails = () => {
    if (currentThreadMessages?.length > 0) {
      const data = currentThreadMessages?.map((message) => {
        const timestamp = message.createdOn;
        const formattedTime = getFormattedDate(timestamp);
        //
        const uiFormattedMessage = {
          key: message.id,
          time: timestamp,
          message: (
            <ChatMessage
              author={message.senderId === chatInfo.userId ? t('YOU') : message.senderDisplayName}
              content={message.content}
              timestamp={formattedTime}
              mine={message.senderId === chatInfo.userId}
            />
          ),
        };
        return uiFormattedMessage;
      });
      setMessages(
        // slice removes duplicates in case of repeated calls
        data.slice().sort((msg1, msg2) => {
          if (msg1?.time && msg2?.time) return msg1.time - msg2.time;
          return 0;
        })
      );
    }
  };

  useEffect(() => {
    logger.debug('setting msgError from socket connection status', socketConnected);
    setMsgError(!socketConnected);
  }, [socketConnected]);

  const onSendMessage = async () => {
    // To prevent sending multiple messages untill it sends one message.
    const msgContent = content;
    setContent('');
    if (msgContent?.trim().length > 0) {
      const msg = await sendNewMessage(content);
      if (msg) {
        setMsgError(false);
      } else {
        logger.debug('onSendMessage handleSend failed', msg);
        setMsgError(true);
      }
    }
  };

  const onChange = (e) => {
    if (socketConnected) {
      setContent(e.target.value);
      setMsgError(false);
    }
  };

  const sendOnEnter = (e) => {
    if (e.keyCode === ENTER_KEY_CODE && socketConnected) {
      e.preventDefault();
      if (content !== '') onSendMessage(e);
    }
  };
  // #endregion

  // #region useEffect hooks

  /**
   * On opening a chat or receiving new msg in currently open thread,
   * scroll to the last msg received
   */
  useEffect(() => {
    const assignMessageDetailsAsync = async () => {
      if (messages) {
        try {
          await assignMessageDetails();
          document.querySelector('.ui-chat').scrollTop =
            document?.querySelector('.ui-chat')?.scrollHeight;
          setThreadError(false);
        } catch (error) {
          logger.error(error);
          setThreadError(t('ERROR_FETCHING_MESSAGES'));
        }
      }
    };
    assignMessageDetailsAsync();
  }, [currentThreadMessages]);

  // #endregion

  // #region UI
  const displayName = currentThread.thread?.isGroupChat
    ? t('INTERPRETING_TEAM')
    : currentThread.user.name;

  return (
    <>
      <FlexItem>
        <Flex padding="padding.medium" className="pt-3 chatNameBackground" vAlign="center">
          <FlexItem size="size.quarter" vAlign="center" className="pt-3">
            <ChevronStartIcon
              onClick={handleCloseChatThread}
              onKeyDown={(e) => {
                if (e.keyCode === ENTER_KEY_CODE) {
                  handleCloseChatThread();
                }
              }}
              tabIndex="0"
              className="cursor-pointer mr-0 click-effect iconMiniButton"
            />
          </FlexItem>
          <Avatar name={displayName} />
          <Text weight="bold" className="mt-1 mx-5 darkGreyText alignSelfBaseline">
            {displayName}
          </Text>
        </Flex>
      </FlexItem>
      <FlexItem>
        <Flex className="messageThread">
          {threadError ? (
            <Text error content={t('ERROR_FETCHING_MESSAGES')} />
          ) : (
            <Chat className="mr-5" items={messages} density="compact" />
          )}
        </Flex>
      </FlexItem>
      <FlexItem push>
        <Flex fill className="sendBox pl-2">
          <TextareaAutosize
            autoFocus
            disable={msgError}
            id="sendbox"
            onChange={onChange}
            onKeyDown={sendOnEnter}
            placeholder={t('TYPE_A_NEW_MESSAGE')}
            className={msgError ? 'inputError' : null} // To do handle error in case of socket disconnection
            value={content}
          />
          <FlexItem push vAlign="center">
            <SendIcon
              disable={msgError}
              outline
              tabIndex="0"
              role="button"
              onClick={onSendMessage}
              className="iconMiniButton"
            />
          </FlexItem>
        </Flex>
      </FlexItem>
    </>
  );
  // #endregion
};

export default ChatThread;
