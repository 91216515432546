import PropTypes from 'prop-types';
import { Flex } from '@fluentui/react-northstar';

import useTheme from 'HOOKS/useTheme';
import { ReactComponent as SignInDark } from 'RESOURCES/images/sign-in-dark.svg';
import { ReactComponent as SignInLight } from 'RESOURCES/images/sign-in-light.svg';

const MicrosoftSignInButton = ({ onClick, className }) => {
  const { themeMode } = useTheme();
  return (
    <Flex className={`cursor-pointer ${className}`} padding="padding.medium">
      {themeMode === 'default' ? (
        <SignInLight onClick={onClick} />
      ) : (
        <SignInDark onClick={onClick} />
      )}
    </Flex>
  );
};

MicrosoftSignInButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MicrosoftSignInButton.defaultProps = {
  className: '',
};

export default MicrosoftSignInButton;
