import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from 'RESOURCES/languages/en.json';
import ptTranslations from 'RESOURCES/languages/pt.json';
import frTranslations from 'RESOURCES/languages/fr.json';
import deTranslations from 'RESOURCES/languages/de.json';
import esTranslations from 'RESOURCES/languages/es.json';

export { useTranslation } from 'react-i18next';
// the translations
// (manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// Additional resources are added in useAppInitializer.jsx
const resources = {
  en: {
    translation: enTranslations,
  },
  pt: {
    translation: ptTranslations,
  },
  fr: {
    translation: frTranslations,
  },
  de: {
    translation: deTranslations,
  },
  es: {
    translation: esTranslations,
  },
};
export const addI18nResources = (nsName, languages) => {
  i18n.addResourceBundle('en', nsName, languages.en, true, true);
  i18n.addResourceBundle('pt', nsName, languages.pt, true, true);
  i18n.addResourceBundle('fr', nsName, languages.fr, true, true);
  i18n.addResourceBundle('de', nsName, languages.de, true, true);
  i18n.addResourceBundle('es', nsName, languages.es, true, true);
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: { order: ['navigator'] },
    fallbackLng: 'en',
    resources,
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
