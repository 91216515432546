import { useSelector } from 'react-redux';
import { useFluentContext } from '@fluentui/react-northstar';

const useTheme = () => {
  const themeMode = useSelector((store) => store.app.themeMode);
  const { theme } = useFluentContext();
  return { themeMode, theme };
};

export default useTheme;
