import useActions from 'HOOKS/useActions';
import { floorMeetingActions as actions } from '../floorMeetingSlice';

const useFloorDeviceManager = () => {
  const setSelectedSpeakerAction = useActions(actions.setSelectedSpeaker);

  return {
    setSelectedSpeaker: (speaker) => setSelectedSpeakerAction(speaker),
  };
};

export default useFloorDeviceManager;
