import { useEffect, useState } from 'react';
import { useTranslation } from 'SERVICES/i18n';
import { CallClient } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

import logger from 'SERVICES/logger';
import serverAPI from 'SERVICES/serverAPI';
import useSnackbar from 'HOOKS/useSnackbar';
import { SNACKBAR_TYPE } from 'CONSTANTS/enum';
import { API } from 'FEATURES/teams/constants';
import { changeTeamsIncomingAudio } from 'FEATURES/teams/utils/teamsMeetingUtils';

const useTeamsAcs = () => {
  const showSnackbar = useSnackbar();
  const [call, setCall] = useState(null);
  const [loading, setLoading] = useState(false);
  const [callAgent, setCallAgent] = useState(null);
  const [acsSetupCompleted, setAcsSetupCompleted] = useState(null);
  const [remoteParticipants, setRemoteParticipants] = useState([]);
  const { t } = useTranslation('teams', { keyPrefix: 'COMMON' });

  const subscribeToRemoteParticipant = (participant) => {
    // check if participant is not already present
    if (!remoteParticipants.find((p) => p === participant)) {
      setRemoteParticipants((prevState) => [...prevState, participant]);
    }

    participant.on('stateChanged', () => {
      if (participant.state === 'Connected') {
        setRemoteParticipants((prevState) => [...prevState]);
      }
    });

    participant.on('isMutedChanged', () => {
      const isAllInterpreterMuted = call.remoteParticipants.every((rp) => rp.isMuted === true);
      changeTeamsIncomingAudio(!isAllInterpreterMuted); // false -> then mute else unmute
    });

    if (participant.isMuted === false) {
      // muted
      changeTeamsIncomingAudio(true);
    }
  };

  useEffect(() => {
    if (call) {
      const callStateChanged = () => {
        const { state } = call;
        logger.debug(`ACS: call state changed to ${state}`);
        if (['Connected', 'Disconnected'].includes(state)) setLoading(false);
        else if (state === 'Connecting') setLoading(t('CONNECTING'));
        else if (state === 'Disconnecting') setLoading(t('DISCONNECTING'));
      };

      callStateChanged();
      call.on('stateChanged', callStateChanged);

      call.remoteParticipants.forEach((rp) => subscribeToRemoteParticipant(rp));

      call.on('remoteParticipantsUpdated', (e) => {
        e.added.forEach((p) => {
          logger.debug('participantAdded', p);
          subscribeToRemoteParticipant(p);
        });
        e.removed.forEach((p) => {
          logger.debug('participantRemoved', p);
          if (p.callEndReason) {
            logger.error(`Remote participant ${p.identifier} 
            disconnected: code: ${p.callEndReason.code}, subCode: ${p.callEndReason.subCode}.`);
          }
          setRemoteParticipants((preState) =>
            preState.filter((remoteParticipant) => remoteParticipant !== p)
          );
          if (p.isMuted === false) {
            changeTeamsIncomingAudio(false); // un-mute team audio
          }
        });
      });
    }
  }, [call]);

  const getAcsToken = async (userId) => {
    const response = await serverAPI.post(API.ACS_ACCESS_TOKEN, { userId });
    return response.data.token;
  };

  const getDeviceManagerPermission = async (callClient) => {
    const deviceManager = await callClient.getDeviceManager();
    logger.debug('Device manager created now waiting for permission');
    await deviceManager.askDevicePermission({ audio: true });
    logger.debug('Returned from permission and good to go');
    setAcsSetupCompleted(true);
  };

  const setupLanguageCall = async (callClient, userId) => {
    const token = await getAcsToken(userId);
    const tokenCredential = new AzureCommunicationTokenCredential(token);
    const callAgentLocal = await callClient.createCallAgent(tokenCredential, {
      displayName: 'Participant', // generic name to all participant because we are using same userId for all participant in a meeting
    });

    callAgentLocal.on('callsUpdated', (e) => {
      e.added.forEach((_call) => {
        setCall(_call);
      });

      e.removed.forEach((callItem) => {
        if (call && call === callItem) {
          logger.error('Call Ended with reason ', call.callEndReason);
        }
      });
    });
    setCallAgent(callAgentLocal);
  };
  const loginToAcs = async (userId) => {
    const callClient = new CallClient();

    try {
      await Promise.all([
        getDeviceManagerPermission(callClient),
        setupLanguageCall(callClient, userId),
      ]);
    } catch (err) {
      logger.error(`Error occured in Promise ${err}`);
    }
  };

  const joinInterpreterMeeting = async (groupId) => {
    try {
      setLoading(t('CONNECTING'));
      const callOptions = {
        audioOptions: {
          muted: true,
        },
      };
      await callAgent.join({ groupId }, callOptions);
    } catch (e) {
      logger.error(`Failed to join a call ${e}`);
      showSnackbar({ message: 'Fail to join the meeting', type: SNACKBAR_TYPE.DANGER }); // hardcode string
    }
  };

  const disconnectInterpreterMeeting = async () => {
    if (call) {
      setLoading(t('DISCONNECTING'));
      changeTeamsIncomingAudio(false); // unmute the team audio
      await call.hangUp();
    }
  };

  return {
    loginToAcs,
    joinInterpreterMeeting,
    disconnectInterpreterMeeting,
    loading,
    acsSetupCompleted,
    callAgent,
  };
};

export default useTeamsAcs;
