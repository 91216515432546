import { Flex, Loader } from '@fluentui/react-northstar';

const FullScreenLoader = ({ label }) => {
  return (
    <Flex fill hAlign="center" vAlign="center">
      <Loader label={label} />
    </Flex>
  );
};

export default FullScreenLoader;
