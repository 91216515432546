import React from 'react';
import { Loader, SplitButton as Button } from '@fluentui/react-northstar';
import { useTranslation } from 'SERVICES/i18n';

const SplitButton = ({
  menu,
  primary,
  button,
  size,
  onMainButtonClick,
  onMenuItemClick,
  isLoading,
}) => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });
  const loader = isLoading && <Loader size="smallest" labelPosition="below" />;
  return (
    <Button
      menu={menu}
      primary={primary}
      size={size}
      button={{
        content: loader || button.content,
        styles: { width: '4rem', ':hover': 'black' },
        'aria-roledescription': 'splitbutton',
        'aria-describedby': 'instruction-message',
        className: `${primary ? 'customPrimaryButton' : 'customSecondaryButton'}`,
      }}
      toggleButton={{
        'aria-label': 'more options',
        className: `${primary ? 'customPrimaryButton' : 'customSecondaryButton'}`,
      }}
      onMainButtonClick={() => onMainButtonClick()}
      onMenuItemClick={(e, key) => onMenuItemClick(e, key)}
      title={t('SPLIT_BUTTON_INSTRUCTIONS')}
    />
  );
};

export default SplitButton;
