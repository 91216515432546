import logger from 'SERVICES/logger';
import { useEffect, useRef, useState } from 'react';
import {
  AUDIO_KEYWORD,
  INCOMING_CALL_TYPE,
  MEETING,
  OUTGOING_CALL_TYPE,
} from 'FEATURES/interpreter/constants';

const useBoothIncomingAudioController = (activeLanguage) => {
  const boothAudioRef = useRef();

  const [volumeBooth, setVolume] = useState(1);

  // set Audio Element
  const audios = document.querySelectorAll(AUDIO_KEYWORD);

  const changeIncomingVolumeBooth = (value, type) => {
    setVolume(value); // value is stored in local variable so we can pass it to audioSlider UI component
    logger.debug('changeIncomingVolume.....', value);
    if (type && boothAudioRef.current) {
      boothAudioRef.current.volume = value;
    }
  };

  const muteIncomingAudioBooth = (type) => {
    logger.info('Muting Audio...', type);
    const audiosList = document.querySelectorAll(AUDIO_KEYWORD);
    audiosList.forEach((audioEle) => {
      if (
        (type === INCOMING_CALL_TYPE.booth &&
          activeLanguage === OUTGOING_CALL_TYPE.primary &&
          audioEle.streamName === MEETING.primaryLanguageMeeting) ||
        (activeLanguage === OUTGOING_CALL_TYPE.secondary &&
          audioEle.streamName === MEETING.secondaryLanguageMeeting)
      ) {
        audioEle.muted = true;
        boothAudioRef.current = audioEle;
      }
    });
  };

  const unmuteIncomingAudioBooth = (type) => {
    logger.info('Unmuting Audio...', type);
    if (boothAudioRef.current && type === INCOMING_CALL_TYPE.booth) {
      boothAudioRef.current.muted = false;
    }
  };

  useEffect(() => {
    audios.forEach((audioEle) => {
      if (
        activeLanguage === OUTGOING_CALL_TYPE.primary &&
        audioEle.streamName === MEETING.primaryLanguageMeeting
      ) {
        boothAudioRef.current = audioEle;
      }
      if (
        activeLanguage === OUTGOING_CALL_TYPE.secondary &&
        audioEle.streamName === MEETING.secondaryLanguageMeeting
      ) {
        boothAudioRef.current = audioEle;
      }
    });
    logger.debug('boothAudioref', boothAudioRef.current?.streamName);
  }, [audios]);

  return {
    volumeBooth,
    changeIncomingVolumeBooth,
    muteIncomingAudioBooth,
    unmuteIncomingAudioBooth,
  };
};

export default useBoothIncomingAudioController;
