import ROUTES from 'CONSTANTS/routes';
import Tab from '../features/Tab/Tab';
import TabConfig from '../features/TabConfig/TabConfig';
import ClosePopup from '../features/Authentication/ClosePopup';
import ConsentPopup from '../features/Authentication/ConsentPopup';

const teamsRoutes = [
  { path: ROUTES.TAB, element: <Tab /> },
  { path: ROUTES.TAB_CONFIG, element: <TabConfig /> },
  { path: ROUTES.CONSENT_POPUP, element: <ConsentPopup /> },
  { path: ROUTES.CLOSE_POPUP, element: <ClosePopup /> },
];

export default teamsRoutes;
