import useActions from 'HOOKS/useActions';
import { relayMeetingActions as actions } from '../relayMeetingSlice';

const useRelayDeviceManager = () => {
  const [setSelectedSpeakerAction] = useActions([actions.setSelectedSpeaker]);

  return {
    setSelectedSpeaker: (speaker) => setSelectedSpeakerAction(speaker),
  };
};

export default useRelayDeviceManager;
