import { useState } from 'react';

/**
 * A hooks to store and update the loader states
 * @returns loader states
 */
const useLoader = (
  { message: defaultMessage, isLoading: defaultIsLoading } = { message: '', isLoading: false }
) => {
  const [isLoading, setIsLoading] = useState(defaultIsLoading);
  const [loadingMessage, setLoadingMessage] = useState(defaultMessage);

  /**
   * This function will set isLoading=true and loadingMessage to message you send as a argument
   * @param {string} [message=''] Loading Message (optional)
   */
  const startLoading = (message = '') => {
    setLoadingMessage(message);
    setIsLoading(true);
  };

  const stopLoading = () => {
    setLoadingMessage('');
    setIsLoading(false);
  };

  const changeLoadingMessage = (message) => {
    if (isLoading) setLoadingMessage(message);
  };

  return { loadingMessage, startLoading, stopLoading, isLoading, changeLoadingMessage };
};

export default useLoader;
