const utils = {
  getId(identifier) {
    switch (identifier.kind) {
      case 'communicationUser':
        return identifier.communicationUserId;
      case 'phoneNumber':
        return identifier.phoneNumber;
      case 'microsoftTeamsUser':
        return identifier.microsoftTeamsUserId;
      case 'unknown':
        return identifier.id;
      default:
        return identifier.id; // can be undefined
    }
  },
  getStreamId(userId, stream) {
    return `${userId}-${stream.id}-${stream.mediaStreamType}`;
  },
};

export default utils;
