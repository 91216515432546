import { useEffect, useState } from 'react';
import { useTranslation } from 'SERVICES/i18n';

import logger from 'SERVICES/logger';
import useActions from 'HOOKS/useActions';
import useSnackbar from 'HOOKS/useSnackbar';
import { ERROR } from 'CONSTANTS/apiConstants';
import { SNACKBAR_TYPE } from 'CONSTANTS/enum';
import useTeams from 'FEATURES/teams/hooks/useTeams';
import { fetchMeetingDetailsAPI } from 'FEATURES/teams/api';
import { checkInterpretationIsSupported } from 'FEATURES/teams/utils/acsUtils';
import meetingLocalStorage from 'FEATURES/teams/utils/meetingLocalStorage';
import { teamsMeetingActions } from 'FEATURES/teams/slices/teamsMeetingSlice';
import { getLanguageList } from 'UTILS/languagesUtils';

import { CALL_TYPE } from 'FEATURES/teams/constants';
import useTeamsAcs from './useTeamsAcs';

const addLanguageLabelKey = (languages, interpretationLanguages) => {
  return languages.map((lang) => {
    const item = interpretationLanguages.find((_item) => lang.language === _item.value);
    return {
      id: lang.id,
      label: item.label,
      value: lang.language,
    };
  });
};

const useInMeeting = () => {
  const { t } = useTranslation('teams', { keyPrefix: 'IN_MEETING' });
  const { context } = useTeams();
  const showSnackbar = useSnackbar();
  const [showCountDownTimer, setCountDownTimer] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [showUnsupportedPopup, setUnsupportedPopup] = useState(false);
  const [interpreterMeetings, setInterpreterMeetings] = useState([
    {
      id: CALL_TYPE.FLOOR,
      label: t('NO_INTERPRETATION_OPTION'),
      value: CALL_TYPE.FLOOR,
    },
  ]);
  const [showUnsupportedDialog, setShowUnsupportedDialog] = useState(false); // Showing Interpretation unsupported dialog
  const setMeetingDetails = useActions(teamsMeetingActions.setMeetingDetails);

  const interpretationLanguages = getLanguageList();

  const {
    loginToAcs,
    joinInterpreterMeeting,
    disconnectInterpreterMeeting,
    acsSetupCompleted,
    loading,
    callAgent,
  } = useTeamsAcs();

  const handleLanguageSelection = async (lang) => {
    if (lang.value === CALL_TYPE.FLOOR) {
      setSelectedLanguage(null);
      if (selectedLanguage) {
        await disconnectInterpreterMeeting();
        meetingLocalStorage.remove();
      }
    } else if (selectedLanguage?.value !== lang.value) {
      if (selectedLanguage) {
        await disconnectInterpreterMeeting();
      }
      setSelectedLanguage(lang);
      await joinInterpreterMeeting(lang.id);
      meetingLocalStorage.save(context.meetingId, context.userObjectId, lang.value);
    }
  };

  const connectToPreviousJoinMeetingIfAny = async (meetingId, userId, languagesFetched) => {
    const languageSelected = meetingLocalStorage.get(meetingId, userId);
    if (languageSelected) {
      const language = languagesFetched.find((lang) => lang.value === languageSelected);
      if (language) await handleLanguageSelection(language);
    }
  };

  useEffect(() => {
    // AKMSTEAMS-1203: added callAgent so that it will join when we have
    if (acsSetupCompleted && interpreterMeetings && context && callAgent) {
      // after acs setup complete do this
      connectToPreviousJoinMeetingIfAny(
        context.meetingId,
        context.userObjectId,
        interpreterMeetings
      );
    }
  }, [acsSetupCompleted, interpreterMeetings, context, callAgent]);

  const initializeInMeeting = async ({ meetingId }) => {
    try {
      const meetingDetails = await fetchMeetingDetailsAPI(meetingId);
      setMeetingDetails(meetingDetails);
      const { interpretation } = meetingDetails;
      const languages = interpretation.interpreterMeetings;
      if (languages.length !== 0) {
        const meetingsWithLabel = addLanguageLabelKey(languages, interpretationLanguages);
        // AKMSTEAMS-461
        // meetingsWithLabel.push({
        //   id: CALL_TYPE.FLOOR,
        //   label: t('NO_INTERPRETATION_OPTION'),
        //   value: CALL_TYPE.FLOOR,
        // });
        setInterpreterMeetings([...meetingsWithLabel, ...interpreterMeetings]);

        if (checkInterpretationIsSupported(context.hostClientType)) {
          if (meetingsWithLabel.length !== 0) {
            setCountDownTimer(true);
            await loginToAcs(interpretation.participantId);
            setCountDownTimer(false);
          }
        } else {
          setShowUnsupportedDialog(true);
        }
      }
    } catch (error) {
      if ([ERROR.MEETING_NOT_FOUND, ERROR.INVALID_MEETING_REQUEST].includes(error?.code)) {
        // No languages found for this meeting. Do nothing
        logger.debug('New Meeting: No information is present on server');
      } else {
        logger.error('Error while initializing in-meeting', error);
        showSnackbar({ message: error.message, type: SNACKBAR_TYPE.DANGER });
      }
    }
  };

  useEffect(() => {
    if (context) initializeInMeeting(context);
  }, [context]);

  const handleUnsupportedInterpretation = () => setUnsupportedPopup(true);

  return {
    t,
    loading,
    selectedLanguage,
    showCountDownTimer,
    interpreterMeetings,
    setUnsupportedPopup,
    showUnsupportedPopup,
    handleLanguageSelection: showUnsupportedDialog
      ? handleUnsupportedInterpretation
      : handleLanguageSelection,
  };
};

export default useInMeeting;
