import { Flex, FlexItem, Text } from '@fluentui/react-northstar';
import { ReactComponent as HangUpIcon } from 'RESOURCES/images/hangup-white.svg';
import { ENTER_KEY_CODE } from 'CONSTANTS/appConstants';

import { useTranslation } from 'SERVICES/i18n';

const HangUpButton = ({ onClick }) => {
  const { t } = useTranslation('interpreter', { keyPrefix: 'DASHBOARD' });
  return (
    <Flex
      column
      className="m-1 p-2 click-effect cursor-pointer redButton verticalCenter"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.keyCode === ENTER_KEY_CODE) {
          onClick();
        }
      }}
      tabIndex="0"
      title={t('HANGUP')}
    >
      <FlexItem>
        <HangUpIcon />
      </FlexItem>
      <FlexItem>
        <Text size="smaller" className="uppercaseText whiteText">
          {t('HANGUP')}
        </Text>
      </FlexItem>
    </Flex>
  );
};

export default HangUpButton;
