import React from 'react';
import { Text, Flex, Loader } from '@fluentui/react-northstar';

import AkouoLogo from 'COMPONENTS/common/AkouoLogo';
import MicrosoftSignInButton from 'COMPONENTS/common/MicrosoftSignInButton';

import HyperLink from 'COMPONENTS/common/HyperLink';
import { AKOUO_CONNECT_URL, AKOUO_SUPPORT_URL } from 'CONSTANTS/appConstants';
import useTabConfig from './useTabConfig';

const TabConfig = () => {
  const { t, isAuthorized, loading, authenticate } = useTabConfig();

  if (loading) {
    return (
      <Flex fill hAlign="center" vAlign="center" column gap="gap.smaller">
        <Loader label={t('LOADING_TEAMS_ACCOUNT')} />
      </Flex>
    );
  }

  return (
    <Flex fill hAlign="center" vAlign="center" column gap="gap.smaller">
      <AkouoLogo width="200px" />
      <Text content={t('WELCOME_MESSAGE')} size="larger" />
      <Text content={t('TAGLINE')} size="larger" />
      {!isAuthorized && <Text content={t('AUTH_ERROR_MSG')} align="center" />}
      <Text size="small">
        {t('CONNECT_LINK')}
        <HyperLink link={AKOUO_CONNECT_URL} />
      </Text>
      <Text size="small">
        {t('SUPPORT_LINK')}
        <HyperLink link={AKOUO_SUPPORT_URL} />
      </Text>
      {!isAuthorized && <MicrosoftSignInButton onClick={authenticate} />}
    </Flex>
  );
};

export default TabConfig;
