// libraries
import { useSelector } from 'react-redux';

// hooks
import useActions from 'HOOKS/useActions';

// store
import { chatSelector, chatActions } from 'FEATURES/interpreter/slices/chatSlice';
import { selectInterpreter } from 'FEATURES/interpreter/slices/interpreterSlice';
import logger from 'SERVICES/logger';
import { Participant } from 'FEATURES/interpreter/utils/participant';

const useChatClient = () => {
  // #region selectors
  const currentThread = useSelector(chatSelector.currentThread);
  const currentThreadMessages = useSelector(chatSelector.currentThreadMessages);
  const unreadThreads = useSelector(chatSelector.unreadThreads);
  const groupChat = useSelector(chatSelector.groupChat);
  const participantList = useSelector(chatSelector.participantList);
  const chatInfo = useSelector(selectInterpreter.chatInfo);

  const {
    initialSetAllMessages,
    addMessageToCurrentThread,
    setOpenThread,
    notifyUnreadMessages,
    setGroupChat,
    updateParticipantList,
    resetUnreadMessage,
  } = useActions(chatActions);

  const interpreterName = useSelector(selectInterpreter.interpreterName);
  // #endregion

  const createGroupParticipant = () => {
    const groupParticipant = new Participant({
      user: { id: 'group', name: '' },
    });

    return groupParticipant;
  };
  const saveGroupChatParticipant = () => {
    setGroupChat(createGroupParticipant());
    logger.debug('[THREAD-FLOW] (useChatClient) Setting group chat');
  };

  const addThreadToParticipant = async (threadObj) => {
    // Checking if it is group chat
    logger.debug(
      '[THREAD-FLOW] (useChatClient) Adding thread to participant. Thread:',
      threadObj,
      'ParticipantList:',
      participantList
    );

    if (threadObj.isGroupChat) {
      try {
        const groupParticipant = createGroupParticipant();
        groupParticipant.updateThread(threadObj);
        setGroupChat(groupParticipant);
        logger.debug('[THREAD-FLOW] (useChatClient) Setting group chat after thread updation');
      } catch (error) {
        logger.debug('Error : could not set groupchat thread', error);
      }
    } else {
      const participantListCopy = { ...participantList };
      const existingMember = participantListCopy[threadObj.user.id];
      logger.debug(
        '[THREAD-FLOW] (useChatClient) Found participant for the thread',
        existingMember
      );
      if (existingMember) {
        existingMember.updateThread(threadObj);
        logger.debug('[THREAD-FLOW] (useChatClient) Updating participantlist after adding thread');
        updateParticipantList(participantListCopy);
      }
    }
  };

  // Store data about all other interpreters
  const onNewParticipantListReceived = async (newParticipantList) => {
    logger.debug('[THREAD-FLOW] (useChatClient) Rest user list received', newParticipantList);
    // Removing self data and add rest of the participants
    const restUsers = newParticipantList.filter((member) => member.userId !== chatInfo.userId);
    if (restUsers.length > 0) {
      const newMembers = {};
      restUsers.forEach((userData) => {
        const participant = new Participant({
          user: { id: userData.userId, name: userData.name },
        });
        participant.updateActiveLanguage(userData.activeLanguage);
        participant.updateOutputLanguage(userData.boothLanguages);
        newMembers[userData.userId] = participant;
      });
      logger.debug('[THREAD-FLOW] (useChatClient) Setting participantlist', newMembers);
      updateParticipantList(newMembers);
    }
  };

  const handleStatusUpdate = async (updatedInterpreter) => {
    const existingParticipant = participantList[updatedInterpreter?.userId];
    if (existingParticipant) {
      const participantListCopy = { ...participantList };
      existingParticipant.updateActiveLanguage(updatedInterpreter.activeLanguage);
      existingParticipant.updateOutputLanguage(updatedInterpreter.boothLanguages);
      participantListCopy[updatedInterpreter.userId] = existingParticipant;
      updateParticipantList(participantListCopy);
    }
  };

  const addParticipantToList = async (userData) => {
    logger.debug('[THREAD-FLOW] (useChatClient) New user joined', userData);
    const participantListCopy = { ...participantList };
    participantListCopy[userData.userId] = new Participant({
      user: { id: userData.userId, name: userData.name },
    });

    logger.debug(
      '[THREAD-FLOW] (useChatClient) Updating participantlist after adding new participant',
      participantListCopy
    );
    updateParticipantList(participantListCopy);
  };

  const removeParticipantFromList = async (userData) => {
    logger.debug('[THREAD-FLOW] (useChatClient) User left meeting', userData);
    const participantListCopy = { ...participantList };
    delete participantListCopy[userData.userId];
    logger.debug(
      '[THREAD-FLOW] (useChatClient) Updating participantlist after deleting participant',
      participantListCopy
    );
    updateParticipantList(participantListCopy);
  };
  // #endregion

  // #region msgScreen

  const getAllMessagesForParticipant = async (participant) => {
    participant.thread?.updateUnreadMessage(false);
    try {
      const msgs = await participant.thread.getAllMessages();
      await initialSetAllMessages(msgs);
    } catch (error) {
      logger.debug('Error : could not get all messages', error);
    }
  };

  const sendNewMessage = async (msgContent) => {
    try {
      await currentThread.thread.sendMessageRequest(msgContent, interpreterName);
      return true;
    } catch (error) {
      logger.debug('Error : could not send the message', error);
      return false;
    }
  };

  const updateThreadForParticipant = async (msgObject) => {
    logger.debug('[THREAD-FLOW] (useChatClient) Updating thread for message', msgObject);
    const participantThreadList = Object.values(participantList);
    const existingParticipant = participantThreadList.find(
      (participantElement) => participantElement?.thread?.threadId === msgObject?.threadId
    );
    if (existingParticipant) {
      const participantListCopy = { ...participantList };
      existingParticipant.thread?.updateTimestamp(msgObject.createdOn);
      existingParticipant.thread?.updateUnreadMessage(true);
      participantListCopy[existingParticipant.user.id] = existingParticipant;
      logger.debug(
        '[THREAD-FLOW] (useChatClient) Updating participantlist after updating timestamp & unread status',
        participantListCopy
      );
      updateParticipantList(participantListCopy);
    } else if (groupChat && groupChat?.thread.threadId === msgObject?.threadId) {
      const groupParticipant = createGroupParticipant();
      groupChat.thread.updateTimestamp(msgObject.createdOn);
      groupChat.thread.updateUnreadMessage(true);
      groupParticipant.updateThread(groupChat.thread);
      logger.debug(
        '[THREAD-FLOW] (useChatClient) Setting group chat after updating timestamp & unread status'
      );
      setGroupChat(groupParticipant);
    }
    logger.debug(`Sorting to move ${msgObject?.senderDisplayName} to top`);
  };

  // callback
  const handleNewMessageReceived = async (msgObject) => {
    try {
      if (currentThread && msgObject?.threadId === currentThread?.thread?.threadId) {
        /* a chat thread is currently open && 
    threadId of the received msg matches threadId of the open chat thread. */
        await addMessageToCurrentThread(msgObject);
        currentThread.thread?.updateTimestamp(msgObject.createdOn);
      } else {
        // threadId of the received msg did not match in the previous check.
        await updateThreadForParticipant(msgObject);
        if (currentThread && !unreadThreads.includes(msgObject?.threadId)) {
          /* Another chat thread is open. This received msg thread is not already in the list of unread threads. Add a new entry. */
          notifyUnreadMessages(msgObject?.threadId);
        }
      }
    } catch (error) {
      logger.debug('Error : new message received, but thread was not updated', error);
    }
  };

  // #endregion

  // #region transition

  const handleOpenChatThread = (thread) => {
    // get thread details from threadId, set details + messages as open thread
    setOpenThread(thread);
    getAllMessagesForParticipant(thread);
  };

  const handleCloseChatThread = () => {
    setOpenThread();
    initialSetAllMessages();
    resetUnreadMessage();
  };

  const repopulateParticipantsWithThreads = async (newThreadList) => {
    // Checking the updated data when we reconnect
    logger.debug('[THREAD-FLOW] (useChatClient) repopulateParticipantsWithThreads', newThreadList);
    const participantListCopy = { ...participantList };
    newThreadList.forEach(async (threadObj) => {
      if (threadObj.isGroupChat) {
        const groupParticipant = createGroupParticipant();
        groupParticipant.updateThread(threadObj);
        setGroupChat(groupParticipant);
        logger.debug('[THREAD-FLOW] (useChatClient) Setting group chat after thread updation');
      } else {
        const latestData = participantListCopy[threadObj.user.id];
        if (latestData) {
          const participant = new Participant({
            user: { id: latestData.user.id, name: latestData.user.name },
          });
          threadObj.updateTimestamp(latestData.lastMessageTimeStamp);
          participant.updateThread(threadObj);
          participant.updateActiveLanguage(latestData.activeLanguage);
          participant.updateOutputLanguage(latestData.outputLanguages);
          participantListCopy[threadObj.user.id] = participant;
        }
      }
    });

    logger.debug(
      '[THREAD-FLOW] (useChatClient) Updating participantlist after syncing with threadlist',
      participantListCopy
    );
    updateParticipantList(participantListCopy);
  };

  const syncThreadsAndParticipants = async (newThreadList) => {
    // Checking the updated data when we reconnect
    logger.debug(
      '[THREAD-FLOW] (useChatClient) Syncing thread and participant list',
      newThreadList,
      participantList
    );
    const participantListCopy = { ...participantList };
    const allParticipants = Object.values(participantListCopy);
    // Only re-render if this flag value gets changed
    let isChangedList = false;

    allParticipants.forEach((participantElement) => {
      if (!participantElement.thread) {
        const threadExist = newThreadList.find(
          (threadObj) => threadObj.user.id === participantElement.user.id
        );
        if (threadExist) {
          participantElement.updateThread(threadExist);
          isChangedList = true;
        }
      }
    });
    if (isChangedList) {
      updateParticipantList(participantListCopy);
    }
    logger.debug(
      '[THREAD-FLOW] (useChatClient) Updating participantlist after syncing with threadlist',
      participantListCopy,
      'isChangedList:',
      isChangedList
    );
  };

  const initiateChatClientCleanup = () => {
    setOpenThread();
    resetUnreadMessage();
    initialSetAllMessages();
    setGroupChat();
  };

  // #endregion

  return {
    getAllMessagesForParticipant,
    sendNewMessage,
    handleNewMessageReceived,
    handleOpenChatThread,
    handleCloseChatThread,
    handleStatusUpdate,
    repopulateParticipantsWithThreads,
    syncThreadsAndParticipants,
    addThreadToParticipant,
    onNewParticipantListReceived,
    currentThread,
    currentThreadMessages,
    unreadThreads,
    groupChat,
    participantList,
    initiateChatClientCleanup,
    addParticipantToList,
    removeParticipantFromList,
    saveGroupChatParticipant,
  };
};

export default useChatClient;
