import { createSlice } from '@reduxjs/toolkit';
import { SNACKBAR_DURATION, SNACKBAR_TYPE, THEME_MODE } from 'CONSTANTS/enum';

const initialState = {
  themeMode: THEME_MODE.DEFAULT,
  snackbar: {
    show: false,
    message: 'Set message to show',
    duration: SNACKBAR_DURATION.MEDIUM,
    type: SNACKBAR_TYPE.INFO,
  },
  errorTimestamp: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppThemeMode(state, action) {
      state.themeMode = action.payload;
    },
    showSnackbar: (state, action) => {
      Object.assign(state.snackbar, { ...initialState.snackbar, ...action.payload, show: true });
    },
    hideSnackbar: (state) => {
      state.snackbar = initialState.snackbar;
    },
    setErrorTimestamp(state) {
      state.errorTimestamp = Date.now();
    },
  },
});

export const appActions = { ...appSlice.actions };

export const appSelector = {
  errorTimestamp: (state) => state.app.errorTimestamp,
};
export default appSlice.reducer;
