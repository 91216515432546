import { useEffect } from 'react';
import { settings } from '@microsoft/teams-js';
import { useTranslation } from 'SERVICES/i18n';

import ROUTES from 'CONSTANTS/routes';
import { ORIGIN } from 'FEATURES/teams/constants';
import { useTeamsAuthentication } from '../Authentication';

const useTabConfig = () => {
  const { t } = useTranslation('teams', { keyPrefix: 'TAB_CONFIG' });

  const { isAuthorized, loading, authenticate } = useTeamsAuthentication();

  useEffect(() => {
    authenticate();
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      settings.registerOnSaveHandler((saveEvent) => {
        settings.setSettings({
          contentUrl: `${ORIGIN}${ROUTES.TAB}`,
          websiteUrl: `${ORIGIN}${ROUTES.TAB}`,
        });
        saveEvent.notifySuccess();
      });
      settings.setValidityState(true);
    }
  }, [isAuthorized]);

  return { loading, isAuthorized, t, authenticate };
};

export default useTabConfig;
