import { useSelector } from 'react-redux';
import useActions from 'HOOKS/useActions';
import {
  interpreterActions,
  selectInterpreter,
} from 'FEATURES/interpreter/slices/interpreterSlice';

const useInterpreterMeetings = () => {
  const languageMeetings = useSelector(selectInterpreter.languageMeetings);
  const selectedPrimaryLanguageInfo = useSelector(selectInterpreter.selectedPrimaryLanguageInfo);
  const setPrimarySelectedLanguageInfo = useActions(
    interpreterActions.setSelectedPrimaryLanguageInfo
  );

  const handleLanguageSelection = (language) => {
    setPrimarySelectedLanguageInfo(language);
  };
  return {
    items: languageMeetings,
    selectedItem: selectedPrimaryLanguageInfo,
    onSelect: handleLanguageSelection,
  };
};

export default useInterpreterMeetings;
